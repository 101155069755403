import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../Profile/Profile.css";
import "./Activity.css";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Header/Header';
import { makeStyles } from "@mui/styles";
// import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Cards from '../../img/dster-webp/card-1.webp';
import Cards2 from '../../img/dster-webp/card-2.webp';
import Cards1 from '../../img/dster-webp/card-3.webp';
import Cards3 from '../../img/dster-webp/card-4.webp';
import Cards4 from '../../img/dster-webp/card-5.webp';
import Cards5 from '../../img/dster-webp/card-6.webp';
import Cards6 from '../../img/dster-webp/card-7.webp';
import Cards7 from '../../img/dster-webp/card-8.webp';
import Cards8 from '../../img/dster-webp/card-9.webp';
import Cards9 from '../../img/dster-webp/card-10.webp';
import Cards10 from '../../img/dster-webp/card-11.webp';
import Cards11 from '../../img/dster-webp/card-12.webp';
import Cards12 from '../../img/dster-webp/card-12.webp';
import join from '../../img/dster-webp/join-icon.svg';
import ListIcon from '@mui/icons-material/List';
import icon from '../../img/dster-webp/c-icon.webp';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const drawerWidth = 280;

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const card = [
    { img: `${Cards}`, name: 'Jump' },
    { img: `${Cards1}`, name: 'ManTra' },
    { img: `${Cards2}`, name: 'Opera Odiosa ao Demônio da Putrefação' },
    { img: `${Cards3}`, name: 'Blues-corn' },
    { img: `${Cards4}`, name: 'Jump' },
    { img: `${Cards5}`, name: 'Afro KingKong vs Godzilla' },
    { img: `${Cards6}`, name: 'Jump' },
    { img: `${Cards7}`, name: 'HOSTAGE Feat. Tshaka' },
    { img: `${Cards8}`, name: 'Jump' },
    { img: `${Cards9}`, name: 'ManTra' },
    { img: `${Cards10}`, name: 'Jump' },
    { img: `${Cards11}`, name: 'HOSTAGE Feat. Tshaka' },
    { img: `${Cards12}`, name: 'Jump' },
]


function Activity() {

    const classes = useStyles();
    const theme = useTheme();

    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="UploadMusic profile">
            <ToastContainer position="top-right" />
            <Box sx={{ display: "flex" }} className='display-block'>
                {/* <Header2 /> */}
                <Box
                    component="main"
                    className="UploadMusic-box"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: "50px",
                        // marginBottom: "100px",faq
                    }}
                >


                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                        className="profile-outer-layer"
                    >
                        <Container maxWidth="xl">
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Item className={classes.containWidth}>
                                    <Header />

                                </Item>
                            </Grid>


                            <div>

                                <Box sx={{ width: '100%' }} className='nft-corner-tab'>
                                    <div className="back-btn">
                                        <ArrowBackIcon onClick={(() => { navigate(-1) })} />
                                    </div>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example nft-activity" className="nft-tab-style-activity"
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                        >
                                            <Tab label="My Nft" {...a11yProps(0)} />
                                            <Tab label="Owned Nft" {...a11yProps(1)} />
                                            <Tab label="Creator" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={value} index={0} className='box-pad'>

                                        <div className='participate-card'>
                                            <Grid container spacing={0} className='' sx={{ justifyContent: 'center' }}>
                                                {card.map((obj, ind) => {
                                                    return (
                                                        <Grid item xs={12} sm={12} md={6} xl={3} lg={4}>
                                                            <Card className="card-participate" onClick={(() => { navigate('/activitydetailpage') })}>

                                                                <div className="img-card bckstage-card-img">
                                                                    <img src={obj.img} />
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>{obj.name}</p>
                                                                        <p>mainnet</p>
                                                                    </div>
                                                                    <div className="toggle-icon">
                                                                        <ListIcon />
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>24-01-2024 I 15:35 to 17:45 </p>

                                                                    </div>
                                                                    <div className="toggle-icon">

                                                                        <img src={icon} />
                                                                    </div>
                                                                </div>
                                                                <div className="join-btn">
                                                                    <div className="join-icon">
                                                                        <img src={join} />
                                                                    </div>
                                                                    <Link to="#">

                                                                        Join 100 Dster</Link>
                                                                </div>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} className='box-pad'>

                                        <div className='participate-card'>
                                            <Grid container spacing={0} className='' sx={{ justifyContent: 'center' }}>
                                                {card.map((obj, ind) => {
                                                    return (
                                                        <Grid item xs={12} sm={12} md={6} xl={3} lg={4}>
                                                            <Card className="card-participate">

                                                                <div className="img-card bckstage-card-img">
                                                                    <img src={obj.img} />
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>{obj.name}</p>
                                                                        <p>mainnet</p>
                                                                    </div>
                                                                    <div className="toggle-icon">
                                                                        <ListIcon />
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                    </div>
                                                                    <div className="toggle-icon">

                                                                        <img src={icon} />
                                                                    </div>
                                                                </div>
                                                                <div className="join-btn">
                                                                    <div className="join-icon">
                                                                        <img src={join} />
                                                                    </div>
                                                                    <Link to="#">

                                                                        Join 100 Dster</Link>
                                                                </div>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2} className='box-pad'>

                                        <div className='participate-card'>
                                            <Grid container spacing={0} className='' sx={{ justifyContent: 'center' }}>
                                                {card.map((obj, ind) => {
                                                    return (
                                                        <Grid item xs={12} sm={12} md={6} xl={3} lg={4}>
                                                            <Card className="card-participate">

                                                                <div className="img-card bckstage-card-img">
                                                                    <img src={obj.img} />
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>{obj.name}</p>
                                                                        <p>mainnet</p>
                                                                    </div>
                                                                    <div className="toggle-icon">
                                                                        <ListIcon />
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-title">
                                                                        <p>24-01-2024 I 15:35 to 17:45  </p>

                                                                    </div>
                                                                    <div className="toggle-icon">

                                                                        <img src={icon} />
                                                                    </div>
                                                                </div>
                                                                <div className="join-btn">
                                                                    <div className="join-icon">
                                                                        <img src={join} />
                                                                    </div>
                                                                    <Link to="#">

                                                                        Join 100 Dster</Link>
                                                                </div>
                                                            </Card>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </div>
                                    </CustomTabPanel>

                                </Box>


                            </div>


                        </Container>

                        {/* <div>
              <Track />
            </div> */}
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default Activity;

import React, { useEffect, useState } from "react";
import logo from "../../img/dster-webp/logo.webp";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import consts from "../../Constant";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "../../img/dster-webp/eyelash.svg";
import Axios from "../../Axios";
import { ToastContainer } from 'react-toastify';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import "../Login/Login.css";
import "../Register/Register.css";
import "../../Media.css";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';

function Resetpassword() {
  const params = useParams();
  const tokens = params.token;
  const path = useLocation();
  const navigate = useNavigate();

  const [password, setPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);
  const [passworderr, setpassworderr] = useState(null);
  const [cpassworderr, setcpassworderr] = useState(null);
  const [checkBoxerror, setCheckBoxerror] = useState("")
  const inpassref = useRef(null);
  const inputconfrimRef = useRef(null);
  const isChecked = useRef(false);
  const captchaRef = useRef(null)

  const handleClickPassword = () => setPassword((show) => !show);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordone = () => setShowPasswordone((show) => !show);

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = () => {
    const passwordregex = new RegExp(
      /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
      "gm"
    );
    const inputValue = inpassref.current.value;

    if (inputValue.trim() === "") {
      setpassworderr("");
    } else if (!passwordregex.test(inputValue)) {
      setpassworderr("*Password must be at least 8 characters");
    } else {
      setpassworderr("");
    }
  };

  const handleConfirmPasswordChange = () => {
    if (inputconfrimRef.current.value !== inpassref.current.value) {
      setcpassworderr("*Password do not match");
    } else if (inputconfrimRef === inpassref) {
      setcpassworderr("");
    } else {
      setcpassworderr("");
    }
  };

  useEffect(() => {
    inpassref.current.focus();
  }, []);

  const resetPassword = async () => {
    try {
      const passwordregex = new RegExp(
        /(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,16}$)+/,
        "gm"
      );

      if (inpassref.current.value.trim() === "") {
        setpassworderr("*Please Enter a Password");
      } else if (!passwordregex.test(inpassref.current.value)) {
        setpassworderr("*Password must be at least 8 characters");
      } else if (inputconfrimRef.current.value.trim() === "") {
        setcpassworderr("*Please Confirm Your Password");
      } else if (inputconfrimRef.current.value !== inpassref.current.value) {
        setcpassworderr("*Password & ConfirmPassword not match");
      }
      // else if (!isChecked.current.checked){
      //   setCheckBoxerror("Accept Our Privacy Policy By Clicking The Check Box")
      // }
      else if (!captchaRef.current.getValue()) {
        setCheckBoxerror("Please Verify Your A Human")
      }
      else {
        const newPassword = {
          newPassword: inpassref.current.value,
        };

        await Axios.post(`/users/updateuserpassword`, newPassword, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              navigate(`/`, { state: { passwordUpdated: 0 } });
            }
          })
          .catch((err) => {
            console.log(err);
            navigate(`/`, { state: { passwordUpdated: 1 } });
          });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };


  const handleCheckBox = () => {
    setCheckBoxerror(" ")
  }


  return (
    <>
      <ToastContainer
        position="top-right"

      />
      <div className="register-page">
        <div className="outer-box">
          <div className="inner-box">
            <div className="inner-popup-pad">
              <div className="dream-logo">
                <Link>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <h1 className="log-head">Reset Your Dreamster Password</h1>
              <div className="inner-popup">
                <div className="inputbox">
                  <div className="pwd-spc">
                    <Typography variant="p" component="p" className="email">
                      New Password
                    </Typography>
                  </div>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder="Password"
                      id="outlined-adornment-password"
                      inputRef={inpassref}
                      onChange={() => {
                        handlePasswordChange();
                      }}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <img src={VisibilityOff} alt={VisibilityOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {passworderr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>{passworderr}</div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="inputbox">
                  <div className="pwd-spc">
                    <Typography variant="p" component="p" className="email">
                      Confirm New Password
                    </Typography>
                  </div>
                  <FormControl
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                    className="input-box"
                  >
                    <OutlinedInput
                      className="input-box-txt"
                      placeholder=" Confirm Password"
                      id="outlined-adornment-password"
                      inputRef={inputconfrimRef}
                      onChange={() => {
                        handleConfirmPasswordChange();
                      }}
                      type={password ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickPassword}
                            onMouseDown={handleMouseUpPassword}
                            edge="end"
                          >
                            {password ? (
                              <Visibility />
                            ) : (
                              <img src={VisibilityOff} alt={VisibilityOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {cpassworderr ? (
                    <div className="errorDiv" style={{ textAlign: "center" }}>{cpassworderr}</div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="check-agree">
                  {/* <div className="check-box">
                    <Checkbox inputRef={isChecked} onChange={() => {setCheckBoxerror("")}}/>
                  </div>
                  <p>
                    This site is protected by reCAPTCHA and the Google.{" "}
                    <span>Privacy Policy</span> and{" "}
                    <span>Terms of Service</span> apply.
                  </p> */}
                  <ReCAPTCHA sitekey={consts.sitekey} ref={captchaRef} onChange={() => { handleCheckBox() }} />
                </div>

                {checkBoxerror ? <div className="errorDiv" style={{ textAlign: "center" }}>{checkBoxerror}</div> : <></>}
                <div className="submit-btn">
                  <Button
                    onClick={() => {
                      resetPassword();
                    }}
                  >
                    Reset Password
                  </Button>
                </div>




                <div className="copyright">
                  <p>© 2023 Dreamster - All rights reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Resetpassword;

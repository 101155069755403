export const SEND_DATA = 'SEND_DATA';
export const SEND_PLAY_DATA = 'SEND_PLAY_DATA ';
export const INDEX_DATA = 'INDEX_DATA ';
export const UPDATE_AMOUNT_DATA = 'UPDATE_AMOUNT_DATA';
export const DELETE_ALL_RECEIVED_DATA  = 'DELETE_ALL_RECEIVED_DATA ';
export const DELETE_RECEIVED_DATA  = 'DELETE_RECEIVED_DATA ';
export const SET_WALLET_DATA = 'SET_WALLET_DATA';

export const sendData = (data) => ({
  type: SEND_DATA,
  payload: data,
});

export const sendplayData = (data) => ({
  type: SEND_PLAY_DATA,
  payload: data,
});

export const indexData = (data) => ({
  type: INDEX_DATA,
  payload: data,
});

export const deleteData = () => ({
  type: DELETE_ALL_RECEIVED_DATA ,
 
});
export const deleteoneData = (index) => ({
  type: DELETE_RECEIVED_DATA ,
 payload:index
});

export const updateamountData = (value) => ({
  type: UPDATE_AMOUNT_DATA ,
 payload:value
});

export const setWalletData = (data) => ({
  type: SET_WALLET_DATA,
  payload: data

});
import { initializeApp } from "firebase/app";
import constant from "../Constant";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from "firebase/auth";

const provider = new GoogleAuthProvider();

const firebaseConfig = {
apiKey: constant.fireBase_apikey,
  authDomain: constant.authDomain,
  projectId: constant.projectId,
  storageBucket: constant.storageBucket,
  messagingSenderId:constant.messagingSenderId,
  appId: constant.appId,
  measurementId: constant.measurementId
};

 const app = initializeApp(firebaseConfig);
 export const auth = getAuth(app)
 export default app
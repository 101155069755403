import React from "react";
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';


function SnackBar() {

    // const classes = useStyles();
    const [state, setState] = React.useState({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;





    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const action = (
        <CloseIcon onClick={handleClose} />
    );


    return (<>
        <Box sx={{ width: '100%' }}>
            {/* {buttons} */}

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                // onClose={handleClose}
                // message="We’ve updated our Terms of Use and Privacy Policy. By continuing to use the Dreamster Service, you agree to our updated Terms of Use and Privacy"
                // icon={<CloseIcon />}
                key={vertical + horizontal}
            >
                <SnackbarContent action={action} message="We’ve updated our Terms of Use and Privacy Policy. By continuing to use the Dreamster Service, you agree to our updated Terms of Use and Privacy" />
            </Snackbar>
        </Box>
    </>);
}

export default SnackBar;
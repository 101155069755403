import Grid from "@mui/material/Grid";
import "./Player.css";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React from "react";
import Header2 from "../Header/Header2";
import { Container } from "@mui/material";




const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "var( --clr-playerzone-card) !important",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",
    "@media (max-width: 991.98px)": {
      // width: '75% !important',
    },
  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "var(--clr-fanzone-box)",
  },

  learn: {
    borderRadius: "12px !important",
    background: 'transparent !important',
    boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
  },

  containWidth: {
    maxWidth: '1440px',
    margin: 'auto',
    background: 'none !important',
    padding: '0 !important',
    boxShadow: 'none !important',

    "@media (max-width: 1199.98px)": {
      padding: '20px !important',
    },
  }

});

function Player2() {
  const classes = useStyles();
  const [name, setName] = React.useState();

  return (
    <>
      <div className="player" sx={{ bgcolor: '#000' }}>
        <div className="playerBanner"></div>
        <Box sx={{ display: 'flex' }}>

          <Header2 name={name} setName={setName} />
          <Box component="main"
            // className="explore-box"
            sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', marginBottom: '100px' }}>


            <Grid container spacing={0} className="heading-container">
              <Container maxWidth="lg">
                <Grid xs={12} sm={12} md={8} lg={8} xl={12}>
                  <div className="explore-heading">
                    Player
                  </div>
                </Grid>
              </Container>

            </Grid>

            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3 }}
            // className="explore-box2"
            >
              <Container maxWidth="lg">
                <Grid container spacing={2} >
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="music-player-banner-grid"
                  >
                    <Item className={classes.containWidth}>

                      <h1 className="player-platform">
                        The music platform
                        <div>that does it all!</div>
                      </h1>
                      <div className="player-description">
                        Dreamster for Creators puts the power back in your hands by giving
                        you access to all the essential artist services you need to take
                        control of your musical journey.
                      </div>
                    </Item>

                  </Grid>
                </Grid>
              </Container>



              {/* <div>
            <Track />
        </div> */}
            </Box>
          </Box>

        </Box>
        {/* <Grid container spacing={0} className="music-player-grid">
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.containWidth}>
              <Header />
            </Item>
          </Grid>

          

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="music-player-banner-grid"
          >
            <Item className={classes.containWidth}>
              <div className="all-services">
                <div className="surprise-offer">
                  <div className="offer-left">
                    <h5>All services for 50€/year 🎉</h5>
                    <span>
                      50€, or $55 outside the Eurozone, per year, excl. taxes
                    </span>
                  </div>
                  <div className="start-now-offer">
                    <Button>Start here for free</Button>
                    <Button>Book a demo</Button>
                  </div>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Item className={classes.cardbg}>
                        <div className="card">
                          <div>
                            <img src={distribute} />
                          </div>
                          <div className="card-con">
                            <h2>Distribution</h2>
                            <p>
                              Make your music available on all the major streaming
                              services, including Spotify, Amazon, Apple Music,
                              TikTok and many more.
                            </p>
                            <p>
                              Upload unlimited tracks for free and collect 100% of
                              your master royalties.
                            </p>
                            <br></br>
                          </div>
                          <div className="inner-card-img">
                            <img src={appGroup} />
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Item className={classes.cardbg}>
                        <div className="card player-fanzone">
                          <div>
                            <img src={fanzone} />
                          </div>
                          <div className="card-con">
                            <h2>Backstage pass</h2>
                            <p>
                              Backstage pass enhances your relationship with dedicated
                              listeners by allowing you to create your own
                              subscription plans and reward your most engaged fans
                              with exclusive content. Such as an early access to
                              your new release, behind the scenes and much more.
                            </p>
                          </div>
                          <div className="inner-card-img">
                            <img src={dreamsterMinion} />
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.cardbg}>
                        <div className="card cln">
                          <div>
                            <img src={copyright} />
                          </div>
                          <div className="card-con">
                            <h2>Copyright management</h2>
                            <p>
                              As your music’s author, composer, or songwriter, you
                              generate copyright royalties each time your tracks
                              are played on streaming platforms. Collect those
                              royalties directly on Dreamster and generate a new
                              income stream. <span>Visit Bridger's website</span>
                            </p>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.cardbg}>
                        <div className="card cln">
                          <div className="preference">
                            <img src={license} />
                            <div className="soon">SOON</div>
                          </div>
                          <div className="card-con">
                            <h2>Licensing</h2>
                            <p>
                              License your music for commercial use in ads, video
                              games, TV, and video content worldwide. Reach
                              thousands of sync professionals and get paid a fair
                              revenue share for each placement.
                            </p>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.cardbg}>
                        <div className="card cln">
                          <div className="preference">
                            <img src={nft} />
                            <div className="soon">SOON</div>
                          </div>
                          <div className="card-con">
                            <h2>NFT</h2>
                            <p>
                              Dreamster empowers you to turn your work into unique
                              digital collectibles. You can create and monetize
                              your work with just a few clicks without worrying
                              about coding, royalties, or losing copyright. We’ve
                              got you covered!
                            </p>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ flexGrow: 1 }} className="creator">
                  <div className="player-platform pwr-back">
                    It's time for creators to take their power back!
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.pwrback}>
                        <div className="card cln">
                          <div className="card-con">
                            <h2>Backstage pass</h2>
                            <p>
                              We collect a 15% fee from the revenues you receive
                              from your fans' monthly subscription.
                            </p>

                            <div className="total-per fanzone">
                              <div className="create">
                                <div className="per">85%</div>
                                <span>Up to 85% for the creator</span>
                              </div>
                              <div className="winamp">
                                <div></div>
                                <span>15% for Winamp</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.pwrback}>
                        <div className="card cln">
                          <div className="card-con">
                            <h2>Distribution</h2>
                            <p>
                              Distribution services shall be free of charge
                              ensuring that artists receive 100% of their
                              earnings, minus applicable taxes and banking fees.
                            </p>

                            <div className="create">
                              <div className="per">100%</div>
                              <span>Up to 100% for the creator</span>
                            </div>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Item className={classes.pwrback}>
                        <div className="card cln">
                          <div className="card-con">
                            <h2>Copyright Management </h2>
                            <p>
                              With our sister company Bridger, you get 90% of the
                              copyright royalties accrued from streaming and
                              downloads, free of any administrative costs.
                            </p>

                            <div className="total-per">
                              <div className="create">
                                <div className="per">90%</div>
                                <span>Up to 90% for the creator</span>
                              </div>
                              <div className="winamp">
                                <div></div>
                                <span>10% for Winamp</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>

                  <div className="start-now-offer">
                    <Button>Start here for free</Button>
                    <Button>Book a demo</Button>
                  </div>
                </Box>

                <Box sx={{ flexGrow: 1 }} className="tips">
                  <div className="tips-title">
                    <h5>Learn and tips</h5>
                    <Link>More learn and tips</Link>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Item className={classes.learn}>
                        <div className="card">
                          <div>
                            <img src={tip1} />
                          </div>
                          <div className="card-con-tip">
                            <h2>What are copyright and master rights? </h2>
                            <div className="rights-tips">
                              <span>Music Rights</span>

                              <div className="duration">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                  <g clip-path="url(#clip0_115_13785)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98027 1.6623C5.65174 1.6623 2.14277 5.17127 2.14277 9.4998C2.14277 13.8283 5.65174 17.3373 9.98027 17.3373C14.3088 17.3373 17.8178 13.8283 17.8178 9.4998C17.8178 5.17127 14.3088 1.6623 9.98027 1.6623ZM0.717773 9.4998C0.717773 4.38426 4.86473 0.237305 9.98027 0.237305C15.0958 0.237305 19.2428 4.38426 19.2428 9.4998C19.2428 14.6154 15.0958 18.7623 9.98027 18.7623C4.86473 18.7623 0.717773 14.6154 0.717773 9.4998ZM9.98027 4.9873C10.3738 4.9873 10.6928 5.3063 10.6928 5.6998V9.20464L13.3341 11.846C13.6123 12.1243 13.6123 12.5753 13.3341 12.8536C13.0558 13.1318 12.6047 13.1318 12.3265 12.8536L9.47649 10.0036C9.34282 9.87002 9.26777 9.68876 9.26777 9.4998V5.6998C9.26777 5.3063 9.58678 4.9873 9.98027 4.9873Z" fill="#B5BBCC" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_115_13785">
                                      <rect width="19" height="19" fill="white" transform="translate(0.480469)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="min-time">5 minutes</p>
                              </div>

                            </div>


                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Item className={classes.learn}>
                        <div className="card">
                          <div>
                            <img src={tip2} />
                          </div>
                          <div className="card-con-tip">
                            <h2>How do you earn money as an artist on YouTube in 2023? </h2>
                            <div className="rights-tips">
                              <span>Music Rights</span>

                              <div className="duration">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                  <g clip-path="url(#clip0_115_13785)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98027 1.6623C5.65174 1.6623 2.14277 5.17127 2.14277 9.4998C2.14277 13.8283 5.65174 17.3373 9.98027 17.3373C14.3088 17.3373 17.8178 13.8283 17.8178 9.4998C17.8178 5.17127 14.3088 1.6623 9.98027 1.6623ZM0.717773 9.4998C0.717773 4.38426 4.86473 0.237305 9.98027 0.237305C15.0958 0.237305 19.2428 4.38426 19.2428 9.4998C19.2428 14.6154 15.0958 18.7623 9.98027 18.7623C4.86473 18.7623 0.717773 14.6154 0.717773 9.4998ZM9.98027 4.9873C10.3738 4.9873 10.6928 5.3063 10.6928 5.6998V9.20464L13.3341 11.846C13.6123 12.1243 13.6123 12.5753 13.3341 12.8536C13.0558 13.1318 12.6047 13.1318 12.3265 12.8536L9.47649 10.0036C9.34282 9.87002 9.26777 9.68876 9.26777 9.4998V5.6998C9.26777 5.3063 9.58678 4.9873 9.98027 4.9873Z" fill="#B5BBCC" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_115_13785">
                                      <rect width="19" height="19" fill="white" transform="translate(0.480469)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="min-time">6 minutes</p>
                              </div>

                            </div>


                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Item className={classes.learn}>
                        <div className="card">
                          <div>
                            <img src={tip3} />
                          </div>
                          <div className="card-con-tip">
                            <h2>How do you make a living as an artist? </h2>
                            <div className="rights-tips">
                              <span>Music Rights</span>

                              <div className="duration">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                  <g clip-path="url(#clip0_115_13785)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98027 1.6623C5.65174 1.6623 2.14277 5.17127 2.14277 9.4998C2.14277 13.8283 5.65174 17.3373 9.98027 17.3373C14.3088 17.3373 17.8178 13.8283 17.8178 9.4998C17.8178 5.17127 14.3088 1.6623 9.98027 1.6623ZM0.717773 9.4998C0.717773 4.38426 4.86473 0.237305 9.98027 0.237305C15.0958 0.237305 19.2428 4.38426 19.2428 9.4998C19.2428 14.6154 15.0958 18.7623 9.98027 18.7623C4.86473 18.7623 0.717773 14.6154 0.717773 9.4998ZM9.98027 4.9873C10.3738 4.9873 10.6928 5.3063 10.6928 5.6998V9.20464L13.3341 11.846C13.6123 12.1243 13.6123 12.5753 13.3341 12.8536C13.0558 13.1318 12.6047 13.1318 12.3265 12.8536L9.47649 10.0036C9.34282 9.87002 9.26777 9.68876 9.26777 9.4998V5.6998C9.26777 5.3063 9.58678 4.9873 9.98027 4.9873Z" fill="#B5BBCC" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_115_13785">
                                      <rect width="19" height="19" fill="white" transform="translate(0.480469)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="min-time">5 minutes</p>
                              </div>

                            </div>


                          </div>
                        </div>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Item className={classes.learn}>
                        <div className="card">
                          <div>
                            <img src={tip4} />
                          </div>
                          <div className="card-con-tip">
                            <h2>What is digital distribution?</h2>
                            <div className="rights-tips">
                              <span>Music Rights</span>

                              <div className="duration">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                                  <g clip-path="url(#clip0_115_13785)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.98027 1.6623C5.65174 1.6623 2.14277 5.17127 2.14277 9.4998C2.14277 13.8283 5.65174 17.3373 9.98027 17.3373C14.3088 17.3373 17.8178 13.8283 17.8178 9.4998C17.8178 5.17127 14.3088 1.6623 9.98027 1.6623ZM0.717773 9.4998C0.717773 4.38426 4.86473 0.237305 9.98027 0.237305C15.0958 0.237305 19.2428 4.38426 19.2428 9.4998C19.2428 14.6154 15.0958 18.7623 9.98027 18.7623C4.86473 18.7623 0.717773 14.6154 0.717773 9.4998ZM9.98027 4.9873C10.3738 4.9873 10.6928 5.3063 10.6928 5.6998V9.20464L13.3341 11.846C13.6123 12.1243 13.6123 12.5753 13.3341 12.8536C13.0558 13.1318 12.6047 13.1318 12.3265 12.8536L9.47649 10.0036C9.34282 9.87002 9.26777 9.68876 9.26777 9.4998V5.6998C9.26777 5.3063 9.58678 4.9873 9.98027 4.9873Z" fill="#B5BBCC" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_115_13785">
                                      <rect width="19" height="19" fill="white" transform="translate(0.480469)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <p className="min-time">4 minutes</p>
                              </div>

                            </div>


                          </div>
                        </div>
                      </Item>
                    </Grid>
                  </Grid>

                </Box>
              </div>
            </Item>

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.containWidth}>
              <Footer />
            </Item>
          </Grid> */}
        {/* </Grid> */}
      </div>
    </>
  );
}

export default Player2;

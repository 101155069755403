import Styles from "./cards.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
// import Button from "./Button";

function Cards({ imagen }) {
    const [show, setShown] = useState(false);

    const props3 = useSpring({
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 25%)"
            : "0 2px 10px rgb(0 0 0 / 8%)",
        width: '250px',
        background: 'rgb(81 81 81)',
        borderRadius: '15px',
        padding: '10px',
        opacity: '100%'

    });
    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
        >
            <img src={imagen} alt="" className="mx-card-img" />
            <h2 className="mx-c-head">Formula 1 Theme</h2>
            <p className="mx-c-para">
                Taylor Swift
            </p>
        </animated.div>
    );
}

export default Cards;

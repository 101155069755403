import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './Particpate.css'
import Cards from '../../img/dster-webp/card-1.webp';
import Cards1 from '../../img/dster-webp/card-2.webp';
import Cards2 from '../../img/dster-webp/card-3.webp';
import Axios from "../../Axios";
import Cards3 from '../../img/dster-webp/card-4.webp';
import Cards4 from '../../img/dster-webp/card-5.webp';
import Cards5 from '../../img/dster-webp/card-6.webp';
import Cards6 from '../../img/dster-webp/card-7.webp';
import Cards7 from '../../img/dster-webp/card-8.webp';
import Cards8 from '../../img/dster-webp/card-9.webp';
import Cards9 from '../../img/dster-webp/card-10.webp';
import Cards10 from '../../img/dster-webp/card-11.webp';
import Cards11 from '../../img/dster-webp/card-12.webp';
import Cards12 from '../../img/dster-webp/card-12.webp';
import icon from '../../img/dster-webp/c-icon.webp';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import join from '../../img/dster-webp/join-icon.svg';
import ListIcon from '@mui/icons-material/List';
import HeaderNew from "../HeaderNew/HeaderNew";
import newno from '../../img/dster-webp/newnodata.gif'



const drawerWidth = 280;

const Participate = () => {
    const [name, setName] = React.useState();
    const [followers, setFollowers] = useState([])
    const tokens = localStorage.getItem("iphephile");


    useEffect(() => {
        getFollowers()
    }, [])

    const getFollowers = async () => {
        try {

            const datas = await Axios.post("/followers/get_my_followers", {
                name: name
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setFollowers(res?.data?.result[0]?.followerDetails)


                    }
                    else {

                        setFollowers([])
                    }
                }).catch((err) => {
                    console.log(err, "err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const [card, setCard] = useState([
        { img: `${Cards}`, name: 'Jump' },
        { img: `${Cards1}`, name: 'ManTra' },
        { img: `${Cards2}`, name: 'Opera Odiosa ao Demônio da Putrefação' },
        { img: `${Cards3}`, name: 'Blues-corn' },
        { img: `${Cards4}`, name: 'Jump' },
        { img: `${Cards5}`, name: 'Afro KingKong vs Godzilla' },
        { img: `${Cards6}`, name: 'Jump' },
        { img: `${Cards7}`, name: 'HOSTAGE Feat. Tshaka' },
        { img: `${Cards8}`, name: 'Jump' },
        { img: `${Cards9}`, name: 'ManTra' },
        { img: `${Cards10}`, name: 'Jump' },
        { img: `${Cards11}`, name: 'HOSTAGE Feat. Tshaka' },
        { img: `${Cards12}`, name: 'Jump' },
    ])

    const [title, setTitle] = useState('participate')
    return (
        <div>
            <Box sx={{ display: 'flex' }} className='display-block'>
                <HeaderNew name={name} setName={setName} title={title} />
                <Box component="main"
                    className="feed-bg"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px' }}>
                    <Grid container className='participate-grid'>
                        {/* <div className='participate-head'>

                            <h2>Participate</h2>
                            <div className="join-room">
                                <TextField id="outlined-basic" variant="outlined" defaultValue="0xaF489a41e001dDeaFeC79829df5D56CC4b3a9Aa5" />
                                <div className="join-room-btn">
                                    <Link to="#">Join Room</Link>
                                </div>
                            </div>
                        </div>
                         */}

                        <div className='participate-card'>
                            <Grid container className=''>
                                {followers && followers.length > 0 ? <>

                                    {card.map((obj, ind) => {
                                        return (
                                            <Grid item xs={12} sm={6} md={6} xl={3} lg={4}>
                                                <Card className="card-participate">

                                                    <div className="img-card">
                                                        <img src={obj.img} />
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <p>Jump</p>
                                                            <p>mainnet</p>
                                                        </div>
                                                        <div className="toggle-icon">
                                                            <ListIcon />
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                            <p>24-01-2024 I 15:35 to 17:45  </p>

                                                        </div>
                                                        <div className="toggle-icon">

                                                            <img src={icon} />
                                                        </div>
                                                    </div>
                                                    <div className="join-btn">
                                                        <div className="join-icon">
                                                            <img src={join} />
                                                        </div>
                                                        <Link to="#">

                                                            Join 100 Dster</Link>
                                                    </div>
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                                </> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='feed-music-box'>
                                        <div className='music-detail'>


                                            <div className='album-detail'>

                                                <div className='no-music'>
                                                    <div className='time-music'>
                                                        <div className='music-name'>
                                                            <div className="newno">
                                                                <img src={newno} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </Grid>}
                            </Grid>
                        </div>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default Participate

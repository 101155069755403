import React, { useState } from 'react'
import './LiveChat.css'
import SidebarNew from '../SidebarNew/SidebarNew'
import Recent from '../Recent/Recent'
import { Box, Grid, useTheme, useMediaQuery, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import KeyboardIcon from '@mui/icons-material/Keyboard';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import typee from '../../img/dster-webp/type.gif'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function LiveChat() {

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const [playCard, setPlaycard] = useState([
        { img: 'https://imgs.search.brave.com/gTGcFA5drHatTMbjYOP3X-CROolnU7u_bxbi4rdlrWg/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9y/ZWxheGVkLWFmcmlj/YW4tZ2lybC13aXRo/LWJpZy1leWVzLXNt/aWxpbmctYXBwZWFs/aW5nLWxhZHktbGlz/dGVuaW5nLW11c2lj/LXdoaXRlXzE5NzUz/MS0xMTI4MS5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw' },
        { img: "https://imgs.search.brave.com/HvqPR3gn8GFFQ9J8-sokBi0jvSJWz364dV9mPZGoUyc/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by9hZnJpY2FuLXdv/bWFuLXNpbmdpbmct/d2hpbGUtbGlzdGVu/aW5nLW11c2ljLWhl/YWRwaG9uZXMtaXNv/bGF0ZWQtZ3JleS1i/YWNrZ3JvdW5kLWNv/cHktc3BhY2VfNjEz/NjUyLTQ0MC5qcGc_/c2l6ZT02MjYmZXh0/PWpwZw" },
        { img: 'https://imgs.search.brave.com/krYFxbma7Nk_tDb7WxmAkBP-Yq03lI-oc7LR-gmBr_Q/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by9w/bGVhc2VkLWdvb2Qt/bG9va2luZy1naXJs/LXdpdGgtY3VybHkt/YWZyby1oYWlyLWNs/b3Nlcy1leWVzLWVt/YnJhY2VzLWhlcnNl/bGYtbGlzdGVucy1t/dXNpY18yNzM2MDkt/NDU3NDYuanBnP3Np/emU9NjI2JmV4dD1q/cGc' },
        // { img: "https://imgs.search.brave.com/krx_xPUZGcu2ldKw1qreusemorEtlIMuaqCo4SPdnZY/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/ZnJlZS1waG90by93/aW5zb21lLWNhcmVm/cmVlLWJsYWNrLWdp/cmwtbGlzdGVuaW5n/LW11c2ljLXN0dWRp/by1zaG90LWpvY3Vu/ZC1hZnJpY2FuLWZl/bWFsZS1tb2RlbC1w/b3NpbmctaGVhZHBo/b25lc18xOTc1MzEt/MTExNTkuanBnP3Np/emU9NjI2JmV4dD1q/cGc" }
    ])

    const [emoji, setEmoji] = useState('');
    const [showEmoji, setShowEmoji] = useState(false);
    const [messages, setMessages] = useState([]);
    const [name, setName] = React.useState();


    const handleEmojiSelect = (emoji) => {
        setEmoji((prevValue) => prevValue + emoji.native);
    };

    const handleChange = (event) => {
        setEmoji(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (emoji.trim() !== '') {
            setMessages([...messages, emoji.trim()]);
            setEmoji('');
            // setIsFocused(false)
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e);
            // setIsFocused(false)
        }

    };

    const picker = (
        <Picker data={data} onEmojiSelect={handleEmojiSelect} />
    );

    AOS.init();

    const [recent, setRecent] = useState([
        {},
        {},
        {},
        {},
        {},
        {},

    ])

    const [isFocused, setIsFocused] = useState(false);


    const grubChat = (
        <>
            <div className='topartist-recent'>
                grub chat
            </div>
            <div className='height-prop'>
                {recent.map((row, i) => {
                    return (
                        <div className='display-2 recente-head-div beta'>
                            <div className='display-1 '>
                                <Avatar
                                    alt="Remy Sharp"
                                    src="https://s.france24.com/media/display/451ed2b8-eed6-11ea-afdd-005056bf87d6/w:1280/p:4x3/messi-1805.jpg"
                                    sx={{ width: 40, height: 40 }}
                                />
                                <div>
                                    <div className='recent-head-artist beta'>
                                        track pop
                                    </div>
                                    <div className='recent-head-domainbeta'>
                                        500+ members join chat
                                    </div>
                                </div>
                            </div>
                            <div>

                                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.32692 10.5C5.64555 10.5 5.90385 10.2417 5.90385 9.92311C5.90385 9.60449 5.64555 9.34619 5.32692 9.34619C5.0083 9.34619 4.75 9.60449 4.75 9.92311C4.75 10.2417 5.0083 10.5 5.32692 10.5Z" stroke="#B8B8B8" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9.07692 10.5C9.39555 10.5 9.65385 10.2417 9.65385 9.92311C9.65385 9.60449 9.39555 9.34619 9.07692 9.34619C8.7583 9.34619 8.5 9.60449 8.5 9.92311C8.5 10.2417 8.7583 10.5 9.07692 10.5Z" stroke="#B8B8B8" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.8269 10.5C13.1455 10.5 13.4038 10.2417 13.4038 9.92311C13.4038 9.60449 13.1455 9.34619 12.8269 9.34619C12.5083 9.34619 12.25 9.60449 12.25 9.92311C12.25 10.2417 12.5083 10.5 12.8269 10.5Z" stroke="#B8B8B8" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.61538 16.8462L1 18L2.15385 14.5385V4.15385C2.15385 3.84783 2.27541 3.55434 2.4918 3.33795C2.70819 3.12157 3.00167 3 3.30769 3H14.8462C15.1522 3 15.4457 3.12157 15.662 3.33795C15.8784 3.55434 16 3.84783 16 4.15385V15.6923C16 15.9983 15.8784 16.2918 15.662 16.5082C15.4457 16.7246 15.1522 16.8462 14.8462 16.8462H5.61538Z" stroke="#B8B8B8" stroke-linecap="round" stroke-linejoin="round" />
                                    <circle cx="17" cy="3" r="3" fill="#00F218" />
                                </svg>



                            </div>
                        </div>
                    )
                })}

            </div>
        </>

    )
    return (
        <div className='livechat-main'>
            <Box sx={{ display: isLgUp ? 'flex' : 'block' }}>
                <SidebarNew name={name} setName={setName} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        marginTop: "80px",
                        // height: '100vh'
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={9}
                            style={{ padding: '20px' }}
                        >

                            <div className='livechat-banner'>
                                <div className="livechathead display-2">
                                    <div className='display-1'>
                                        <svg width="30" height="8" viewBox="0 0 30 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="4" cy="4" r="4" fill="#B8B8B8" />
                                            <circle cx="15" cy="4" r="4" fill="#B8B8B8" />
                                            <circle cx="26" cy="4" r="4" fill="#B8B8B8" />
                                        </svg>
                                        <div className='Live-tet'>
                                            Live Chat Members
                                        </div>
                                    </div>
                                    <Stack direction="row" spacing={-0.5}>
                                        {playCard.map((row, i) => (
                                            <Avatar
                                                key={i}
                                                alt="Chat Member"
                                                src={row.img}
                                                sx={{ width: 24, height: 24 }}
                                            />
                                        ))}
                                    </Stack>
                                </div>
                                <div className='leftsidechat'>
                                    {messages.map((message, index) => {
                                        return (
                                            <>
                                                <div key={index} className="display-1 mappedchat left" >
                                                    <Avatar
                                                        alt="Chat Member"
                                                        src={playCard[0].img}
                                                        sx={{ width: 52, height: 52 }}
                                                    />
                                                    <div className='chat-memname'>
                                                        Sankar<span>now at 08.00</span>
                                                        <div className='chat-message left' >
                                                            {message}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    )}
                                </div>
                                {isFocused && <div className='leftsidechat tyinp'>

                                    <div className="display-1 mappedchat left" >
                                        <Avatar
                                            alt="Chat Member"
                                            src={playCard[0].img}
                                            sx={{ width: 52, height: 52 }}
                                        />
                                        <div className='chat-memname'>
                                            Sankar<span>now at 08.00</span>
                                            <div className="typee">
                                                <img src={typee} alt="typee" />
                                            </div>
                                        </div>
                                    </div>

                                </div>}


                                <div className='rightsidechat'>

                                    {messages.map((message, index) => (
                                        <div key={index} className="display-1 mappedchat right">
                                            <div className='chat-memname'>
                                                <span>now at 08.00</span> Sankar
                                                <div className='chat-message right' >
                                                    {message}
                                                </div>
                                            </div>
                                            <Avatar
                                                // key={i}
                                                alt="Chat Member"
                                                src={playCard[0].img}
                                                sx={{ width: 52, height: 52 }}
                                            />


                                        </div>
                                    ))}


                                </div>

                                {/* {isFocused && <div className='rightsidechat'>
                                    <div className="display-1 mappedchat right">
                                        <div className='chat-memname'>
                                            <span>now at 08.00</span> Sankar
                                            <div className="typee">
                                                <img src={typee} alt="typee" />
                                            </div>
                                        </div>
                                        <Avatar

                                            alt="Chat Member"
                                            src={playCard[0].img}
                                            sx={{ width: 52, height: 52 }}
                                        />
                                    </div>
                                </div>} */}

                                {/* {messages.length <= 0 && <div className="nochat-found text-center">
                                    <img width="100" height="100" src="https://img.icons8.com/bubbles/100/chat.png" alt="chat" />

                                    <div className="strt-convo">
                                        <Button variant='contained' >
                                            Start a conversation
                                        </Button>
                                    </div>
                                </div>} */}
                            </div>
                            <div className='livechat-type display-1'>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="Type Something"
                                    variant="outlined"
                                    value={emoji}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                />
                                <Stack direction="row" spacing={2}>
                                    <div onClick={() => setShowEmoji(!showEmoji)}>
                                        {!showEmoji ? (
                                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1429 23.6426C18.297 23.6426 23.2857 18.6539 23.2857 12.4998C23.2857 6.34576 18.297 1.35693 12.1429 1.35693C5.98883 1.35693 1 6.34576 1 12.4998C1 18.6539 5.98883 23.6426 12.1429 23.6426Z" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M6.48535 14.2139C7.34249 17.2996 10.7711 19.1853 13.8568 18.3282C15.7425 17.6424 17.2854 16.0996 17.7996 14.2139" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.37193 9.84249C8.13524 9.84249 7.94336 9.65061 7.94336 9.41392C7.94336 9.17723 8.13524 8.98535 8.37193 8.98535" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M8.37109 9.84249C8.60779 9.84249 8.79967 9.65061 8.79967 9.41392C8.79967 9.17723 8.60779 8.98535 8.37109 8.98535" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.9139 9.84249C15.6772 9.84249 15.4854 9.65061 15.4854 9.41392C15.4854 9.17723 15.6772 8.98535 15.9139 8.98535" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M15.9141 9.84249C16.1508 9.84249 16.3426 9.65061 16.3426 9.41392C16.3426 9.17723 16.1508 8.98535 15.9141 8.98535" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        ) : (
                                            <KeyboardIcon />
                                        )}
                                    </div>

                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.1423 16.3569C7.27254 16.3569 8.99944 14.63 8.99944 12.4997C8.99944 10.3695 7.27254 8.64258 5.1423 8.64258C3.01206 8.64258 1.28516 10.3695 1.28516 12.4997C1.28516 14.63 3.01206 16.3569 5.1423 16.3569Z" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7136 23.6425C21.8438 23.6425 23.5707 21.9156 23.5707 19.7854C23.5707 17.6551 21.8438 15.9282 19.7136 15.9282C17.5833 15.9282 15.8564 17.6551 15.8564 19.7854C15.8564 21.9156 17.5833 23.6425 19.7136 23.6425Z" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.7136 9.07122C21.8438 9.07122 23.5707 7.34432 23.5707 5.21408C23.5707 3.08384 21.8438 1.35693 19.7136 1.35693C17.5833 1.35693 15.8564 3.08384 15.8564 5.21408C15.8564 7.34432 17.5833 9.07122 19.7136 9.07122Z" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.58789 10.7854L16.2679 6.92822" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.58789 14.2139L16.2679 18.071" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6844 19.343L14.425 23.0784C14.6572 23.3107 14.9449 23.4797 15.2608 23.5694C15.5766 23.6592 15.9102 23.6667 16.2299 23.5913C16.5495 23.5159 16.8445 23.3601 17.087 23.1386C17.3295 22.9172 17.5113 22.6374 17.6152 22.3259L23.7541 3.89216C23.8675 3.55284 23.8841 3.18865 23.802 2.84044C23.7199 2.49222 23.5424 2.17377 23.2895 1.9208C23.0365 1.66782 22.718 1.49035 22.3698 1.40828C22.0216 1.3262 21.6574 1.34279 21.3181 1.45616L2.88439 7.60016C2.57386 7.70451 2.29507 7.8863 2.07434 8.12836C1.85361 8.37043 1.69825 8.66477 1.62292 8.98358C1.54759 9.3024 1.55479 9.63515 1.64384 9.9504C1.73288 10.2657 1.90083 10.553 2.13182 10.7853L6.83068 15.4893L6.67125 21.4293L10.6844 19.343Z" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M23.2042 1.84033L6.83105 15.4895" stroke="#7A7A7A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </Stack>
                            </div>
                            {showEmoji && (
                                <div className='em-emoji-picker' style={{ width: '100%', display: 'flex' }}>
                                    {picker}
                                </div>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={3}
                        >
                            <Recent grubChat={grubChat} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div >
    )
}

export default LiveChat

import './AlbumPage.css'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DownloadIcon from '@mui/icons-material/Download';
import '../Feed/Feed.css'
import SendMessages from '../ShareLink/SendMessages';
import Tooltip from '@mui/material/Tooltip';
import wave from "../../img/dster-webp/musicwave.gif"
import '../TrackPage/TrackPage.css'
import Axios from "../../Axios";
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import Companylogo from '../../img/dster-webp/company-logo.webp';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import Tracks from "../Music-traxk/Tracks";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { sendData, sendplayData } from '../../Redux/action'
import { indexData } from '../../Redux/action'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import s6 from "../../img/dster-webp/pass-play.webp"
import Popover from '@mui/material/Popover';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HeaderNew from '../HeaderNew/HeaderNew';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import newno from "../../img/dster-webp/newnodata.gif"

const drawerWidth = 280;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'var(--clr-bcg)',
    height: '620px',
    // overflow: 'scroll',
    // border: '2px solid #000',
    // boxShadow: 'none',
    p: 4,
    '@media all and (max-width: 1199.98px)': {
        width: '55%'
    },
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    }
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AlbumPage = () => {
    const [value, setValue] = React.useState(0);
    const [music, setMusic] = React.useState([]);
    const [usermusic, setUsermusic] = React.useState([]);
    const [name, setName] = React.useState();
    const [tracksongs, settracksongs] = useState([])
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const [openModel2, setOpenModel2] = React.useState(false);
    const handleOpen2 = () => setOpenModel2(true);
    const handleModelClose2 = () => setOpenModel2(false);
    const [audiosource, setAudiosource] = React.useState();
    const dispatch = useDispatch();
    const [tracks, setTracks] = useState(0)
    const songlist = useSelector(state => state.songs);
    const play_mood = useSelector(state => state.play_mood);
    const [audiosources, setAudiosources] = React.useState();
    const [audiosources1, setAudiosources1] = React.useState(songlist);
    const index = useSelector(state => state.index);
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const navigate = useNavigate()
    const [audioDurations, setAudioDurations] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [playname, setPlayname] = React.useState();
    const [song_id, setsongid] = React.useState()
    const [plays, setPlays] = React.useState("false");
    const [play, setPlay] = React.useState([]);
    const [openModel1, setOpenModel1] = React.useState(false);
    const [values, setValues] = React.useState(true);
    const [audioplays, setAudioplays] = useState([]);
    const [isplay, setisplay] = useState(0)
    const handleOpen1 = () => setOpenModel1(true);
    const params = useParams()

    const handleModelClose1 = () => setOpenModel1(false);

    const handleModelClose = () => setOpenModel(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const location = useLocation()


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };
    const getaudioPlays = async (audioSrc, index) => {
        try {
            // console.log(audioSrc, "songs")
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            }
            else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }

        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0
            }));
        }
    };


    useEffect(() => {
        if (music?.songDetails?.length > 0) {
            music?.songDetails.forEach((row) => {
                getAudioDuration(row?.audio);
            });

        }
        if (music?.songDetails?.length > 0) {
            music?.songDetails?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });

        }
    }, [music?.songDetails]);
    useEffect(() => {

        if (music?.songDetails?.length > 0) {
            music?.songDetails?.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });

        }
    }, [values]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // console.log(location?.state, 'state');

    useEffect(() => {
        setTracks(index !== undefined ? index : Number(localStorage.getItem("tracks")))

        if (location?.state?.value) {
            // console.log(location?.state, "state")
            setValue(location?.state?.value)
        }
        else {
            setValue(0)
        }

    }, [])
    useEffect(() => {


        getTrackMusic()
        getMusic()
    }, [])


    useEffect(() => {

        getMusic()

    }, [params._id, name])
    React.useEffect(() => {
        // getTrackMusic()

    }, [name]);

    const changePlays = (data) => {
        // console.log('changeplays');

        setPlays(data)
        // console.log(data, "songlist1")
        localStorage.setItem("playsong", "true")
        dispatch(sendplayData(data))
        dispatch(sendData(audiosources))
        dispatch(indexData(0))
        setAudiosources1(songlist)
        localStorage.setItem("songName", songlist[0]?.name)

    }
    const changeTracks = () => {
        // console.log('changeTracks');

        setPlays("true")
        localStorage.setItem("playsong", "true")
        dispatch(sendplayData("true"))
        dispatch(sendData(audiosources))
        setAudiosources1(audiosources)
        localStorage.setItem("songName", songlist[index]?.name)

    }
    const getTrackMusic = async () => {
        try {


            const datas = await Axios.post("/users/get_user_music", { music_type: "album", name: name }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {

                        //settracksongs(res?.data?.trackList)
                        setCount(res?.data?.count)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        // settracksongs([])
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    const handleAdd = async (data) => {

        try {
            var create_data
            create_data = {
                albumId: params._id,
                songId: data
            }
            // console.log(create_data)

            await Axios.post("/users/edit_album", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        getTrackMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }


    }

    const getMusic = async () => {
        try {
            var findData = {
                album_id: params._id,
                name: name
            }


            const datas = await Axios.post("/users/get_one_album", findData, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res?.data?.result[0], "resi")
                    if (res?.data?.success) {

                        setMusic(res?.data?.result[0])
                        var source = []

                        if (res?.data?.result[0].songDetails.length > 0) {
                            for (var i = 0; i < res?.data?.result[0].songDetails.length; i++) {
                                var song = {}
                                song.name = res?.data?.result[0].songDetails[i].song_name
                                // song.audio = res?.data?.result[0].songDetails[i]?.audio
                                if (res?.data?.result[0].songDetails[i]?.nft_type === "nor") {
                                    song.audio = res?.data?.result[0].songDetails[i]?.audio
                                }
                                else {
                                    song.audio = res?.data?.result[0].songDetails[i]?.pre_audio

                                }
                                source.push(song)
                                // console.log(source, "sor")
                            }
                        }
                        setAudiosources(source)
                        settracksongs(res?.data?.musics)

                        // console.log(res?.data?.result[0].SongDetails.length)
                        if (res?.data?.result[0].SongDetails.length > 1) {
                            setUsermusic(res?.data?.result[0].SongDetails)
                        } else {
                            setUsermusic(res?.data?.result[0])
                        }
                        setCount(res?.data?.count)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                        settracksongs([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio)
        localStorage.setItem("songName", data.song_name)
        localStorage.setItem("Image", data.image)
        localStorage.setItem("current", 0)
        localStorage.setItem("isPause", false)
        setAudiosource(data.audio)

    }


    const removeAudio = () => {
        localStorage.removeItem("audiosource")
        localStorage.removeItem("current")
        localStorage.removeItem("isPause")
        // setAudiosource(data)

    }
    const audio = localStorage.getItem("audiosource")

    const createMusic = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            // console.log(create_data)

            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addLibrary = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            // console.log(create_data)

            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addFavorite = async (data) => {
        try {

            const create_data = {
                song_id: data
            }
            // console.log(create_data)

            await Axios.post("/users/add_to_favourite", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumLibrary = async (data) => {
        try {

            const create_data = {
                album_id: data
            }
            // console.log(create_data)

            await Axios.post("/users/add_album_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }
            // console.log(create_data)

            await Axios.post("/users/add_fav_album", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const editAlbums = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }
            // console.log(data)

            await Axios.post("/users/edit_album_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editSong = async (data) => {
        try {

            const create_data = {
                songId: data.id,
                status: data.status
            }
            // console.log(create_data)

            await Axios.post("/users/edit_song_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    // console.log(res, "res")
                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // console.log(res?.data?.message)
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const playAll = async (data) => {
        // console.log(data)
    }



    // new add track
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}

        >
            <List >

                {tracksongs?.length > 0 ? <>

                    {tracksongs && tracksongs.map((obj, index) => {
                        return (
                            <>  <div className='add-track-createplaylist'>
                                <div className='tracks-createplaylist'>
                                    {obj?.image ? <img src={obj?.image} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}
                                    <div>{obj.song_name} by {obj.createUser?.name}</div>
                                </div>
                                <div className='add-btn-add-track'>

                                    <Button onClick={() => { handleAdd(obj?._id) }}>
                                        ADD
                                    </Button>
                                </div>

                            </div>
                            </>)
                    })}
                </> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='feed-music-box'>
                        <div className='music-detail'>


                            <div className='album-detail'>

                                <div className='no-music'>
                                    <div className='time-music'>
                                        <div className='music-name'>
                                            <h4>No Data Found</h4>
                                        </div>

                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </Grid>}

            </List>
        </Box>
    );


    return (
        <div className='feed trackPage'>
            <ToastContainer

                position="top-right"

            />
            <Box sx={{ display: 'flex' }} className='display-block'>
                <HeaderNew name={name} setName={setName} title="Album" />
                <Box component="main"
                    className="feed-bg"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px', }}>







                    <Grid container spacing={0} className='albumpage-grid'>
                        <Grid xs={11} sm={10} md={10} lg={10} xl={10}>
                            <div className='Trackpage-title-div'>
                                <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                                    <Grid xs={11} sm={10} md={10} lg={10} xl={10}>
                                        <div className='track-avatar'>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={music?.image}
                                                sx={{ width: 200, height: 200 }}
                                            />
                                        </div>


                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={8}>
                                        <div className='track-title-content-div'>
                                            <div className='Track-genre'>
                                                Album
                                            </div>
                                            <div className='Track-title'>
                                                {music?.album_name}
                                            </div>

                                            <div className='track-artist' onClick={((e) => { navigate(`/artistpage/${music.createUser?._id}`) })}>

                                                {/* {console.log(music?.createUser[0]?.name)} */}
                                                <span>By</span>{music?.createUser?.name}
                                            </div>
                                            <div className='discription-trackpage2'>
                                                <div>Released<span>{new Date(music?.createdAt).toLocaleDateString()}   </span></div>

                                            </div>

                                            <div className='trackspage-tags'>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11" className='repeat'>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                    </svg>
                                                    <span> {music?.reposts?.length ? music?.reposts?.length : 0} Repost</span>
                                                </div>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className='unfav'>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                    </svg>
                                                    <span> {music?.fav_count} Favourite</span>
                                                </div>
                                            </div>
                                            <div className='follow-us-div2'>
                                                <div className='play-btn-albumpage'>
                                                    <Tooltip title="Play" placement="top">
                                                        <Button onClick={() => { changePlays("true"); setAudiosource() }} ><PlayArrowIcon />Play</Button>
                                                    </Tooltip>
                                                </div>

                                                <SendMessages handleModelClose1={handleModelClose1} handleOpen1={handleOpen1} openModel1={openModel1} id={`/albumpage/${music._id}`} />


                                                <div>
                                                    {music?.players?.length > 1 ? `${music?.players?.length} plays` : `${music?.players?.length} play`}
                                                </div>
                                                <Tooltip title={music?.isRepost === true ? "Unrepost" : "Repost"} placement="top"><div onClick={() => { editAlbums({ id: music?._id, status: "repost" }) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 11" fill="var(--clr-font)" className={music?.isRepost === true ? "repeat" : "Repost"}>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                    </svg>


                                                </div></Tooltip>


                                                <Tooltip title={music?.userFav === false ? "Add to Favourite" : "Remove from Favourite"} placement="top">
                                                    <div onClick={() => { addalbumFavorite(music._id) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className={music?.userFav === false ? "fav" : "unfav"}>
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                        </svg>

                                                    </div>
                                                </Tooltip>

                                                {music?.userFound === false && music?.TotalCounts !== 0 ? <div onClick={(e) => addalbumLibrary(music._id)}>
                                                    <DownloadIcon />
                                                </div> : <></>}
                                                <div>
                                                    {['bottom'].map((anchor) => (
                                                        <React.Fragment key={anchor}>
                                                            <Button onClick={toggleDrawer(anchor, true)} className='bot-drawer-btn'>Add More</Button>
                                                            <Drawer
                                                                anchor={anchor}
                                                                open={state[anchor]}
                                                                onClose={toggleDrawer(anchor, false)}
                                                                className='add-track-list'
                                                            >
                                                                {list(anchor)}
                                                            </Drawer>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='table-div-feed'>
                                            {music.songDetails && music.songDetails.length > 0 ? <TableContainer >
                                                <Table aria-label="simple table" sx={{ minWidth: 650 }} >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell align="left">Track Name</TableCell>
                                                            <TableCell align="left">Artist</TableCell>
                                                            <TableCell align="left">Date</TableCell>
                                                            <TableCell align="left">Length</TableCell>
                                                            <TableCell align="left">Plays</TableCell>
                                                            <TableCell align="center"></TableCell>

                                                        </TableRow>
                                                    </TableHead>

                                                    {music.songDetails && music.songDetails.map((obj, index) => {
                                                        return (
                                                            <>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            {audioDurations[obj?.audio] !== undefined ? <>
                                                                                {audioplays[index] === 0 ?
                                                                                    <Tooltip title="Play Song" placement="top">
                                                                                        <img src={s6} className='music-play2'
                                                                                            onClick={() => { dispatch(indexData(index)); changeTracks(); setTracks(index); createMusic(obj?._id); setPlays(false); createMusic(obj?._id); setAudio(obj) }} />

                                                                                    </Tooltip>
                                                                                    : <Tooltip title="Playing" placement="top">
                                                                                        <img id="pauseImage" src={wave} className='music-play2' />

                                                                                    </Tooltip>}
                                                                            </> : <>
                                                                                <Tooltip title="Can't Play" placement="top">
                                                                                    <img src={s6} className='music-play2' />

                                                                                </Tooltip>
                                                                            </>}

                                                                        </TableCell>
                                                                        <TableCell >
                                                                            <div className='inside-div'>

                                                                                <div className='more-tracks-inner-div pad'>
                                                                                    <div className='music-play-main-div' onClick={((e) => { navigate(`/trackpage/${obj._id}`) })}>
                                                                                        <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                    </div>
                                                                                    <div>
                                                                                        {/* <span>{audioDurations[obj?.audio]}</span>  <MoreHorizIcon onClick={handleClick} /> */}
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </TableCell>

                                                                        <TableCell>{obj.nft_link}</TableCell>
                                                                        <TableCell>{new Date(obj?.createdAt).toLocaleDateString()}</TableCell>
                                                                        <TableCell><span>{audioDurations[obj?.audio]}</span> </TableCell>
                                                                        <TableCell>{obj?.players?.length ? obj?.players?.length : '0'} </TableCell>
                                                                        <TableCell>

                                                                            <Tooltip title={obj.isRepost === true ? "Unrepost" : "Repost"} placement="top"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 11" fill="var(--clr-font)" className={obj.isRepost === true ? "repeat" : "Repost"} onClick={() => { editSong({ id: obj?._id, status: "repost" }) }}>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6992 0.375C15.1067 0.375 15.4375 0.653048 15.4375 0.99574V5.82692H16.7394C16.9284 5.82692 17.0862 5.96322 17.1191 6.14313L17.125 6.21245C17.125 6.28722 17.1031 6.36043 17.0626 6.42274L14.517 10.317C14.4031 10.4392 14.2883 10.5 14.1719 10.5C14.0554 10.5 13.9407 10.4392 13.8259 10.317L11.2812 6.42274C11.2249 6.3368 11.2052 6.23462 11.226 6.13603C11.2467 6.03745 11.3064 5.94949 11.3934 5.88923C11.455 5.84877 11.5287 5.82699 11.6043 5.82692H12.9062L12.9054 2.71076L6.757 2.71154C6.56969 2.71154 6.39419 2.59938 6.28956 2.41324L5.34456 0.720807C5.32347 0.682709 5.31248 0.640533 5.3125 0.59775C5.3125 0.474692 5.39687 0.375 5.49981 0.375H14.6992ZM2.67578 10.5C2.26825 10.5 1.9375 10.222 1.9375 9.87926L1.93666 5.04808H0.635594C0.542009 5.04535 0.452502 5.01209 0.382983 4.95419C0.313464 4.89629 0.268437 4.81752 0.255906 4.73187L0.25 4.66255C0.25 4.58778 0.271937 4.51457 0.312437 4.45226L2.85803 0.558029C2.97194 0.43575 3.08669 0.375 3.20312 0.375C3.31956 0.375 3.43431 0.43575 3.54906 0.558029L6.09381 4.45226C6.15009 4.5382 6.16978 4.64038 6.14904 4.73897C6.1283 4.83755 6.06863 4.92551 5.98159 4.98577C5.91998 5.02623 5.84627 5.04801 5.77066 5.04808H4.46875V8.16346H10.618C10.8053 8.16346 10.9808 8.27561 11.0854 8.46176L12.0304 10.1542C12.0515 10.1923 12.0625 10.2345 12.0625 10.2773C12.0625 10.4003 11.9781 10.5 11.8752 10.5H2.67578Z" />
                                                                            </svg></Tooltip>


                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Tooltip title={obj.userFav === false ? "Add to Favourite" : "Remove From Favouite"} placement="top"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 20" fill="var(--clr-font)" className={obj.userFav === false ? "fav" : "unfav"} onClick={() => { addFavorite(obj._id) }}>
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.08938 0.875C3.15975 0.875 0.75 4.25876 0.75 6.75321C0.75 12.1451 7.67475 16.813 12.5625 20C17.4503 16.8116 24.375 12.1465 24.375 6.75321C24.375 4.25876 21.9653 0.875 18.0356 0.875C15.8438 0.875 13.9669 2.42413 12.5625 3.89402C11.1555 2.42413 9.28125 0.875 7.08938 0.875Z" />
                                                                            </svg></Tooltip>

                                                                        </TableCell>
                                                                    </TableRow >
                                                                </TableBody>

                                                            </>

                                                        )
                                                    })

                                                    }

                                                </Table>
                                            </TableContainer> : (<>
                                                <div className='add-false-content'>
                                                    This Album is empty. Start adding tracks to share it or make it public.
                                                </div>
                                            </>)}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            className='playlist-popover'
                        >

                        </Popover>
                    </Grid>
                    {music?.userFound === true || music.isMine === true ? <Grid container spacing={0} className='albumpage-grid'>
                        <Grid xs={10} sm={10} md={10} lg={11} xl={10}>

                        </Grid>
                    </Grid> : <></>}

                    {localStorage.getItem("playsong") === "true" ? <>{audiosources1.length > 0 ? <Tracks audiosources={audiosources1} tracks={tracks} /> : <></>} </> : <></>}

                    {/* {(localStorage.getItem("playsong") === "false" && audiosource) === true ? <Track audiosource={audiosource} /> : <>{(localStorage.getItem("playsong") === "false" && audio !== null) === true ? <Track audiosource={audio} /> : <></>} </>} */}


                    <Modal
                        keepMounted
                        open={openModel}
                        onClose={handleModelClose}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Box sx={style2}>
                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
                                    <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                        <div>
                                            <CloseIcon onClick={handleModelClose} />
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                                        <div className='edit-playlist-title'>
                                            {/* <QueueMusicIcon /> */}
                                            Add to Playlist
                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div id="search-wrap2">

                                        <InputBase
                                            id='search-input'
                                            sx={{ ml: 1, flex: 1 }}
                                            value={playname ? playname : ''}
                                            onChange={(e) => setPlayname(e.target.value)}
                                            placeholder="Search your music"
                                            inputProps={{ 'aria-label': 'search google maps' }}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                            <SearchIcon />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className='new-playlist' onClick={((e) => { navigate('/createplaylist', { state: { song_id } }) })}>
                                        <AddCircleIcon />
                                        <div>
                                            New Playlist
                                        </div>
                                    </div>
                                </Grid>
                                {play.length > 0 ? (<>

                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >
                                        {play && play.map((row, ind) => {
                                            return (
                                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} onClick={() => { handleAdd(row?._id) }}>
                                                    <div className='new-playlist created'>
                                                        <div className='d-flex'>
                                                            <img src={row?.image} alt='img' />
                                                        </div>
                                                        <div>
                                                            {row?.playlist}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </>) : (<>
                                    <div className="newno">
                                        <img src={newno} />
                                    </div>


                                </>)}



                            </Grid>

                        </Box>
                    </Modal>

                </Box>
            </Box >
        </div >
    )
}

export default AlbumPage

import React, { useState, useEffect, useRef } from "react";
import "../ExploreNew/ExploreNew.css";
import "../MusicPlayerNew/MusicPlayerNew.css";
import HeaderNew from "../HeaderNew/HeaderNew";
import UndoIcon from "@mui/icons-material/Undo";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tracks from "../Music-traxk/Tracks";
import RepeatIcon from "@mui/icons-material/Repeat";
import VerifiedIcon from "@mui/icons-material/Verified";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import ava1 from "../../img/dster-webp/ava1.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "../../Axios";
import { Button } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import bgs from "../../img/dster-webp/feed-banner1.webp";
import { Link, useNavigate } from "react-router-dom";
import nrplay from "../../img/dster-webp/nr-play.webp";
import SearchIcon from "@mui/icons-material/Search";
import "./FeedNew.css";
import Stack from "@mui/material/Stack";
import fdp3 from "../../img/dster-webp/fdplpas.webp";
import NewMusicTrack from "../NewMusicTrack/NewMusicTrack";
import Popover from "@mui/material/Popover";
import Skeleton from "@mui/material/Skeleton";
import SendTrack from "../ShareLink/SendTrack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import s6 from "../../img/dster-webp/pass-play.webp"
import Modal from "@mui/material/Modal";
import Companylogo1 from "../../img/dster-webp/musicwave.gif";

// import Companylogo1 from '../../img/phause.png';
import Companylogo from '../../img/dster-webp/company-logo.webp';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Album2 from "../../img/dster-webp/bs4.webp";
import nodata from "../../img/dster-webp/newnodata.gif";

const drawerWidth = 240;

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "var(--clr-bcg)",
  height: "620px",
  overflow: "scroll",
  border: "2px solid #000",
  boxShadow: "none",
  p: 4,
  "@media all and (max-width: 1199.98px)": {
    width: "55%",
  },
  "@media all and (max-width: 768.98px)": {
    width: "85%",
  },
  "@media all and (max-width: 575.98px)": {
    width: "95%",
  },
};

function FeedNew() {
  const navigate = useNavigate();
  const [name, setName] = React.useState();
  const [see, setSee] = useState(true);
  const [btnactive1, setBtnactive1] = useState(true);
  const [btnactive2, setBtnactive2] = useState(false);
  const [fav, setFav] = useState(false);
  const [exp, setExp] = useState(false);
  const [hist, setHist] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [showMessage1, setShowMessage1] = useState(true);
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(true);
  const [music, setMusic] = React.useState([]);
  const [topmusic, setTopmusic] = React.useState([]);
  const [recent, setRecent] = React.useState([]);
  const [limit, setLimit] = React.useState(5);
  const [limit1, setLimit1] = React.useState(5);
  const [playname, setPlayname] = React.useState();
  const [song_id, setsongid] = React.useState();
  const [song_id1, setsongid1] = React.useState();
  const [play, setPlay] = React.useState([]);
  const [audiosource, setAudiosource] = React.useState();
  const [count, setCount] = React.useState();
  const tokens = localStorage.getItem("iphephile");
  const [audioDurations, setAudioDurations] = useState([]);
  const [audioDurations1, setAudioDurations1] = useState([]);
  const [audioplays, setAudioplays] = useState([]);
  const [isplay, setisplay] = useState(0);
  const [audioplays1, setAudioplays1] = useState([]);
  const [audioplays2, setAudioplays2] = useState([]);
  const [audioplays3, setAudioplays3] = useState([]);
  const [openModel, setOpenModel] = React.useState(false);
  const handleOpen = () => setOpenModel(true);
  const handleModelClose = () => setOpenModel(false);
  const [rows, setRows] = useState({});
  const [newlist, setNewList] = useState(false);
  const [tracks, setTracks] = useState();

  const handlenewPlayList = () => setNewList(!newlist);
  const [openModel2, setOpenModel2] = React.useState(false);
  const handleOpen2 = () => setOpenModel2(true);
  const handleModelClose2 = () => setOpenModel2(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const songlist = useSelector((state) => state.songs);
  const play_mood = useSelector((state) => state.play_mood);
  const index = useSelector((state) => state.index);
  const [audiosources, setAudiosources] = React.useState(songlist);
  const audio = localStorage.getItem("audiosource");
  const audios = localStorage.getItem("audiosources");

  const handleClickEl2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClickEl3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleCloseEl2 = () => {
    setAnchorEl2(null);
  };
  const handleCloseEl3 = () => {
    setAnchorEl3(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const navigate = useNavigate()

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover2" : undefined;
  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popover3" : undefined;

  const getAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };
  const getPreAudioDuration = async (audioSrc) => {
    try {
      const audio = new Audio(audioSrc);
      await audio.addEventListener("loadedmetadata", () => {
        const duration = audio.duration;
        const durationInSeconds = audio.duration;
        const durationInMinutes = (durationInSeconds / 60).toFixed(2);
        setAudioDurations1((prev) => ({
          ...prev,
          [audioSrc]: durationInMinutes,
        }));
      });
    } catch (error) {
      console.error(error);
      setAudioDurations1((prev) => ({
        ...prev,
        [audioSrc]: 0, // Default duration or handle error accordingly
      }));
    }
  };

  const getaudioPlays = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 1,
        }));
      } else {
        setAudioplays((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  const getaudioPlays1 = async (audioSrc, index) => {
    try {
      if (audioSrc === localStorage.getItem("songName")) {
        setAudioplays1((prev) => ({
          ...prev,
          [index]: 1,
        }));
      } else {
        setAudioplays1((prev) => ({
          ...prev,
          [index]: 0,
        }));
      }
    } catch (error) {
      console.error(error);
      setAudioplays1((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  useEffect(() => {
    if (music.length > 0) {
      music.forEach((row) => {
        getAudioDuration(row?.audio);
        getPreAudioDuration(row?.pre_audio)
      });
    }
    if (music.length > 0) {
      music.forEach((row, index) => {
        getaudioPlays1(row?.song_name, index);
      });
    }
  }, [music]);
  useEffect(() => {
    if (topmusic.length > 0) {
      topmusic.forEach((row) => {
        getAudioDuration(row?.audio);
        getPreAudioDuration(row?.pre_audio)
      });
    }
    if (topmusic.length > 0) {
      topmusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [topmusic]);

  useEffect(() => {
    if (topmusic.length > 0) {
      topmusic.forEach((row, index) => {
        getaudioPlays(row?.song_name, index);
      });
    }
  }, [values]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage1(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage1]);

  // console.log(showMessage1, 'showmessage');

  const tabContent = ["All", "original", "repost"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // add song to Playlist
  const handleAdd = async (data) => {
    try {
      const create_data = {
        playlistId: data,
        songId: song_id,
      };

      await Axios.post("/users/edit_playlist", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            toast.success(res?.data?.message);
            getMusic();
            handleModelClose();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {

      setTracks(
        index !== undefined ? index : Number(localStorage.getItem("tracks"))
      );

      isMounted.current = true;
    } else {
      console.log("fetched");
    }
  }, []);

  const changeLimit = () => {
    if (exp === false) {
      setLimit(20);
    } else {
      setLimit(5);
    }
    setExp(!exp);
  };

  const seeMore = () => {

    if (see == false) {
      // music.slice(0,5)
      setLimit1(5);
    } else {
      setLimit1(20);
    }

    setSee(!see);
  };

  useEffect(() => {
    if (tokens) {
      getplayLists();
    }
    isMounted.current = true;
  }, [playname]);

  useEffect(() => {
    if (tokens) {
      getMusic();
      getTopmusic();
    } else {
      getMusics();
      getTopmusics();
    }
  }, [name, limit1]);

  // to get Top Streaming Songs

  const getTopmusic = async () => {
    try {
      const find_data = {
        explore: "Top Stream",
        name: name,
      };
      const datas = await Axios.post("/users/get_user_favourite", find_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setTopmusic(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setTopmusic([]);
          }
        })
        .catch((err) => {
          console.log(err, "errsss")
          setTopmusic([]);
          if (err?.response?.data?.message == "Admin Blocked You") {
            localStorage.clear();
            navigate('/');
            toast.error('Admin Blocked You');
          }
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  const getTopmusics = async () => {
    try {
      const find_data = {
        explore: "Top Stream",
        name: name,
      };
      const datas = await Axios.post("/users/get_user_favourites", find_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setTopmusic(res?.data?.result);
          } else {
            //toast.error(err?.response?.data?.message)
            setTopmusic([]);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          setTopmusic([]);
          if (err?.response?.data?.message == "Admin Blocked You") {
            localStorage.clear();
            navigate('/');
            toast.error('Admin Blocked You');
          }
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get All songs,playlist,albums with Creator Details

  const getMusic = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_user_music",
        {
          status: tabContent[value],
          limit: limit1,
          name: name,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setMusic(res?.data?.result);
            setRecent(res?.data?.recentList);
            setCount(res?.data?.count);
          } else {
            setMusic([]);
            setRecent([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getMusics = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_user_musics",
        {
          status: tabContent[value],
          limit: limit1,
          name: name,
        },
      )
        .then((res) => {
          if (res?.data?.success) {
            setMusic(res?.data?.result);
            setRecent(res?.data?.recentList);
            setCount(res?.data?.count);
          } else {
            setMusic([]);
            setRecent([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  // to get song detail which is currently playing

  const setAudio = (data) => {
    localStorage.setItem("audiosource", data.audio);
    localStorage.setItem("songName", data.song_name);
    localStorage.setItem("Image", data.image);
    localStorage.setItem("current", 0);
    localStorage.setItem("tracks", 0);
    localStorage.setItem("isPause", false);
    setAudiosource(data.audio);
    localStorage.setItem("playsong", "false");
    setValues(!values);
    setisplay(true);
  };

  const setAudios = (data) => {
    if (audioDurations1[data?.pre_audio] !== undefined) {
      localStorage.setItem("audiosource", data.pre_audio);
      localStorage.setItem("songName", data.song_name);
      localStorage.setItem("Image", data.image);
      localStorage.setItem("current", 0);
      localStorage.setItem("tracks", 0);
      localStorage.setItem("isPause", false);
      setAudiosource(data.pre_audio);
      localStorage.setItem("playsong", "false");
      setValues(!values);
      setisplay(true);
    }
    // else {
    //   toast.error("Error: Invalid")
    // }
  };

  // console.log(isplay, 'isplay');

  const removeAudios = (data) => {
    setisplay(false);
  };

  // to add song to history

  const createMusic = async (data) => {
    try {
      const create_data = {
        song_id: data,
      };

      await Axios.post("/users/add_history", create_data, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            // getMusic()
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          // console.log(err, "err")
          toast.error(err?.response?.data?.message);
        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to repost/unrepost Songs

  const editSong = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          songId: data.id,
          status: data.status,
        };

        await Axios.post("/users/edit_song_details", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to Repost this Song")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to repost/unrepost albums

  const editAlbums = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          album_id: data.id,
          status: data.status,
        };

        await Axios.post("/users/edit_album_details", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to Repost this Album")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to repost/unrepost playlist

  const editPlaylists = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          album_id: data.id,
          status: data.status,
        };

        await Axios.post("/users/edit_playlist_details", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to Repost this PlayList");
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to add song to library

  const addLibrary = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          song_id: data,
        };

        await Axios.post("/users/add_library", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              toast.success(res?.data?.message);
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Song to your Library")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to favourite/unfavorite a song

  const addFavorite = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          song_id: data,
        };

        await Axios.post("/users/add_to_favourite", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
              getTopmusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Song to your Favourite")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to favourite/unfavorite a Album

  const addalbumFavorite = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          album_id: data,
        };

        await Axios.post("/users/add_fav_album", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Album to your Favourite")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to favourite/unfavorite a Playlist

  const addplayFavorite = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          album_id: data,
        };

        await Axios.post("/users/add_fav_playlist", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Playlist to your Favourite")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  const [send, setSend] = useState();
  const sendTracks = (data) => {
    handleClose();
    handleOpen2();
    setSend(data);
  };

  // to add album to library

  const addalbumLibrary = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          album_id: data,
        };

        await Axios.post("/users/add_album_to_library", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              toast.success(res?.data?.message);
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Album to your Library")
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to add playlist to library

  const addplayLibrary = async (data) => {
    try {
      if (tokens) {
        const create_data = {
          play_id: data,
        };

        await Axios.post("/users/add_playlist_to_library", create_data, {
          headers: {
            Authorization: tokens,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              toast.success(res?.data?.message);
              getMusic();
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            // console.log(err, "err")
            toast.error(err?.response?.data?.message);
          });
      }
      else {
        toast.error("Please Login to add this Playlist to your Library");
      }
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get user playlist

  const getplayLists = async () => {
    try {
      const datas = await Axios.post(
        "/users/get_my_playlist",
        {
          playname: playname,
        },
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setPlay(res?.data?.result);
          } else {
            setPlay([]);
          }
        })
        .catch((err) => {
          // console.log(err,"err")
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const addPlay = (data) => {
    setsongid(data);
    handleOpen();
    handleCloseEl2();
    handleClose();
  };

  const options = {
    margin: 30,
    items: 4,
    responsiveClass: true,
    //  nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    dots: false,
    loop: true,
    smartSpeed: 2000,
    autoHeightClass: "owl-height",
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const navigation = (row, id) => {
    if (row === "Album") {
      navigate(`/albumpage/${id}`);
    } else if (row === "Track") {
      navigate(`/trackpage/${id}`);
    } else if (row === "Playlist") {
      navigate(`/showplaylist/${id}`);
    }
  };

  const mappedRows = recent?.slice(0, limit1).map((row, ind) => {
    return (
      <>
        {showMessage1 === true ? (
          <Stack spacing={1}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              sx={{ bgcolor: "var(--clr-skel)" }}
              width={150}
              height={150}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "15px", bgcolor: "var(--clr-skel)" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "12px", bgcolor: "var(--clr-skel)" }}
            />
          </Stack>
        ) : (
          <div
            key={ind}
            className="nft-item-nr"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/trackpage/${row._id}`);
            }}
          >
            <div className="nr">
              <img
                src={
                  row?.image && !row?.image.includes("localhost")
                    ? row?.image
                    : ava1
                }
                alt="img"

              />
            </div>
            <div className="nr-artist">{row?.song_name}</div>
            <div className="song-new">
              {row?.createUser?.name}{" "}
              {row?.nft_type === "per" ? (
                <>
                  <VerifiedIcon className="premium" />
                </>
              ) : (
                <></>
              )}
              {/* {row?.nft_type !== "nor" ? } */}
            </div>
            {row?.nft_type === "per" ?
              <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">
                <div className="play-new2" onMouseOver={() => setAudios(row)}>
                  <img src={nrplay} alt="play"

                  // onMouseOut={() => setisplay(false)}
                  />
                </div>
              </Tooltip> : <></>}
          </div>
        )}
      </>
    );
  });

  //music
  const Images = localStorage.getItem("Image");
  const songNames = localStorage.getItem("songName");
  const isPause = localStorage.getItem("isPause");
  const isPauses = localStorage.getItem("isPauses");
  var Images1 = "../../img/feed-newbg.png";
  const bg = `${Images !== null && Images.includes("localhost")
    ? `url(${Images})`
    : `url(${bgs})`
    }`;

  const [title, setTitle] = useState("feed");

  const navigationto = (row) => {
    if (row?.type === "Album") {
      navigate(`/albumpage/${row?.AlbumDetails[0]?._id}`);
    } else if (row?.type === "Track") {
      navigate(`/trackpage/${row?._id}`);
    } else if (row?.type === "Playlist") {
      navigate(`/showplaylist/${row?.PlaylistDetails[0]._id}`);
    }
  };

  return (
    <div className="Explore-new">
      <Box sx={{ display: "flex" }}>
        <HeaderNew name={name} setName={setName} title={title} />
        <ToastContainer autoClose={3000} position="top-right" />

        <Box
          component="main"
          className="explore-box"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "135px",
            marginBottom: "100px",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              sx={{ padding: "15px" }}
            >
              <Grid container spacing={0}>
                {showMessage === true ? (
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      sx={{
                        width: "100%",
                        height: "500px",
                        margin: "20px 0px",
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="feed-new-main-grid"
                    sx={{
                      padding: "15px",
                      borderRadius: "15px",
                      margin: "15px 0px",
                    }}
                    style={{ backgroundImage: bg }}
                  >
                    <div className="feed-new-whole">
                      <div></div>
                      <div className="feed-new-search">
                        <SearchIcon sx={{ fill: "#000" }} />
                        <input
                          type="text"
                          placeholder="Search playlist, songs, albums..."
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="feed-new-player">
                      <div className="feed-new-player-controls">
                        <img
                          src={
                            Images !== null && !Images?.includes("localhost")
                              ? Images
                              : Album2
                          }
                          alt="c8"
                        />
                      </div>
                      <Box sx={{ width: "80%", margin: "auto" }}>
                        <div className="audio-timmer-feed">
                          <div>{songNames !== null ? songNames : ""}</div>
                          {songNames !== null && isPause === "false" ? (
                            <img
                              src={Companylogo1}
                              id="pauseImage"
                              onClick={() => {
                                setisplay(false);
                              }}
                              alt={Companylogo}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div></div>
                      </Box>
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={0}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="enew-g1-main">
                    <div className="enew-g1-release">
                      <div className="new-release-mxp">
                        New Releases <img src={nrplay} alt="nr" />
                      </div>
                      <div
                        className="new-release-btns"
                        onClick={() => {
                          seeMore();
                        }}
                      >
                        {recent?.length > 0 ? (
                          <>{see ? "See More" : "See Less"} </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="nr-owlcarosual">
                      <div className="lazy-load">
                        {see ? (
                          <OwlCarousel
                            className="owl-theme owl-carousel nft"
                            {...options}
                            sx={{ width: "160px", height: "204px" }}
                          >
                            {mappedRows}
                          </OwlCarousel>
                        ) : (
                          <Grid container spacing={0}>
                            {mappedRows.map((row) => {
                              return (
                                <Grid xs={12} sm={12} md={6} lg={4} xl={3}>
                                  {row}
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={4}
              sx={{ padding: "15px" }}
            >
              <div className="enew-g1-main">
                <div className="top-stream-mian">
                  <div className="top-stemers">Top Streaming Songs</div>
                </div>
                {btnactive1 && (
                  <div className={exp ? "exp-tran" : "stream-iner-display"}>
                    {topmusic?.length > 0 ? (
                      <>
                        {topmusic.slice(0, limit)?.map((row, ind) => {
                          return (
                            <>
                              <div className="ts-c1-whole">
                                <div className="ts-c1-left">
                                  <div className="ts-coun">{ind + 1}</div>
                                  <div className="ts-c1-imgdiv cursor">
                                    <img
                                      src={
                                        row?.image &&
                                          !row?.image?.includes("localhost")
                                          ? row?.image
                                          : ava1
                                      }
                                      alt="ts1"

                                    />
                                    {row.nft_type == "per" ?
                                      <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                        <img
                                          src={nrplay}
                                          style={{ width: "30px", height: "30px" }}
                                          onMouseOver={() => setAudios(row)}
                                          // onMouseOut={() => setisplay(false)}
                                          className="hover-play"
                                        />
                                      </Tooltip> : <></>}
                                  </div>
                                  <div
                                    className="milestone"
                                    onClick={() => navigationto(row)}
                                  >
                                    {row?.song_name}
                                    {row?.nft_type === "per" ? (
                                      <>
                                        <VerifiedIcon className="premium" />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="jsuti">
                                      {row?.createUser?.name}
                                    </div>
                                  </div>
                                </div>
                                <div className="ts-c1-right">
                                  <div className="ts-t-time">
                                    {audioDurations[row?.audio] !==
                                      undefined ? (
                                      <span>{audioDurations[row?.audio]}</span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </div>
                                  <div>
                                    <Tooltip
                                      title={
                                        row?.userFav === true
                                          ? "Remove From Favourite"
                                          : "Add to Favourite"
                                      }
                                      placement="top"
                                    >
                                      <FavoriteIcon
                                        onClick={() => {
                                          addFavorite(row?._id);
                                        }}
                                        className={
                                          row?.userFav === true
                                            ? "fav-heart"
                                            : "normal-svg"
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    {(audioDurations[row?.audio] !==
                                      undefined &&
                                      row?.nft_type === "nor") ||
                                      row?.isMine === true ? (
                                      <>
                                        {audioplays[ind] === 0 ||
                                          isplay === false ? (
                                          <Tooltip
                                            title="Play Song"
                                            placement="top"
                                          >
                                            <img
                                              src={nrplay}
                                              onClick={() => {
                                                setAudio(row);
                                                createMusic(row?._id);
                                                setAudiosource(row?.audio);
                                              }}
                                              style={{ cursor: "pointer" }}
                                              alt="nr"
                                            />
                                          </Tooltip>
                                        ) : (
                                          <img
                                            src={Companylogo1}
                                            id="pauseImage"
                                            onClick={() => {
                                              setisplay(false);
                                            }}
                                            alt={Companylogo}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {row?.nft_type === "per" &&
                                          audioDurations[row?.audio] !==
                                          undefined ? (
                                          <Tooltip
                                            title="Premium Song"
                                            placement="top"
                                          >
                                            {(audioplays[ind] == 0 ||
                                              isplay == false) == true ? (
                                              <img
                                                src={nrplay}
                                                onClick={() => {
                                                  setAudios(row);
                                                  createMusic(row?._id);
                                                  setAudiosource(
                                                    row?.pre_audio
                                                  );
                                                }}
                                                style={{ cursor: "pointer" }}
                                                alt="nr"
                                              />
                                            ) : (
                                              <img
                                                src={Companylogo1}
                                                id="pauseImage"
                                                onClick={() => {
                                                  setisplay(false);
                                                }}
                                                alt={Companylogo}
                                              />
                                            )}
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="Can't Play"
                                            placement="top"
                                          >
                                            <img
                                              src={nrplay}
                                              style={{ cursor: "pointer" }}
                                              alt="nr"
                                            />
                                          </Tooltip>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  <div>
                                    <MoreHorizIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        handleClick(e);
                                        setsongid(row?._id);
                                        setRows(row);
                                      }}
                                    />
                                    <Popover
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      className="playlist-popover"
                                    >
                                      <div
                                        className="playlist-more-div"
                                        onClick={() => {
                                          sendTracks(rows?._id);
                                        }}
                                      >
                                        Share
                                      </div>
                                      <div
                                        className="playlist-more-div"
                                        onClick={(e) => {
                                          navigate(`/trackpage/${rows._id}`);
                                        }}
                                      >
                                        Visit Track Page
                                      </div>
                                      <div
                                        className="playlist-more-div"
                                        onClick={(e) => {
                                          navigate(
                                            `/artistpage/${rows.createUser?._id}`
                                          );
                                        }}
                                      >
                                        Visit Artist Page
                                      </div>
                                    </Popover>
                                  </div>
                                </div>
                              </div>
                              <div className="hr"></div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="description-History"></div>
                          <div className="description-History"></div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="newno">
                            <img src={nodata} />
                          </div>
                        </Grid>
                      </>
                    )}
                  </div>
                )}

                <div className="stream-exp-btn">
                  <Button
                    onClick={() => {
                      changeLimit();
                    }}
                  >
                    {topmusic?.length > 0 ? (
                      <>
                        {" "}
                        {exp ? "Show Less" : "Show All"}{" "}
                        {exp ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={8}
              sx={{ padding: "15px" }}
            >
              <div className="enew-g1-main">
                <Grid container spacing={0} sx={{ justifyContent: "center" }}>
                  <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    {music?.map((row, ind) => {
                      return (
                        <>
                          {showMessage1 === true ? (
                            <Stack
                              spacing={1}
                              direction="row"
                              className="sk-stack main"
                            >
                              <Stack spacing={1} direction="row">
                                <Skeleton
                                  variant="rectangular"
                                  animation="wave"
                                  width={100}
                                  height={100}
                                  sx={{ bgcolor: "var(--clr-skel)" }}
                                />
                                <Stack
                                  spacing={1}
                                  className="sk-stack-inner"
                                >
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    sx={{
                                      fontSize: "15px",
                                      bgcolor: "var(--clr-skel)",
                                    }}
                                    width={150}
                                  />
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    sx={{
                                      fontSize: "12px",
                                      bgcolor: "var(--clr-skel)",
                                    }}
                                    width={120}
                                  />
                                  <Skeleton
                                    variant="text"
                                    animation="wave"
                                    sx={{
                                      fontSize: "12px",
                                      bgcolor: "var(--clr-skel)",
                                    }}
                                    width={70}
                                  />
                                </Stack>
                              </Stack>
                              <Stack spacing={3} direction="row">
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{
                                    borderRadius: "15px",
                                    bgcolor: "var(--clr-skel)",
                                  }}
                                  width={25}
                                  height={25}
                                />
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{
                                    borderRadius: "15px",
                                    bgcolor: "var(--clr-skel)",
                                  }}
                                  width={25}
                                  height={25}
                                />
                                <Skeleton
                                  variant="rounded"
                                  animation="wave"
                                  sx={{
                                    borderRadius: "15px",
                                    bgcolor: "var(--clr-skel)",
                                  }}
                                  width={25}
                                  height={25}
                                />
                              </Stack>
                              <Stack spacing={1} direction="row">
                                <Skeleton
                                  variant="circular"
                                  animation="wave"
                                  sx={{ bgcolor: "var(--clr-skel)" }}
                                  width={60}
                                  height={60}
                                />
                              </Stack>
                            </Stack>
                          ) : (
                            <div className="e-news-list feed">
                              <div className="history-new-main">
                                <div className="history-new-left">
                                  {row?.type === "Track" ? (
                                    <div className="hover-play-div">
                                      <img
                                        src={
                                          row?.image !== null &&
                                            !row?.image?.includes("localhost")
                                            ? row?.image
                                            : ava1
                                        }
                                        alt="img"
                                        className="images"
                                        onClick={() => {
                                          navigation(row?.type, row?._id);
                                        }}

                                      />
                                      {row.nft_type == "per" ?
                                        <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">

                                          <img
                                            src={fdp3}
                                            style={{ cursor: "pointer" }}
                                            alt="nr"
                                            onMouseOver={() => setAudios(row)}
                                            // onMouseOut={() => setisplay(false)}
                                            className="hover-play2"
                                          />
                                        </Tooltip> : <></>}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Album" ? (
                                    <div className="hover-play-div">
                                      <img
                                        src={
                                          row?.AlbumDetails?.image !== null &&
                                            !row?.AlbumDetails?.image?.includes(
                                              "localhost"
                                            )
                                            ? row?.AlbumDetails?.image
                                            : ava1
                                        }
                                        alt="img"
                                        className="images"
                                        onClick={() => {
                                          navigation(
                                            row?.type,
                                            row?.AlbumDetails?._id
                                          );
                                        }}
                                      />
                                      <img
                                        src={fdp3}
                                        style={{ cursor: "pointer" }}
                                        alt="nr"
                                        className="hover-play2"
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Playlist" ? (
                                    <div className="hover-play-div">
                                      <img
                                        src={
                                          row?.PlaylistDetails?.image !==
                                            null &&
                                            !row?.PlaylistDetails?.image?.includes(
                                              "localhost"
                                            )
                                            ? row?.PlaylistDetails?.image
                                            : ava1
                                        }
                                        alt="img"
                                        className="images"
                                        onClick={() => {
                                          navigation(
                                            row?.type,
                                            row?.PlaylistDetails?._id
                                          );
                                        }}
                                      />
                                      <img
                                        src={fdp3}
                                        style={{ cursor: "pointer" }}
                                        alt="nr"
                                        className="hover-play2"
                                      />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {/* <img src={row.img} alt='ct1' /> */}

                                  <div className="hist-mai">
                                    {row?.type} -
                                    {row?.type === "Track" ? (
                                      <> {row?.song_name}</>
                                    ) : (
                                      <></>
                                    )}{" "}
                                    {row?.type === "Track" &&
                                      row?.nft_type === "per" ? (
                                      <>
                                        <VerifiedIcon className="premium" />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {row?.type === "Album" ? (
                                      <>{row?.AlbumDetails?.album_name}</>
                                    ) : (
                                      <></>
                                    )}
                                    {row?.type === "Playlist" ? (
                                      <>{row?.PlaylistDetails?.playlist}</>
                                    ) : (
                                      <></>
                                    )}
                                    <div className="feedn-p">
                                      {row?.type === "Track" ? (
                                        <p>
                                          {" "}
                                          <RepeatIcon className="repeat" />
                                          {row?.reposts?.length
                                            ? row?.reposts?.length
                                            : 0}{" "}
                                          reposts
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.type === "Album" ? (
                                        <p>
                                          {" "}
                                          <RepeatIcon className="repeat" />
                                          {row?.AlbumDetails?.reposts?.length
                                            ? row?.AlbumDetails?.reposts?.length
                                            : 0}{" "}
                                          reposts
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.type === "Playlist" ? (
                                        <p>
                                          {" "}
                                          <RepeatIcon className="repeat" />
                                          {row?.PlaylistDetails?.reposts?.length
                                            ? row?.PlaylistDetails?.reposts
                                              ?.length
                                            : 0}{" "}
                                          reposts
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.type === "Track" ? (
                                        <p>
                                          {" "}
                                          <FavoriteIcon className="unfav" />
                                          {row?.fav_count
                                            ? row?.fav_count
                                            : 0}{" "}
                                          Favourite
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.type === "Album" ? (
                                        <p>
                                          {" "}
                                          <FavoriteIcon className="unfav" />
                                          {row?.AlbumDetails?.fav_count
                                            ? row?.AlbumDetails?.fav_count
                                            : 0}{" "}
                                          Favourite
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.type === "Playlist" ? (
                                        <p>
                                          {" "}
                                          <FavoriteIcon className="unfav" />
                                          {row?.PlaylistDetails?.fav_count
                                            ? row?.PlaylistDetails?.fav_count
                                            : 0}{" "}
                                          Favourite
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="maroon">
                                      <Link
                                        to={`/artistpage/${row.createUser?._id}`}
                                      >
                                        {row?.createUser?.name}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="history-new-right">
                                  <div className="playtime">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10"
                                      height="12"
                                      viewBox="0 0 10 12"
                                      fill="none"
                                      style={{ cursor: "default" }}
                                    >
                                      <path
                                        d="M9.65909 7.07169C9.61648 7.13001 9.41761 7.37789 9.26136 7.53828L9.17614 7.62576C7.98295 8.95261 5.0142 10.9502 3.50852 11.5917C3.50852 11.6063 2.61364 11.9854 2.1875 12H2.13068C1.47727 12 0.866477 11.6209 0.553977 11.0085C0.383523 10.6731 0.227273 9.69623 0.213068 9.68165C0.0852273 8.8068 0 7.46683 0 5.99271C0 4.44714 0.0852273 3.04739 0.241477 2.18712C0.241477 2.17254 0.397727 1.38518 0.497159 1.12272C0.653409 0.743621 0.9375 0.422843 1.29261 0.218712C1.5767 0.072904 1.875 0 2.1875 0C2.5142 0.0145808 3.125 0.234751 3.36648 0.335358C4.95739 0.976914 7.99716 3.07655 9.16193 4.35966C9.3608 4.56379 9.57386 4.81167 9.63068 4.86999C9.87216 5.19077 10 5.58445 10 6.00875C10 6.38639 9.88636 6.76549 9.65909 7.07169Z"
                                        fill="#7C8DB5"
                                        fill-opacity="0.72"
                                      />
                                    </svg>{" "}
                                    {row?.type === "Track" ? (
                                      <> {row?.players?.length}</>
                                    ) : (
                                      <></>
                                    )}
                                    {row?.type === "Album" ? (
                                      <>{row?.AlbumDetails?.players?.length}</>
                                    ) : (
                                      <></>
                                    )}
                                    {row?.type === "Playlist" ? (
                                      <>
                                        {row?.PlaylistDetails?.players?.length}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {/* <MoreHorizIcon /> */}
                                  {row?.type === "Track" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.isRepost === true
                                            ? "Unrepost"
                                            : "Repost"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className="playtime feed-repost"
                                          onClick={() => {
                                            editSong({
                                              id: row?._id,
                                              status: "repost",
                                            });
                                          }}
                                        >
                                          <RepeatIcon
                                            className={
                                              row?.isRepost === true
                                                ? "repeat"
                                                : "repeats"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Album" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.AlbumDetails?.isRepost === true
                                            ? "Unrepost"
                                            : "Repost"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className="playtime feed-repost"
                                          onClick={() => {
                                            editAlbums({
                                              id: row?.AlbumDetails?._id,
                                              status: "repost",
                                            });
                                          }}
                                        >
                                          <RepeatIcon
                                            className={
                                              row?.AlbumDetails?.isRepost ===
                                                true
                                                ? "repeat"
                                                : "repeats"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Playlist" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.PlaylistDetails?.isRepost ===
                                            true
                                            ? "Unrepost"
                                            : "Repost"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className="playtime feed-repost"
                                          onClick={() => {
                                            editPlaylists({
                                              id: row?.PlaylistDetails?._id,
                                              status: "repost",
                                            });
                                          }}
                                        >
                                          <RepeatIcon
                                            className={
                                              row?.PlaylistDetails?.isRepost ===
                                                true
                                                ? "repeat"
                                                : "repeats"
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {row?.type === "Track" ? (
                                    <>
                                      {row?.nftclaim !== 0 &&
                                        row?.userFound === false &&
                                        row?.isMine === false ? (
                                        <Tooltip
                                          title="Add to Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addLibrary(row?._id);
                                            }}
                                          >
                                            <DownloadIcon />
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.userFound === false ? (
                                        <></>
                                      ) : (
                                        <Tooltip
                                          title="Remove from Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addLibrary(row?._id);
                                            }}
                                          >
                                            <UndoIcon />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {row?.type === "Album" ? (
                                    <>
                                      {row?.AlbumDetails?.userFound === false &&
                                        row?.AlbumDetails?.isMine === false ? (
                                        <Tooltip
                                          title="Add to Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addalbumLibrary(
                                                row?.AlbumDetails?._id
                                              );
                                            }}
                                          >
                                            <DownloadIcon />
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.AlbumDetails?.userFound ===
                                        false ? (
                                        <></>
                                      ) : (
                                        <Tooltip
                                          title="Remove from Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addalbumLibrary(
                                                row?.AlbumDetails?._id
                                              );
                                            }}
                                          >
                                            <UndoIcon />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {row?.type === "Playlist" ? (
                                    <>
                                      {row?.PlaylistDetails?.userFound ===
                                        false &&
                                        row?.PlaylistDetails?.isMine === false ? (
                                        <Tooltip
                                          title="Add to Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addplayLibrary(
                                                row?.PlaylistDetails?._id
                                              );
                                            }}
                                          >
                                            <DownloadIcon />
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                      {row?.PlaylistDetails?.userFound ===
                                        false ? (
                                        <></>
                                      ) : (
                                        <Tooltip
                                          title="Remove from Library"
                                          placement="top"
                                        >
                                          <div
                                            className="playtime more-histore"
                                            onClick={() => {
                                              addplayLibrary(
                                                row?.PlaylistDetails?._id
                                              );
                                            }}
                                          >
                                            <UndoIcon />
                                          </div>
                                        </Tooltip>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {row?.type === "Track" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.userFav === true
                                            ? "Remove from Favourite"
                                            : "Add To Favourite"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            row?.userFav === true
                                              ? "playtime hist-fav"
                                              : "playtime"
                                          }
                                          onClick={() => {
                                            addFavorite(row?._id);
                                            setHist(!hist);
                                          }}
                                        >
                                          <svg
                                            key={ind}
                                            width="25"
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2378_5148)">
                                              <path
                                                opacity="0.4"
                                                d="M9.81207 18.1979C7.90954 17.0227 6.14095 15.6371 4.53867 14.0663C3.40752 12.9448 2.54365 11.5754 2.01307 10.0627C1.06504 7.11269 2.16861 3.74123 5.24969 2.74033C6.87595 2.22961 8.64464 2.54313 10.0046 3.5832C11.3651 2.54429 13.1333 2.23087 14.7596 2.74033C17.8407 3.74123 18.9516 7.11269 18.0036 10.0627C17.4773 11.574 16.6186 12.9433 15.4928 14.0663C13.8891 15.6354 12.1207 17.0209 10.2194 18.1979L10.012 18.3333L9.81207 18.1979Z"
                                                fill="#B9C0DE"
                                              />
                                              <path
                                                d="M10.0084 18.3332L9.81267 18.1978C7.90779 17.0228 6.13674 15.6371 4.53186 14.0662C3.39535 12.9463 2.52634 11.5767 1.99145 10.0626C1.05083 7.11261 2.15439 3.74115 5.23548 2.74024C6.86173 2.22952 8.65375 2.54328 10.0084 3.59206V18.3332Z"
                                                fill="#7C8DB5"
                                                fill-opacity="0.72"
                                              />
                                              <path
                                                d="M15.1907 8.33269C15.0233 8.32191 14.8674 8.23825 14.7596 8.1013C14.6517 7.96436 14.6014 7.78617 14.6203 7.60847C14.6384 7.02317 14.3054 6.49042 13.7918 6.28314C13.4661 6.19424 13.2689 5.84156 13.3504 5.49374C13.4277 5.15152 13.7482 4.93873 14.0702 5.01575C14.1109 5.0225 14.15 5.03723 14.1857 5.05921C15.2154 5.45548 15.8821 6.52201 15.8291 7.68813C15.8274 7.86488 15.7585 8.03332 15.6381 8.15484C15.5178 8.27637 15.3563 8.34055 15.1907 8.33269Z"
                                                fill="white"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2378_5148">
                                                <rect
                                                  width="20"
                                                  height="20"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Album" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.AlbumDetails?.userFav === true
                                            ? "Remove from Favourite"
                                            : "Add To Favourite"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            row?.AlbumDetails?.userFav === true
                                              ? "playtime hist-fav"
                                              : "playtime"
                                          }
                                          onClick={() => {
                                            addalbumFavorite(
                                              row?.AlbumDetails?._id
                                            );
                                            setHist(!hist);
                                          }}
                                        >
                                          <svg
                                            key={ind}
                                            width="25"
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2378_5148)">
                                              <path
                                                opacity="0.4"
                                                d="M9.81207 18.1979C7.90954 17.0227 6.14095 15.6371 4.53867 14.0663C3.40752 12.9448 2.54365 11.5754 2.01307 10.0627C1.06504 7.11269 2.16861 3.74123 5.24969 2.74033C6.87595 2.22961 8.64464 2.54313 10.0046 3.5832C11.3651 2.54429 13.1333 2.23087 14.7596 2.74033C17.8407 3.74123 18.9516 7.11269 18.0036 10.0627C17.4773 11.574 16.6186 12.9433 15.4928 14.0663C13.8891 15.6354 12.1207 17.0209 10.2194 18.1979L10.012 18.3333L9.81207 18.1979Z"
                                                fill="#B9C0DE"
                                              />
                                              <path
                                                d="M10.0084 18.3332L9.81267 18.1978C7.90779 17.0228 6.13674 15.6371 4.53186 14.0662C3.39535 12.9463 2.52634 11.5767 1.99145 10.0626C1.05083 7.11261 2.15439 3.74115 5.23548 2.74024C6.86173 2.22952 8.65375 2.54328 10.0084 3.59206V18.3332Z"
                                                fill="#7C8DB5"
                                                fill-opacity="0.72"
                                              />
                                              <path
                                                d="M15.1907 8.33269C15.0233 8.32191 14.8674 8.23825 14.7596 8.1013C14.6517 7.96436 14.6014 7.78617 14.6203 7.60847C14.6384 7.02317 14.3054 6.49042 13.7918 6.28314C13.4661 6.19424 13.2689 5.84156 13.3504 5.49374C13.4277 5.15152 13.7482 4.93873 14.0702 5.01575C14.1109 5.0225 14.15 5.03723 14.1857 5.05921C15.2154 5.45548 15.8821 6.52201 15.8291 7.68813C15.8274 7.86488 15.7585 8.03332 15.6381 8.15484C15.5178 8.27637 15.3563 8.34055 15.1907 8.33269Z"
                                                fill="white"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2378_5148">
                                                <rect
                                                  width="20"
                                                  height="20"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {row?.type === "Playlist" ? (
                                    <>
                                      <Tooltip
                                        title={
                                          row?.PlaylistDetails?.userFav === true
                                            ? "Remove from Favourite"
                                            : "Add To Favourite"
                                        }
                                        placement="top"
                                      >
                                        <div
                                          className={
                                            row?.PlaylistDetails?.userFav ===
                                              true
                                              ? "playtime hist-fav"
                                              : "playtime"
                                          }
                                          onClick={() => {
                                            addplayFavorite(
                                              row?.PlaylistDetails?._id
                                            );
                                            setHist(!hist);
                                          }}
                                        >
                                          <svg
                                            key={ind}
                                            width="25"
                                            height="25"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g clip-path="url(#clip0_2378_5148)">
                                              <path
                                                opacity="0.4"
                                                d="M9.81207 18.1979C7.90954 17.0227 6.14095 15.6371 4.53867 14.0663C3.40752 12.9448 2.54365 11.5754 2.01307 10.0627C1.06504 7.11269 2.16861 3.74123 5.24969 2.74033C6.87595 2.22961 8.64464 2.54313 10.0046 3.5832C11.3651 2.54429 13.1333 2.23087 14.7596 2.74033C17.8407 3.74123 18.9516 7.11269 18.0036 10.0627C17.4773 11.574 16.6186 12.9433 15.4928 14.0663C13.8891 15.6354 12.1207 17.0209 10.2194 18.1979L10.012 18.3333L9.81207 18.1979Z"
                                                fill="#B9C0DE"
                                              />
                                              <path
                                                d="M10.0084 18.3332L9.81267 18.1978C7.90779 17.0228 6.13674 15.6371 4.53186 14.0662C3.39535 12.9463 2.52634 11.5767 1.99145 10.0626C1.05083 7.11261 2.15439 3.74115 5.23548 2.74024C6.86173 2.22952 8.65375 2.54328 10.0084 3.59206V18.3332Z"
                                                fill="#7C8DB5"
                                                fill-opacity="0.72"
                                              />
                                              <path
                                                d="M15.1907 8.33269C15.0233 8.32191 14.8674 8.23825 14.7596 8.1013C14.6517 7.96436 14.6014 7.78617 14.6203 7.60847C14.6384 7.02317 14.3054 6.49042 13.7918 6.28314C13.4661 6.19424 13.2689 5.84156 13.3504 5.49374C13.4277 5.15152 13.7482 4.93873 14.0702 5.01575C14.1109 5.0225 14.15 5.03723 14.1857 5.05921C15.2154 5.45548 15.8821 6.52201 15.8291 7.68813C15.8274 7.86488 15.7585 8.03332 15.6381 8.15484C15.5178 8.27637 15.3563 8.34055 15.1907 8.33269Z"
                                                fill="white"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2378_5148">
                                                <rect
                                                  width="20"
                                                  height="20"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <div>
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                      alignItems: "center",
                                      height: "30px",
                                    }}
                                  >
                                    {row?.type === "Track" ? (
                                      <div className="fd-plps">
                                        {(audioDurations[row?.audio] !==
                                          undefined &&
                                          row?.nft_type === "nor") ||
                                          row?.isMine === true ? (
                                          <>
                                            {(audioplays1[ind] === 0 ||
                                              isplay === false) == true ? (
                                              <Tooltip
                                                title="Play Song"
                                                placement="top"
                                              >
                                                <img
                                                  onClick={() => {
                                                    setAudio(row);
                                                    createMusic(row?._id);
                                                    setAudiosource(row?.audio);
                                                  }}
                                                  src={fdp3}
                                                  alt={fdp3}
                                                />
                                              </Tooltip>
                                            ) : (
                                              <img
                                                src={Companylogo1}
                                                id="pauseImage"
                                                onClick={() => {
                                                  setisplay(false);
                                                }}
                                                alt={Companylogo}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {row?.nft_type === "per" &&
                                              audioDurations[row?.audio] !==
                                              undefined ? (
                                              <Tooltip
                                                title="Premium Song"
                                                placement="top"
                                              >
                                                {(audioplays1[ind] == 0 ||
                                                  isplay == false) == true ? (
                                                  <img
                                                    src={fdp3}
                                                    onClick={() => {
                                                      setAudios(row);
                                                      createMusic(row?._id);
                                                      setAudiosource(
                                                        row?.pre_audio
                                                      );
                                                    }}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    alt="nr"
                                                  />
                                                ) : (
                                                  <img
                                                    src={Companylogo1}
                                                    id="pauseImage"
                                                    onClick={() => {
                                                      setisplay(false);
                                                    }}
                                                    alt={Companylogo}
                                                  />
                                                )}
                                              </Tooltip>
                                            ) : (
                                              <Tooltip
                                                title="Can't Play"
                                                placement="top"
                                              >
                                                <img
                                                  src={fdp3}
                                                  style={{ cursor: "pointer" }}
                                                  alt="nr"
                                                />
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Stack>
                                </div>
                              </div>
                              {row?.type === "Album" ? (
                                <Accordion className="accord-feed-page">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header-feed-album-list"
                                  >
                                    View All in Album
                                  </AccordionSummary>
                                  <div className="table-div-feed">
                                    <TableContainer>
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          {row?.SongDetails.map(
                                            (obj, index) => {
                                              return (
                                                <>
                                                  <TableRow>
                                                    <TableCell>
                                                      <div className="inside-div">
                                                        <div className="more-tracks-inner-div">
                                                          <div className="music-play-main-div">
                                                            {(audioDurations[
                                                              obj?.audio
                                                            ] !== undefined &&
                                                              obj?.nft_type ===
                                                              "nor") ||
                                                              obj?.isMine ===
                                                              true ? (
                                                              <>
                                                                {songNames ===
                                                                  obj?.song_name &&
                                                                  songNames !==
                                                                  null &&
                                                                  isPause ===
                                                                  "false" ? (
                                                                  <img
                                                                    src={
                                                                      Companylogo1
                                                                    }
                                                                    id="pauseImage"
                                                                    onClick={() => {
                                                                      setisplay(
                                                                        false
                                                                      );
                                                                    }}
                                                                    alt={
                                                                      Companylogo
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <Tooltip
                                                                    title="Play Song"
                                                                    placement="top"
                                                                  >
                                                                    <img
                                                                      src={s6}
                                                                      className="music-play"
                                                                      onClick={() => {
                                                                        setAudio(
                                                                          obj
                                                                        );
                                                                        createMusic(
                                                                          obj?._id
                                                                        );
                                                                        setAudiosource(
                                                                          obj?.audio
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Tooltip>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <Tooltip
                                                                title={
                                                                  obj?.nft_type ===
                                                                    "per"
                                                                    ? "Premium Song"
                                                                    : "Can't play"
                                                                }
                                                                placement="top"
                                                              >
                                                                <img
                                                                  src={s6}
                                                                  className="music-play"
                                                                />
                                                              </Tooltip>
                                                            )}
                                                            <span>
                                                              #{index + 1}
                                                            </span>
                                                            {obj?.song_name}-
                                                            {obj?.ISRC}
                                                          </div>
                                                          <div>
                                                            {audioDurations[
                                                              obj?.audio
                                                            ] !== undefined ? (
                                                              <span>
                                                                {
                                                                  audioDurations[
                                                                  obj?.audio
                                                                  ]
                                                                }
                                                              </span>
                                                            ) : (
                                                              <span>-</span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                    <TableCell>
                                                      {row?.type === "Album" ? (
                                                        <MoreHorizIcon
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) => {
                                                            handleClickEl2(e);
                                                            setsongid(obj?._id);
                                                            setRows(obj);
                                                          }}
                                                          className="feed-album-list-info"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                      <Popover
                                                        id={id2}
                                                        open={open2}
                                                        anchorEl={anchorEl2}
                                                        onClose={handleCloseEl2}
                                                        anchorOrigin={{
                                                          vertical: "bottom",
                                                          horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                          vertical: "top",
                                                          horizontal: "right",
                                                        }}
                                                        className="playlist-popover"
                                                      >
                                                        <div
                                                          className="playlist-more-div"
                                                          onClick={() =>
                                                            addPlay(rows._id)
                                                          }
                                                        >
                                                          Add to Playlist
                                                        </div>
                                                        <div
                                                          className="playlist-more-div"
                                                          onClick={() => {
                                                            navigate(
                                                              `/trackpage/${rows._id}`
                                                            );
                                                          }}
                                                        >
                                                          Visit Track Page
                                                        </div>
                                                        <div
                                                          className="playlist-more-div"
                                                          onClick={() => {
                                                            navigate(
                                                              `/artistpage/${rows.user_id}`
                                                            );
                                                          }}
                                                        >
                                                          Visit Artist Page
                                                        </div>
                                                      </Popover>
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </Accordion>
                              ) : null}
                              {row?.type === "Playlist" ? (
                                <Accordion className="accord-feed-page">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header-feed-album-list"
                                  >
                                    View All in Playlist
                                  </AccordionSummary>
                                  <div className="table-div-feed">
                                    <TableContainer>
                                      <Table aria-label="simple table">
                                        <TableBody>
                                          {row?.playlistSongDetails.map(
                                            (obj, index) => {
                                              return (
                                                <>
                                                  <TableRow>
                                                    <TableCell>
                                                      <div className="inside-div">
                                                        <div className="more-tracks-inner-div">
                                                          <div className="music-play-main-div">
                                                            {(audioDurations[
                                                              obj?.audio
                                                            ] !== undefined &&
                                                              obj?.nft_type ===
                                                              "nor") ||
                                                              obj?.isMine ===
                                                              true ? (
                                                              <>
                                                                {songNames ===
                                                                  obj?.song_name &&
                                                                  songNames !==
                                                                  null &&
                                                                  isPause ===
                                                                  "false" ? (
                                                                  <img
                                                                    src={
                                                                      Companylogo1
                                                                    }
                                                                    id="pauseImage"
                                                                    onClick={() => {
                                                                      setisplay(
                                                                        false
                                                                      );
                                                                    }}
                                                                    alt={
                                                                      Companylogo
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <Tooltip
                                                                    title="Play Song"
                                                                    placement="top"
                                                                  >
                                                                    <img
                                                                      src={s6}
                                                                      className="music-play"
                                                                      onClick={() => {
                                                                        setAudio(
                                                                          obj
                                                                        );
                                                                        createMusic(
                                                                          obj?._id
                                                                        );
                                                                        setAudiosource(
                                                                          obj?.audio
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Tooltip>
                                                                )}
                                                              </>
                                                            ) : (
                                                              <Tooltip
                                                                title={
                                                                  obj?.nft_type ===
                                                                    "per"
                                                                    ? "Premium Song"
                                                                    : "Can't play"
                                                                }
                                                                placement="top"
                                                              >
                                                                <img
                                                                  src={s6}
                                                                  className="music-play"
                                                                />
                                                              </Tooltip>
                                                            )}
                                                            <span>
                                                              #{index + 1}
                                                            </span>
                                                            {obj?.song_name}-
                                                            {obj?.ISRC}
                                                          </div>
                                                          <div>
                                                            {audioDurations[
                                                              obj?.audio
                                                            ] !== undefined ? (
                                                              <span>
                                                                {
                                                                  audioDurations[
                                                                  obj?.audio
                                                                  ]
                                                                }
                                                              </span>
                                                            ) : (
                                                              <span>-</span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                    <TableCell>
                                                      {row?.type ===
                                                        "Playlist" ? (
                                                        <MoreHorizIcon
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) => {
                                                            handleClickEl2(e);
                                                            setsongid(obj?._id);
                                                            setRows(obj);
                                                          }}
                                                          className="feed-album-list-info"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                      <Popover
                                                        id={id2}
                                                        open={open2}
                                                        anchorEl={anchorEl2}
                                                        onClose={handleCloseEl2}
                                                        anchorOrigin={{
                                                          vertical: "bottom",
                                                          horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                          vertical: "top",
                                                          horizontal: "right",
                                                        }}
                                                        className="playlist-popover"
                                                      >
                                                        <div
                                                          className="playlist-more-div"
                                                          onClick={(e) => {
                                                            navigate(
                                                              `/trackpage/${rows._id}`
                                                            );
                                                          }}
                                                        >
                                                          Visit Track Page
                                                        </div>
                                                        <div
                                                          className="playlist-more-div"
                                                          onClick={(e) => {
                                                            navigate(
                                                              `/artistpage/${rows.user_id}`
                                                            );
                                                          }}
                                                        >
                                                          Visit Artist Page
                                                        </div>
                                                      </Popover>
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              );
                                            }
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </Accordion>
                              ) : null}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={4}
              sx={{ padding: "15px" }}
            >
              {/* 
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '15px 0px' }}>
                                <div className='enew-g1-main'>
                                    <div className='top-stream-mian'>
                                        <div className='top-stemers'>
                                            Songs to Fit Your Mood
                                        </div>

                                    </div>
                                    <div>
                                        <ImageList sx={{ width: 'auto', height: 'auto' }} cols={2} rowHeight={110} className='img-list'>
                                            {itemData.map((item) => (
                                                <>
                                                    {showMessage === true ? <Stack spacing={1}>




                                                        <Skeleton variant="rectangular" width='100%' height={150} sx={{ bgcolor: 'var(--clr-skel)' }} />
                                                    </Stack>
                                                        :
                                                        <ImageListItem key={item.img} onClick={((e) => { navigate('/premiumtracks', { state: `${item.title}` }) })}>
                                                            <img
                                                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                                alt={item.title}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    }

                                                </>

                                            ))}
                                        </ImageList >
                                    </div>
                                </div>


                            </Grid> */}
            </Grid>
            <SendTrack
              handleModelClose2={handleModelClose2}
              handleOpen2={handleOpen2}
              openModel2={openModel2}
              id={`/trackpage/${send}`}
            />
          </Grid>

          {localStorage.getItem("playsong") === "true" ? (
            <>
              {audiosources.length > 0 ? (
                <Tracks audiosources={audiosources} tracks={tracks} />
              ) : (
                <></>
              )}{" "}
            </>
          ) : (
            <></>
          )}
          {(localStorage.getItem("playsong") === "false" && audiosource) ===
            true ? (
            <NewMusicTrack
              audiosource={audiosource}
              isplay={isplay}
              setisplay={setisplay}
            />
          ) : (
            <>
              {(localStorage.getItem("playsong") === "false" &&
                audio !== null) === true ? (
                <NewMusicTrack
                  audiosource={audio}
                  isplay={isplay}
                  setisplay={setisplay}
                />
              ) : (
                <></>
              )}{" "}
            </>
          )}

          <Modal
            keepMounted
            open={openModel}
            onClose={handleModelClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style2}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "var(--clr-font)",
                }}
              >
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    borderBottom: "1px solid var(--clr-search-border)",
                    display: "flex",
                  }}
                >
                  <Grid xs={1} sm={1} md={1} lg={1} xl={1}>
                    <div>
                      <CloseIcon onClick={handleModelClose} />
                    </div>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                    <div className="edit-playlist-title">
                      {/* <QueueMusicIcon /> */}
                      Add to Playlist
                    </div>
                  </Grid>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div id="search-wrap2">
                    <InputBase
                      id="search-input"
                      sx={{ ml: 1, flex: 1 }}
                      value={playname ? playname : ""}
                      onChange={(e) => setPlayname(e.target.value)}
                      placeholder="Search your music"
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      id="search-input"
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    className="new-playlist"
                    onClick={(e) => {
                      navigate("/createplaylist", { state: { song_id } });
                    }}
                  >
                    <AddCircleIcon />
                    <div>New Playlist</div>
                  </div>
                </Grid>
                {play.length > 0 ? (
                  <>
                    <Grid
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="grid-height"
                    >
                      {play &&
                        play.map((row, ind) => {
                          return (
                            <Grid
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              key={row?._id}
                              onClick={() => {
                                handleAdd(row?._id);
                              }}
                            >
                              <div className="new-playlist created">
                                <div className="d-flex">
                                  {row?.image ? (
                                    <img src={row?.image} alt="bg2" />
                                  ) : (
                                    <img src={Companylogo} alt="bg2" />
                                  )}
                                </div>
                                <div>{row?.playlist}</div>
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </>
                ) : (
                  <>
                    <div className="newno">
                      <img src={nodata} />
                    </div>
                  </>
                )}
              </Grid>
            </Box>
          </Modal>
        </Box>
      </Box>
    </div>
  );
}

export default FeedNew;

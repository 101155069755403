import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import c7 from "../../img/dster-webp/bs3.webp";
import { useSelector } from "react-redux";
import nodata from "../../img/dster-webp/newnodata.gif";
import Tracks from "../Music-traxk/Tracks";
import Track from "../NewMusicTrack/NewMusicTrack";
import InfiniteScroll from "react-infinite-scroll-component";
import i3 from '../../img/dster-webp/i3.webp'
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import "./ExploreArtist.css";
import Axios from "../../Axios";
import HeaderNew from "../HeaderNew/HeaderNew";

import Cards2 from '../../img/dster-webp/card-2.webp';
import Cards1 from '../../img/dster-webp/card-3.webp';
import Cards3 from '../../img/dster-webp/card-4.webp';
import Cards4 from '../../img/dster-webp/card-5.webp';
import Cards5 from '../../img/dster-webp/card-6.webp';
import Cards6 from '../../img/dster-webp/card-7.webp';
import Cards7 from '../../img/dster-webp/card-8.webp';
import Cards8 from '../../img/dster-webp/card-9.webp';
import Cards9 from '../../img/dster-webp/card-10.webp';
import Cards10 from '../../img/dster-webp/card-11.webp';
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",
  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: "transparent !important",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important",
  },

  containWidth: {
    maxWidth: "1440px",
    margin: "auto",
    background: "none !important",
    padding: "0 !important",
    boxShadow: "none !important",

    "@media (max-width: 1199.98px)": {
      padding: "20px !important",
    },
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function ExploreArtist2() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(true);
  const songlist = useSelector((state) => state.songs);
  const [audiosource, setAudiosource] = React.useState();
  const [name, setName] = React.useState();
  const [count, setCount] = React.useState(10);

  const [tracks, setTracks] = useState(0);
  const params = useParams();
  const tokens = localStorage.getItem("iphephile");
  const [showMessage, setShowMessage] = useState(true);
  const [isplay, setisplay] = useState(0);
  const [page, setPage] = useState(1);
  const [audiosources, setAudiosources] = React.useState([]);
  const [audiosources1, setAudiosources1] = React.useState(songlist);
  const listInnerRef = useRef();
  const audio = localStorage.getItem("audiosource");
  const [refreshing, setRefreshing] = useState(false);

  const [Artists, setArtists] = useState([]);

  // to get top getSupporters
  const getSupporters = async () => {
    try {
      var findData = { id: params._id, page: page };

      const datas = await Axios.post("/followers/get_my_supporters", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            // setArtists(res.data?.result)
            setCount(res.data?.result.length);
            if (res?.data?.result?.length > 0) {
              setArtists(res?.data?.result);
              setArtists([...Artists, ...res?.data?.result]);
              setRefreshing(false);
            } else {
              setHasMore(false);
            }
          } else {
            //toast.error(err?.response?.data?.message)
            // setArtists([]);
          }
        })
        .catch((err) => {

        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get top getMutual followers
  const getMutual = async () => {
    try {
      var findData = { id: params._id, page: page };

      const datas = await Axios.post(
        "/followers/get_my_mutualfollowers",
        findData,
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            if (res?.data?.result?.MutualFollowers.length > 0) {
              // setArtists(res?.data?.result?.MutualFollowers);
              setArtists([...Artists, ...res?.data?.result?.MutualFollowers]);
              setRefreshing(false);
            } else {
              setHasMore(false);
            }
          } else {


            // setArtists([]);
          }
        })
        .catch((err) => {

        });
    } catch (error) {
      // console.log(error, "error");
    }
  };

  // to get top SameArtist
  const SameArtist = async () => {
    try {
      var findData = { id: params._id, page: page };

      const datas = await Axios.post(
        "/followers/get_relatedartists",
        findData,
        {
          headers: {
            Authorization: tokens,
          },
        }
      )
        .then((res) => {
          if (res?.data?.success) {
            setArtists(res?.data?.result);
          } else {


            setArtists([]);
          }
        })
        .catch((err) => {

        });
    } catch (error) {
      // console.log(error, "error");
    }
  };
  // to Get the list of followers list for the artist

  const getFollowers = async () => {
    try {
      var findData = {
        id: params._id,
        page: page,
      };

      const datas = await Axios.post("/followers/get_my_followers", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setCount(res.data?.result.TotalFollowers);

            if (res?.data?.result?.followerDetails.length > 0) {
              setArtists(res?.data?.result?.followerDetails);
              setArtists([...Artists, ...res?.data?.result?.followerDetails]);
              setRefreshing(false);
            } else {
              setHasMore(false);
            }
          } else {
            setArtists([]);
          }
        })
        .catch((err) => {
          setArtists([]);


        });
    } catch (error) {
      setArtists([]);

      // console.log(error, "error");
    }
  };

  const getFollowings = async () => {
    try {
      var findData = {
        id: params._id,
        page: page,
      };

      const datas = await Axios.post("/followers/get_my_followings", findData, {
        headers: {
          Authorization: tokens,
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            setCount(res.data?.result.TotalFollowings);

            if (res?.data?.result?.followingDetails.length > 0) {
              setArtists(res?.data?.result?.followingDetails);
              setArtists([...Artists, ...res?.data?.result?.followingDetails]);
              setRefreshing(false);
            } else {
              setHasMore(false);
            }
          } else {
            setArtists([]);
          }
        })
        .catch((err) => {
          setArtists([]);


        });
    } catch (error) {
      setArtists([]);

      // console.log(error, "error");
    }
  };

  // useEffect(() => {
  //   setPage(1)
  //   setArtists([])
  //   if (params?.title === "top_supporters") {
  //     getSupporters();
  //     setName("Top Supporters");
  //   } else if (params?.title === "mutual_connects") {
  //     getMutual();
  //     setName("Mutual Connects");
  //   } else if (params?.title === "related_artists") {
  //     SameArtist();
  //     setName("Related Artists");
  //   } else if (params?.title === "followers_list") {
  //     getFollowers();
  //     setName("Followers");
  //   }
  //   else if (params?.title === "following_details") {
  //     getFollowings();
  //     setName("Following Details");
  //   }
  // }, [page]);

  useEffect(() => {
    setPage(1)
    setArtists([])
    if (params?.title === "top_supporters") {
      getSupporters();
      setName("Top Supporters");
    } else if (params?.title === "mutual_connects") {
      getMutual();
      setName("Mutual Connects");
    } else if (params?.title === "related_artists") {
      SameArtist();
      setName("Related Artists");
    } else if (params?.title === "plugin_list") {
      getFollowers();
      setName("My Plugin List");
    }
    else if (params?.title === "pluggedin_details") {
      getFollowings();
      setName("Plugged-In Details");
    }
  }, [params.title, page]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [showMessage]);

  const handleRefresh = () => {
    setRefreshing(true);
    setPage(1);
  };
  const loadMoreData = () => {
    console.log("page", page);
    setPage(page + 1);
  };

  const [detail, setDetail] = useState([
    { img: `${Cards1}`, name: "ManTra" },
    { img: `${Cards2}`, name: "Opera Odiosa ao Demônio da Putrefação" },
    { img: `${Cards3}`, name: "Blues-corn" },
    { img: `${Cards4}`, name: "Jump" },
    { img: `${Cards5}`, name: "Afro KingKong vs Godzilla" },
    { img: `${Cards6}`, name: "Jump" },
    { img: `${Cards7}`, name: "HOSTAGE Feat. Tshaka" },
    { img: `${Cards8}`, name: "Jump" },
    { img: `${Cards9}`, name: "ManTra" },
    { img: `${Cards10}`, name: "Jump" },
  ]);


  const drawerWidth = 280;

  return (
    <div>
      <Box sx={{ display: "flex" }} className="display-block">
        <HeaderNew title={name} />

        <Box component="main"
          sx={{
            flexGrow: 1,
            py: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            marginTop: "50px",
          }}
          className="explore-artist">
          {/* <Grid spacing={0} className='whole-cnt'>
                        <InfiniteScroll
                            dataLength={count}
                            next={loadMoreData}
                            hasMore={hasMore}
                            pullDownToRefresh
                            pullDownToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>
                                    &#8595; Pull down to refresh &#8595;
                                </h3>
                            }
                            releaseToRefreshContent={
                                <h3 style={{ textAlign: 'center' }}>
                                    &#8593; Release to refresh &#8593;
                                </h3>
                            }
                            refreshFunction={handleRefresh}

                        endMessage={<p>No more items to load</p>}
                        >

                            <Grid container spacing={0} className="backstage-artist-grid expart" id="document" style={{ height: '300px !important' }}
                                ref={listInnerRef}>
                                {showMessage ?


                                    <>
                                        {Artists?.map((row, obj) => {
                                            return (
                                                <Grid xs={12} sm={12} md={4} lg={6} xl={3} sx={{ padding: '10px' }}>
                                                    <Card sx={{ maxWidth: 240, margin: 'auto' }} className="card-backstage">
                                                        <Stack spacing={1}  >


                                                            < Skeleton variant="circular" animation="wave" sx={{ bgcolor: 'var(--clr-skel)' }
                                                            } width={210} height={210} />
                                                            < Skeleton variant="text" animation="wave" sx={{ bgcolor: 'var(--clr-skel)', fontSize: '15px' }
                                                            } width={200} />
                                                            < Skeleton variant="text" animation="wave" sx={{ bgcolor: 'var(--clr-skel)', fontSize: '15px' }
                                                            } width={170} />

                                                        </Stack>
                                                    </Card>
                                                </Grid>)
                                        })}</> : <>
                                        {Artists?.length > 0 ? (<>
                                            {Artists?.map((row, obj) => {
                                                return (
                                                    <>

                                                        <Grid xs={12} sm={12} md={4} lg={6} xl={3} sx={{ padding: '10px' }} key={obj} >
                                                            <Card sx={{ maxWidth: 300, margin: 'auto' }} className="card-backstage expart">
                                                                <div

                                                                    className="cirle-img"

                                                                    title="green iguana"
                                                                    onClick={((e) => { navigate(`/artistpage/${row?._id}`) })}
                                                                >
                                                                    <img src={row?.profilePicture != "" ? row?.profilePicture : c7} alt='img' />
                                                                </div>
                                                                <CardContent >
                                                                    <div className="love-txt1">
                                                                        {row?.name != '' ? row?.name : `${row?.account?.slice(0, 10)}...`} {row?.status == 'per' ? <img src={i3} /> : <></>}
                                                                    </div>
                                                                    <Button
                                                                        className="button"
                                                                        disableElevation={true}
                                                                        variant="contained"
                                                                        sx={{
                                                                            color: "#fff",
                                                                            fontSize: "18",
                                                                            background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",
                                                                            borderRadius: "72.4637680053711px",
                                                                            "&:hover": {
                                                                                background: "linear-gradient(-85.6deg, #175aff, #fc5aff)",

                                                                            },
                                                                            marginTop: '20px',
                                                                            width: 190,
                                                                            height: 37,
                                                                        }} onClick={() => { navigate(`/artistpage/${row?._id}`) }}>
                                                                        View
                                                                    </Button>


                                                                    <div className="explore-more-btn2 artists"  onClick={((e) => { navigate(`/message/${row?._id}`) })}>
                                                            <Button>Direct Message</Button>
                                                        </div>


                                                                </CardContent>
                                                            </Card>
                                                        </Grid>

                                                    </>
                                                )
                                            })}
                                        </>) : (<>
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                                                <div className='newno'>
                                                    <img src={nodata} alt='no-data' className='no-data' />
                                                </div>

                                            </Grid>
                                        </>)}
                                    </>}


                            </Grid>
                        </InfiniteScroll>
                        {localStorage.getItem("playsong") === "true" ? <>{audiosources1.length > 0 ? <Tracks audiosources={audiosources1} tracks={tracks} /> : <></>} </> : <></>}
                        {(localStorage.getItem("playsong") === "false" && audiosource) === true ? <Track audiosource={audiosource} isplay={isplay} setisplay={setisplay} /> : <>{(localStorage.getItem("playsong") === "false" && audio !== null) === true ? <Track audiosource={audio} isplay={isplay} setisplay={setisplay} /> : <></>} </>}
                    </Grid> */}
          <InfiniteScroll
            dataLength={count}
            next={loadMoreData}
            hasMore={hasMore}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh &#8595;
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh &#8593;
              </h3>
            }
            refreshFunction={handleRefresh}

          >
            <Grid
              container
              className="whole-cnt"
              style={{ marginTop: "100px", justifyContent: "center" }}
            >
              {Artists?.length > 0 ? <>
                {Artists && Artists.map((row, ind) => {
                  return (
                    <Grid item xs={11} sm={11} md={7} lg={7} xl={7}>
                      <div className="top-support-main">
                        {/* <img /> */}
                        <Avatar alt="Remy Sharp" src={row?.profilePicture && !row?.image?.includes("localhost") ? row?.profilePicture : c7} />
                        <div className="top-suport-content">
                          <div className="musicconthead">
                            <span>
                              {row?.name != '' ? row?.name : `${row?.account?.slice(0, 10)}...`} {row?.status == 'per' ? <img src={i3} /> : <></>}

                            </span>
                            <div className="date-top">
                              <Chip
                                label={row?.role ? row?.role.toUpperCase() : ""}
                                color={row?.status == "nor" ? "primary" : "secondary"}

                              />
                            </div>
                          </div>

                          <Button variant="contained" onClick={() => { navigate(`/artistpage/${row?._id}`) }}>View</Button>
                        </div>
                      </div>
                    </Grid>
                  );
                })} </> :
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
                  <div className='newno top'>
                    <img src={nodata} alt='no-data' className='no-data' />
                  </div>

                </Grid>
              }

            </Grid>
          </InfiniteScroll>
          {localStorage.getItem("playsong") === "true" ? <>{audiosources1.length > 0 ? <Tracks audiosources={audiosources1} tracks={tracks} /> : <></>} </> : <></>}
          {(localStorage.getItem("playsong") === "false" && audiosource) === true ? <Track audiosource={audiosource} isplay={isplay} setisplay={setisplay} /> : <>{(localStorage.getItem("playsong") === "false" && audio !== null) === true ? <Track audiosource={audio} isplay={isplay} setisplay={setisplay} /> : <></>} </>}
        </Box>
      </Box>
    </div>
  );
}

export default ExploreArtist2;

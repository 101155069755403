import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import '../Creater/Creater.css';
import Banner from '../../img/dster-webp/backstage-fanzone.webp';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";



const drawerWidth = 280;


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});



const BackStageFanZone = () => {

    const navigate = useNavigate()

    const classes = useStyles();

    return (
        <div className='creator'>
            <Box sx={{ display: 'flex' }}>
                {/* <Header2 /> */}

                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)`, overflowY: 'scroll', height: '100vh' } }}
                    className="creator-box">
                    <Grid spacing={0} className='whole-cnt'>
                        {/* <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Item className={classes.containWidth}>
                                <Header />
                            </Item>
                        </Grid> */}
                        <Grid>
                            <div class="page-cnt-center">

                                <div class="card_cus-file">
                                    <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <h2 class="text-white">
                                            {window.localStorage.getItem("role") == "artist" ? "Artist Zone" : "Fan Zone"}
                                        </h2>
                                        <p class="txt-sucess">
                                            Dreamster is a platform for both artists and listeners.
                                        </p>
                                    </div>
                                    <div class="card border-0">
                                        <div class="upload-det">
                                            <div class="upload-card-box">
                                                {
                                                    window.localStorage.getItem("role") == "artist" &&
                                                    <div class="music-up border-end">
                                                        <div className='library-button'>
                                                            <Button

                                                                onClick={(() => { navigate('/hoisting') })}
                                                                class="btn btn-outline-success mb-3">
                                                                <div className='upload-cloud start-music'>
                                                                    HOSTING
                                                                </div>

                                                            </Button>
                                                        </div>

                                                        <h5>Why Upload My Music?</h5>
                                                        <p class="text-wrap-file">
                                                            Once yov've uploaded your music, other can
                                                            discover your tracks immediately!
                                                        </p>
                                                    </div>

                                                }

                                                <div>
                                                    <div className='library-button'>
                                                        {window.localStorage.getItem("role") == "artist" ?
                                                            <Button onClick={() => { navigate("/pollshistory") }} class="btn btn-outline-success mb-3">

                                                                <div className='upload-cloud start-music'>
                                                                    Polls and Survey History
                                                                </div>
                                                            </Button>

                                                            :
                                                            <Button

                                                                onClick={(() => { navigate('/polls_survey') })}
                                                                className="btn btn-outline-success mb-3">
                                                                <div className='upload-cloud start-music'>
                                                                    Participate
                                                                </div>

                                                            </Button>}
                                                    </div>
                                                    <div className='music-up border-end'>
                                                        <h5> Nothing to Upload Yet?</h5>
                                                        <p class="text-wrap-file">
                                                            Support artists you like by listening and
                                                            sharing their tracks with your friends.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="music-banner mb-3">
                                    <img src={Banner} alt={Banner} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}

export default BackStageFanZone

import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import '../Upload-Music/UploadMusic.css'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import '../Hoisting/Hoisting.css'
import TextField from '@mui/material/TextField';
import { useState, useEffect, useRef } from "react";
import '../CreateVirtualMeet/CreateVirtualMeet.css'
import HeaderNew from "../HeaderNew/HeaderNew";
import Axios from "../../Axios";
import { ToastContainer, toast } from 'react-toastify';


const drawerWidth = 280;






function MessageSet() {
    const theme = useTheme();
    const [amount, setamount] = useState('')
    const [err, setErr] = useState(null)
    const [update, setupdate] = useState('')
    const tokens = localStorage.getItem("iphephile");


    const inputRef = useRef(null)

    const [title, setTitle] = useState('Message Setting')
    const [islogin, setIslogin] = useState(false)



    useEffect(() => {
        getProfile()


    }, [])

    const handlenumber = (e) => {
        const regex = /^[0-9]*$/
        setamount(e.target.value)
        console.log(regex.test(e.target.value), "tost")
        if (!(regex.test(e.target.value))) {
            setErr("*Enter Number Only")
        }
        else {
            setErr("")
        }

    }

    const [loader, setLoader] = useState(null)


    const getProfile = async () => {
        try {

            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setupdate(true)

                        setamount(res.data?.result?.amount)
                    } else {
                        toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        } catch (error) {
            // console.log(error);
        }
    };
    const setAccoutDetails = async () => {
        try {
            const regex = /^[0-9]*$/

            if (!amount) {
                setErr("*Amount is Required")
            }
            else if (!(regex.test(amount))) {
                setErr("*Enter Number Only")
            }

            else {

                var updateData = {

                    amount: Number(amount),

                }
                console.log(updateData)
                setIslogin(true)

                await Axios.post("/profile/update_account", updateData,
                    {
                        headers: {
                            Authorization: tokens,
                        },
                    }).then((res) => {
                        // console.log(res,"res")
                        if (res?.data?.success) {

                            toast.success(res?.data?.message)
                            getProfile()
                            setIslogin(false)


                        }
                        else {
                            toast.error(res?.data?.message)
                            setIslogin(false)
                        }
                    }).catch((err) => {

                        console.log(err)

                        setIslogin(false)
                    })

            }
        } catch (error) {
            console.log(error)
            setIslogin(false)
        }
    }



    return (

        <div className="Hoisting">
            {/* <SnackBar /> */}
            <Box sx={{ display: 'flex' }} className='display-block'>

                <HeaderNew title={title} />
                <ToastContainer
                    toastStyle={{ backgroundColor: "white" }}
                    autoClose={3000}
                    position="top-right"
                    reverseOrder={false}
                />
                <Box component="main"
                    className="Hoisting-box"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>


                    <Box
                        component="main"
                        sx={{ flexGrow: 1, p: 0 }}
                        className="UploadMusic-box3"
                    >
                        <Container maxWidth="xl" >
                            <Grid container spacing={2} sx={{ justifyContent: 'center' }} >
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={2} sx={{ justifyContent: 'center' }} >
                                        <Grid xs={12} sm={12} md={7} lg={5} xl={4} className="messageset-grid" >
                                            <div className="hoidting-form msgset">
                                                <div className="message-set-input-mian">
                                                    <h5>Token Name:</h5>

                                                    <TextField

                                                        id="outlined-error-helper-text"
                                                        value="Dster"
                                                        placeholder="Your title here"
                                                        className="messageset-input"
                                                        sx={{ width: 'auto !important' }}
                                                    />


                                                </div>
                                                <div className="message-set-input-mian">
                                                    <h5>Amount </h5>

                                                    <TextField

                                                        id="outlined-error-helper-text"

                                                        value={amount}
                                                        // helperText="Incorrect entry."
                                                        placeholder="Amount"
                                                        onChange={((e) => { handlenumber(e) })}
                                                        className="messageset-input"
                                                        sx={{ width: 'auto !important' }}
                                                    />

                                                </div>

                                                <div className="errordiv" style={{ "marginTop": "10px" }}>{err}</div>




                                                <div className="create-room">
                                                    {islogin === false ? <Button onClick={() => { setAccoutDetails() }}>
                                                        Submit
                                                    </Button> : <Button>
                                                        Processing ...
                                                    </Button>}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Container>

                    </Box>
                </Box>

            </Box>
        </div>
    );
}

export default MessageSet;
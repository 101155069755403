import React, { useState } from 'react'
import './BckPassNew.css'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
import bckban1 from '../../img/dster-webp/bckban1.webp';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import bk1 from '../../img/dster-webp/bk1.webp'
import bk2 from '../../img/dster-webp/bk2.webp'
import bk3 from '../../img/dster-webp/bk3.webp'
import bk4 from '../../img/dster-webp/bk4.webp'
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Footer from '../../Components/Footer/Footer'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 700,
    overflowY: 'auto',
    '@media(max-width:991.98px)': {
        width: '80%',
        height: '500px'
    },
    bgcolor: 'var(--clr-explore-bg)',
    border: '2px solid #000',
    borderRadius: '8px',
    // boxShadow: 24,
    p: 2,
};




function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function BckPassNew() {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate()

    const [cardImg] = useState([
        { img: `${bk1}`, sub: '$22 /month' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk4}`, sub: 'free' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk1}`, sub: 'free' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk3}`, sub: '$22 /month' },
        { img: `${bk4}`, sub: '$22 /month' },
        { img: `${bk2}`, sub: '$22 /month' },
        { img: `${bk4}`, sub: '$22 /month' },
        { img: `${bk2}`, sub: 'free' },
        { img: `${bk1}`, sub: 'free' },
        { img: `${bk3}`, sub: 'free' },
        { img: `${bk2}`, sub: '$22 /month' },
    ])

    const [inputChange, setInputChange] = useState('')

    const handleInputChange = (e) => {
        setInputChange(e.target.value.length)
        console.log(setInputChange, '/200');
    }


    const [free, setFree] = useState(false)
    console.log(free, 'free');

    const [open, setOpen] = React.useState(false);

    const handleOpen = (val) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);



    return (
        <div className='bckpassnew'>
            <Header />
            <Grid container sx={{ justifyContent: 'center', marginTop: "80px", }} >
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={10}
                    xl={10}>
                    <div className='discommui'>
                        Discover communities
                    </div>
                    <div style={{ width: '50%', margin: '25px auto' }}>
                        <div class="group">
                            <svg viewBox="0 0 24 24" aria-hidden="true" class="search-icon">
                                <g>
                                    <path
                                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                                    ></path>
                                </g>
                            </svg>

                            <input
                                id="query"
                                class="input2"
                                type="search"
                                placeholder="search music, artist, genre..."
                                name="searchbar"
                            />
                        </div>

                    </div>
                    <div className='bckban1'>
                        <img src={bckban1} alt='bckban' />
                    </div>
                    <div className='createorn'>
                        <Button onClick={handleOpen}>
                            create your own
                        </Button>
                    </div>
                    <Box sx={{ width: '100%', marginTop: "50px" }}>
                        <Box>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='bcknewTab' variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile>
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="💰 Business" {...a11yProps(1)} />
                                <Tab label="🍎 Health & fitness" {...a11yProps(2)} />
                                <Tab label="📚 Personal development" {...a11yProps(3)} />
                                <Tab label="🎨 Arts & crafts" {...a11yProps(4)} />
                                <Tab label="🎸 Music" {...a11yProps(5)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>

                            <div className='display-4' style={{ justifyContent: "space-between" }}>
                                {cardImg.map((obj, i) => {
                                    return (
                                        <>
                                            <div className="layerdiv">

                                                <Card sx={{ maxWidth: 300 }} className='bck-n-card cursor' key={i} onClick={() => {
                                                    navigate('/bckinner1', { state: obj });
                                                }}>
                                                    <CardMedia
                                                        sx={{ height: 140 }}
                                                        image={obj.img}
                                                        title="green iguana"
                                                    />
                                                    <CardContent>
                                                        <div className='display-1 mini'>
                                                            <img src={obj.img} alt={obj.img} />
                                                            <div className='mini-text'>
                                                                {' Dreamster'.slice(0, 18) + '...'}
                                                            </div>
                                                        </div>
                                                        <Typography variant="body2" className='mini-text2'>
                                                            {'Lizards are a widespread group of squamate reptiles, with over 6,000species, ranging across all continents except Antarctica'.slice(0, 100) + ' more...'}
                                                        </Typography>
                                                        <Stack
                                                            direction="row"
                                                            // divider={<Divider orientation="vertical" flexItem />}
                                                            spacing={1}
                                                        >
                                                            <div className='mini-text3'>
                                                                Private •
                                                            </div>
                                                            <div className='mini-text3'>
                                                                6.5k Members •
                                                            </div>
                                                            <div className='mini-text3'>
                                                                {obj.sub}
                                                            </div>
                                                        </Stack>
                                                    </CardContent>

                                                </Card>
                                            </div>

                                        </>
                                    )
                                })}

                            </div>
                            <Pagination count={10} className='bckpage' />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            Item Three
                        </CustomTabPanel>
                    </Box>
                    <Modal
                        open={open}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>

                            <div className="rund-cls text-right">
                                <CancelOutlinedIcon onClick={handleClose} />
                            </div>

                            <div className='answer-banner'>

                                <div className='realmen-h'>
                                    Create your community
                                </div>
                                <div className='realmen-d'>
                                    Free for 14 days, then $99/month. Cancel anytime.All features. Unlimited everything. No hidden fees.
                                </div>
                                <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', alignItems: 'center', padding: '15px' }}>
                                    <div className='realmen-d'>
                                        You were referred by
                                    </div>
                                    <img src={bk1} alt='adf' />
                                    <div className='realmen-d'>
                                        Nicholas Agardy
                                    </div>
                                </Stack>
                            </div>
                            <div className='ask1'>
                                Group Name
                            </div>

                            <div className='ask-inp'>
                                <TextField id="outlined-basic" variant="outlined" placeholder='example' onChange={handleInputChange} />
                            </div>

                            <div className='ask1'>
                                Payment information
                            </div>

                            <div className='ask-inp display-1'>
                                <TextField id="outlined-basic" placeholder='Card number' variant="outlined" />
                                <TextField id="outlined-basic" placeholder='MM/YY' className='mmcv' variant="outlined" />    <TextField id="outlined-basic" placeholder='CVV' className='mmcv' variant="outlined" />
                            </div>
                            <div className='answer-banner'>

                                <div className='ask1 text-center'>
                                    Your first charge will be on August 6th, 2024 for $99. Cancel anytime with 1-click. By clicking below, you accept our terms.
                                </div>
                            </div>
                            <div className='joinask'>
                                <Button onClick={(() => { navigate('/bckpasscreate') })}>
                                    START
                                </Button>
                            </div>

                        </Box>
                    </Modal>


                </Grid>
            </Grid>
            <Footer />
        </div>
    )
}

export default BckPassNew

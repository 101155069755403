import React from 'react';
import ReactApexChart from 'react-apexcharts';

// Function to generate data for the heatmap
const generateData = (count, yrange) => {
    const series = [];
    for (let i = 0; i < count; i++) {
        const x = `w${i + 1}`;
        const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({ x, y });
    }
    return series;
};

// Sample data for the heatmap
const data = [
    {
        name: 'Metric1',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric2',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric3',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric1',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric2',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric3',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric1',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric2',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric3',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric1',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric2',
        data: generateData(8, { min: 0, max: 90 })
    },
    {
        name: 'Metric3',
        data: generateData(8, { min: 0, max: 90 })
    },

];

class HeatMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: data,
            options: {
                chart: {
                    height: 300,
                    type: 'heatmap'
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#FC5AFF', '#FC5AFF', '#FC5AFF', '#898989', '#175AFF', '#000'],
                xaxis: {
                    type: 'category',
                    categories: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '01:00', '01:30']
                },
                grid: {
                    padding: {
                        right: 20
                    }
                }
            }
        };
    }

    render() {
        return (
            <div>
                <div id="chart">
                    <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type="heatmap"
                        height={300}
                    />
                </div>
                <div id="html-dist"></div>
            </div>
        );
    }
}

export default HeatMap;

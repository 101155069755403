import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import UndoIcon from '@mui/icons-material/Undo';
import nrplay from '../../img/dster-webp/nr-play.webp';
import Grid from '@mui/material/Grid';
import './PremiumTracks.css'
import ava1 from "../../img/dster-webp/ava1.webp";
import { useNavigate } from 'react-router-dom';
import Axios from "../../Axios";
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Album from "../../img/dster-webp/ava1.webp";
import { Link } from "react-router-dom";
import Companylogo from '../../img/dster-webp/company-logo.webp';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Companylogo1 from '../../img/dster-webp/musicwave.gif';
import Album2 from '../../img/dster-webp/music-logo4.webp';
import Album4 from '../../img/dster-webp/music-logo6.webp';
import Track from '../NewMusicTrack/NewMusicTrack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import VerifiedIcon from '@mui/icons-material/Verified';
import HeaderNew from '../HeaderNew/HeaderNew';
import nodata from '../../img/dster-webp/newnodata.gif'

const drawerWidth = 280;

function CustomTabPanel(props) {



    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};




const tracks = [
    { image: `${Album4}`, heading: 'Crushed Candy - Going Down', para: 'Trapical Music', time: '3.02', icontext1: 'Dreamster Reposted', icontext2: 'Favourite' },
    { image: `${Album2}`, heading: 'Crushed Candy - Going Down', para: 'Trapical Music', time: '3.02', icontext1: 'Dreamster Reposted', icontext2: 'Favourite' },

]

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'var(--clr-bcg)',
    height: '620px',
    // overflow: 'scroll',
    // border: '2px solid #000',
    // boxShadow: 'none',
    p: 4,
    '@media all and (max-width: 1199.98px)': {
        width: '55%'
    },
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    }
};


function PremiumTrack() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [rows, setRows] = React.useState(0);
    const [isplay, setisplay] = useState(0)
    const [values, setValues] = React.useState(true);

    const [playname, setPlayname] = React.useState();
    const [play, setPlay] = useState()
    const [song_id, setsongid] = React.useState()
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => setOpenModel(false);
    const [audioplays, setAudioplays] = useState([]);

    const [music, setMusic] = React.useState([]);
    const [audiosource, setAudiosource] = React.useState();
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const [name, setName] = React.useState();

    const [audioDurations, setAudioDurations] = useState([]);
    const [audioDurations1, setAudioDurations1] = useState([]);

    const location = useLocation()

    const title = (location.state)
    const isMount = useRef(false)

    useEffect(() => {

        if (isMount.current == false) {
            getMusic()
            getplayLists()
            isMount.current = true
        }

    }, [name])

    // useEffect(() => {
    //     getMusic()
    // }, [name])

    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    const getPreAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener("loadedmetadata", () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations1((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations1((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    const getaudioPlays = async (audioSrc, index) => {
        try {
            if (audioSrc === localStorage.getItem("songName")) {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 1,
                }));
            }
            else {
                setAudioplays((prev) => ({
                    ...prev,
                    [index]: 0,
                }));
            }

        } catch (error) {
            console.error(error);
            setAudioplays((prev) => ({
                ...prev,
                [index]: 0
            }));
        }
    };

    useEffect(() => {
        if (music.length > 0) {
            music.forEach((row) => {
                getAudioDuration(row?.audio);
                getPreAudioDuration(row?.pre_audio)
            });

        }
        if (music.length > 0) {
            music.forEach((row, index) => {
                getaudioPlays(row?.audio, index);
            });

        }
    }, [music]);

    useEffect(() => {
        if (music.length > 0) {
            music.forEach((row, index) => {
                getaudioPlays(row?.song_name, index);
            });
        }
    }, [values]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    const getMusic = async () => {
        try {

            const find_data = {
                explore: title,
                name: name
            }
            const datas = await Axios.post("/users/get_user_favourite", find_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setMusic(res?.data?.result)
                        setCount(res?.data?.result.length)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                    }
                }).catch((err) => {
                    // console.log(err, "err")

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }
    const createMusic = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)


                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }
    const audio = localStorage.getItem("audiosource")
    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio)
        localStorage.setItem("songName", data.song_name)
        localStorage.setItem("Image", data.image)
        localStorage.setItem("current", 0)
        localStorage.setItem("isPause", false)
        setAudiosource(data.audio)
        localStorage.setItem("tracks", 0)
        localStorage.setItem("playsong", "false")
        setValues(!values)
        setisplay(true)
    }
    const setAudios = (data) => {
        if (audioDurations1[data?.pre_audio] !== undefined) {
            localStorage.setItem("audiosource", data.pre_audio)
            localStorage.setItem("songName", data.song_name)
            localStorage.setItem("Image", data.image)
            localStorage.setItem("current", 0)
            localStorage.setItem("tracks", 0)
            localStorage.setItem("isPause", false)
            setAudiosource(data.pre_audio)
            localStorage.setItem("playsong", "false")
            setValues(!values)
            setisplay(true)
        }
    }

    const addLibrary = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const addalbumLibrary = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const addplayLibrary = async (data) => {
        try {

            const create_data = {
                play_id: data
            }


            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const addFavorite = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_to_favourite", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }
    const addalbumFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_fav_album", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }
    const addplayFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_fav_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    useEffect(() => {
        getplayLists()
    }, [playname])
    const getplayLists = async () => {
        try {


            const datas = await Axios.post("/users/get_my_playlist", {
                playname: playname
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {

                        setPlay(res?.data?.result)


                    }
                    else {

                        setPlay([])
                    }
                }).catch((err) => {
                    //console.log(err,"err")

                })

        } catch (error) {
            //console.log(error,"error");
        }

    }
    const handleAdd = async (data) => {

        try {

            const create_data = {
                playlistId: data,
                songId: song_id
            }


            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        handleModelClose()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }


    }
    const addPlay = (data) => {
        // setsongid(data)
        handleOpen()
        handleClose()
    }


    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const editSong = async (data) => {
        try {

            const create_data = {
                songId: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_song_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const editAlbums = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_album_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        getMusic()
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const editPlaylists = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_playlist_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            // console.log(error, "error");
        }

    }

    const navigation = (row) => {
        console.log(row.type)
        if (row.type === "Album") {
            navigate(`/albumpage/${row?.AlbumDetails[0]?._id}`);
        } else if (row.type === "Track") {
            navigate(`/trackpage/${row?._id}`);
        } else if (row.type === "Playlist") {
            navigate(`/showplaylist/${row?.PlaylistDetails[0]?._id}`);
        }
    };

    const [titles, setTitles] = useState(title)

    return (<>
        <div className='feed'>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                <HeaderNew name={name} setName={setName} title={titles} />

                <Box component="main"
                    className="feed-bg"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px' }}>
                    <div className='feed-head'>
                        <div className='feeds'>
                            <div className="back-svg-div">
                                {/* <ArrowBackIcon className="back-svg" onClick={(() => { navigate(-1) })} /> */}
                            </div>
                            {/* <h2>{title}</h2> */}
                        </div>
                        {/* <div className='feed'>
                            <h2>{title}</h2>
                        </div> */}
                        <div className='feed-tab'>



                        </div>
                    </div>

                    <Container maxWidth="md">
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='tab-cnt' style={(audiosource) ? { paddingBottom: "150px" } : {}}>
                                    <CustomTabPanel value={value} index={0}>

                                        <Grid container spacing={2}>
                                            {music.length > 0 ? <>{music && music.map((row, index) => {
                                                return <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <div className='feed-music-box' key={row?._id}>
                                                            <div className='music-detail'>
                                                                <div className='album-img'>
                                                                    {row?.song_name ?
                                                                        <img src={row?.image && !row?.image?.includes('localhost') ? row?.image : ava1} alt={"Track"} /> :

                                                                        <img src={row?.image && !row?.image?.includes('localhost') ? row?.image : ava1} alt={"Album/PlayList"} />}


                                                                </div>

                                                                <div className='album-detail'>
                                                                    <div className='music-time'>
                                                                        <div className='time-music'>
                                                                            <div className='music-name'>
                                                                                {row?.song_name ? <>
                                                                                    {row?.nft_type === "per" ?
                                                                                        <Tooltip title={row?.nft_type == "per" && audioDurations1[row?.pre_audio] == undefined ? "Can't Play" : ""} placement="top">
                                                                                            <div onMouseOver={() => setAudios(row)}>
                                                                                                <img src={nrplay} alt="play"
                                                                                                    onMouseOver={() => setAudios(row)}
                                                                                                // onMouseOut={() => setisplay(false)}
                                                                                                />
                                                                                            </div>
                                                                                        </Tooltip> : <></>}</> : <></>}

                                                                                {row?.song_name ? <div onClick={() => { navigation(row) }}>{row?.song_name}-{row?.ISRC} {row?.nft_type === "per" ? <><VerifiedIcon className="premium" /></> : <></>}</div> : <>{row?.album_name ?
                                                                                    <div onClick={((e) => { navigate(`/albumpage/${row?._id}`, { state: { row } }) })}>{row?.album_name}</div> : <div onClick={((e) => { navigate(`/showplaylist/${row._id}`, { state: { row } }) })}>{row?.playlist}</div>}</>}
                                                                            </div>
                                                                            <div className='music-name'>

                                                                                Created By {row?.createUser?.name ? row?.createUser?.name : ''}
                                                                            </div>
                                                                            <div className='link-badge'>
                                                                                {row?.nft_link ? <><Link to=''>{row?.nft_link}</Link>
                                                                                </> : <></>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>{audioDurations[row?.audio]}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='loop-times'>
                                                                        <div className='loop-part'>
                                                                            <div className='company-logo'>

                                                                                {row?.song_name ? <>
                                                                                    {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ?
                                                                                        <> {audioplays[index] == 0 || isplay === false ? <Tooltip title="Play Song" placement="top">
                                                                                            <img src={nrplay} onClick={() => { setAudio(row); setValues(!values); createMusic(row?._id); setAudiosource(row?.audio) }} style={{ "cursor": "pointer" }} alt='nr' />
                                                                                        </Tooltip> : <img src={Companylogo1} id="pauseImage" onClick={() => setisplay(false)} alt="Companylogo" />
                                                                                        }</> :
                                                                                        <>{row?.nft_type === "per" && audioDurations[row?.audio] !== undefined ?

                                                                                            <Tooltip title="Premium Song" placement="top">
                                                                                                {audioplays[index] == 0 || isplay == false ?
                                                                                                    < img src={nrplay} onClick={() => { setAudios(row); createMusic(row?._id); setAudiosource(row?.pre_audio) }} style={{ "cursor": "pointer" }} alt='nr' />
                                                                                                    : <img src={Companylogo1} id="pauseImage" onClick={() => { setisplay(false) }} alt={Companylogo1} />
                                                                                                }

                                                                                            </Tooltip>
                                                                                            : <Tooltip title="Can't Play" placement="top">
                                                                                                <img src={nrplay} style={{ "cursor": "pointer" }} alt='nr' />

                                                                                            </Tooltip>}

                                                                                        </>
                                                                                    }
                                                                                </> : <img src={Companylogo} alt={Companylogo} />}

                                                                            </div>
                                                                            <div className='repost-loop'>

                                                                                <p>   {row?.reposts?.length} Repost</p>
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <FavoriteIcon className="unfav" />{row?.fav_count ? row?.fav_count : 0} Favorite</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>{row?.players?.length ? row?.players?.length : 0} Plays</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='like-dot'>
                                                                        <div className='share-btn'>
                                                                            {row?.song_name ? <>
                                                                                {row?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>
                                                                                }
                                                                            </> : <>
                                                                                {row?.album_name ? <>

                                                                                    {row?.isRepost === true ?
                                                                                        <Tooltip title="Unrepost" placement="top">
                                                                                            <RepeatIcon className='repeat' onClick={() => { editAlbums({ id: row?._id, status: "repost" }) }} />
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip title="Repost" placement="top">
                                                                                            <RepeatIcon onClick={() => { editAlbums({ id: row?._id, status: "repost" }) }} />
                                                                                        </Tooltip>
                                                                                    }
                                                                                </> : <>

                                                                                    {row?.isRepost === true ?
                                                                                        <Tooltip title="Unrepost" placement="top">
                                                                                            <RepeatIcon className='repeat' onClick={() => { editPlaylists({ id: row?._id, status: "repost" }) }} />
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip title="Repost" placement="top">
                                                                                            <RepeatIcon onClick={() => { editPlaylists({ id: row?._id, status: "repost" }) }} />
                                                                                        </Tooltip>
                                                                                    }
                                                                                </>}

                                                                            </>}
                                                                            {row?.song_name ? <>
                                                                                {row?.userFav === true ?
                                                                                    <Tooltip title="Unfavourite" placement="top">
                                                                                        <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    :

                                                                                    <Tooltip title="Favourite" placement="top">
                                                                                        <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />
                                                                                    </Tooltip>
                                                                                }
                                                                            </> : <>{row?.album_name ? <>
                                                                                {row?.userFav === true ?

                                                                                    <Tooltip title="Unfavourite" placement="top">
                                                                                        <FavoriteIcon className="unfav" onClick={() => { addalbumFavorite(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    :

                                                                                    <Tooltip title="Favourite" placement="top">
                                                                                        <FavoriteIcon onClick={() => { addalbumFavorite(row?._id) }} />
                                                                                    </Tooltip>

                                                                                }
                                                                            </> :
                                                                                <>
                                                                                    {row?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addplayFavorite(row?._id) }} />
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addplayFavorite(row?._id) }} />
                                                                                        </Tooltip>
                                                                                    }
                                                                                </>}

                                                                            </>}


                                                                            {/* <ShortcutIcon /> */}
                                                                            {row?.song_name ? <>
                                                                                {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine ?
                                                                                    <Tooltip title="Add to Library" placement="top">
                                                                                        <DownloadIcon onClick={() => { addLibrary(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    : <></>}
                                                                            </> : <>{row?.album_name ? <>
                                                                                {row?.userFound === false && row?.isMine ?
                                                                                    <Tooltip title="Add to Library" placement="top">
                                                                                        <DownloadIcon onClick={() => { addalbumLibrary(row?._id) }} />
                                                                                    </Tooltip>

                                                                                    : <></>}
                                                                            </> : <>
                                                                                {row?.userFound === false && row?.isMine ?
                                                                                    <Tooltip title="Add to Library" placement="top">
                                                                                        <DownloadIcon onClick={() => { addplayLibrary(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    : <></>}
                                                                            </>}</>}

                                                                            {row?.song_name ? <>
                                                                                {row?.userFound === true ?
                                                                                    <Tooltip title="Remove from Library" placement="top">
                                                                                        <UndoIcon onClick={() => { addLibrary(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    : <></>}
                                                                            </> : <>{row?.album_name ? <>
                                                                                {row?.userFound === true ?
                                                                                    <Tooltip title="Remove from Library" placement="top">
                                                                                        <UndoIcon onClick={() => { addalbumLibrary(row?._id) }} />
                                                                                    </Tooltip>

                                                                                    : <></>}
                                                                            </> : <>
                                                                                {row?.userFound === true ?
                                                                                    <Tooltip title="Remove from Library" placement="top">
                                                                                        <UndoIcon onClick={() => { addplayLibrary(row?._id) }} />
                                                                                    </Tooltip>
                                                                                    : <></>}
                                                                            </>}</>}


                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            {row?.song_name ? <MoreHorizIcon onClick={(e) => { handleClick(e); setRows(row) }} /> : <></>}
                                                                            <Popover
                                                                                id={id}
                                                                                open={open}
                                                                                anchorEl={anchorEl}
                                                                                onClose={handleClose}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'center',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                                className='playlist-popover'
                                                                            >
                                                                                <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlist</div>

                                                                                <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`) })}>Visit Track Page</div>
                                                                                <div className='playlist-more-div' >Visit Artist Page</div>
                                                                            </Popover>

                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>
                                                    </Grid>
                                                </>
                                            })}</> :
                                                <>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="description-History">

                                                        </div>
                                                        <div className="description-History">

                                                        </div>

                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="start-listen-btn2">
                                                            <div className='newno'>
                                                                <img src={nodata} />
                                                            </div>
                                                        </div>
                                                    </Grid></>
                                            }
                                            {/* {tracks.map((row, obj) => {
                                                return (<>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className='feed-music-box'>
                                                            <div className='music-detail'>
                                                                <div className='album-img'>
                                                                    <img src={row.image} alt={Album} />
                                                                </div>

                                                                <div className='album-detail'>
                                                                    <div className='music-time'>
                                                                        <div className='time-music'>
                                                                            <div className='music-name'>
                                                                                <Link to=''>{row.heading}</Link>
                                                                            </div>
                                                                            <div className='link-badge'>
                                                                                <Link to=''>{row.para}</Link>
                                                                                <img src={Mbadge} alt={Mbadge} />
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>{row.time}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='loop-times'>
                                                                        <div className='loop-part'>
                                                                            <div className='company-logo'>
                                                                                <img src={Companylogo} alt={Companylogo} />
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <RepeatIcon />{row.icontext1}</p>
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <FavoriteIcon />{row.icontext2}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>200 Plays</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='like-dot'>
                                                                        <div className='share-btn'>
                                                                            <RepeatIcon />
                                                                            <FavoriteIcon />
                                                                            <ShortcutIcon />
                                                                            <DownloadIcon />
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <MoreHorizIcon onClick={handleClick} />
                                                                            <Popover
                                                                                id={id}
                                                                                open={open}
                                                                                anchorEl={anchorEl}
                                                                                onClose={handleClose}
                                                                                anchorOrigin={{
                                                                                    vertical: 'center',
                                                                                    horizontal: 'center',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right',
                                                                                }}
                                                                                className='playlist-popover'
                                                                            >
                                                                                <div className='playlist-more-div'>Visit Track Page</div>
                                                                                <div className='playlist-more-div' >Visit Artist Page</div>
                                                                            </Popover>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>
                                                    </Grid>
                                                </>)
                                            })} */}

                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1}>

                                        <Grid container spacing={2}>

                                            {tracks.map((row, obj) => {
                                                return (<>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className='feed-music-box'>
                                                            <div className='music-detail'>
                                                                <div className='album-img'>
                                                                    <img src={row.image} alt={Album} />
                                                                </div>

                                                                <div className='album-detail'>
                                                                    <div className='music-time'>
                                                                        <div className='time-music'>
                                                                            <div className='music-name'>
                                                                                <Link to=''>{row.heading}</Link>
                                                                            </div>
                                                                            <div className='link-badge'>
                                                                                <Link to=''>{row.para}</Link>
                                                                                {/* <img src={Mbadge} alt={Mbadge} /> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>{row.time}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='loop-times'>
                                                                        <div className='loop-part'>
                                                                            <div className='company-logo'>
                                                                                <img src={Companylogo} alt={Companylogo} />
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <RepeatIcon />{row.icontext1}</p>
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <FavoriteIcon />{row.icontext2}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>200 Plays</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='like-dot'>
                                                                        <div className='share-btn'>
                                                                            <RepeatIcon />
                                                                            <FavoriteIcon />
                                                                            <ShortcutIcon />
                                                                            <DownloadIcon />
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <MoreHorizIcon onClick={handleClick} />
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>



                                                    </Grid>
                                                </>)
                                            })}

                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2}>

                                        <Grid container spacing={2}>

                                            {tracks.map((row, obj) => {
                                                return (<>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className='feed-music-box'>
                                                            <div className='music-detail'>
                                                                <div className='album-img'>
                                                                    <img src={row.image} alt={Album} />
                                                                </div>

                                                                <div className='album-detail'>
                                                                    <div className='music-time'>
                                                                        <div className='time-music'>
                                                                            <div className='music-name'>
                                                                                <Link to=''>{row.heading}</Link>
                                                                            </div>
                                                                            <div className='link-badge'>
                                                                                <Link to=''>{row.para}</Link>
                                                                                {/* <img src={Mbadge} alt={Mbadge} /> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>{row.time}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='loop-times'>
                                                                        <div className='loop-part'>
                                                                            <div className='company-logo'>
                                                                                <img src={Companylogo} alt={Companylogo} />
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <RepeatIcon />{row.icontext1}</p>
                                                                            </div>
                                                                            <div className='repost-loop'>
                                                                                <p> <FavoriteIcon />{row.icontext2}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <p>200 Plays</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='like-dot'>
                                                                        <div className='share-btn'>
                                                                            <RepeatIcon />
                                                                            <FavoriteIcon />
                                                                            <ShortcutIcon />
                                                                            <DownloadIcon />
                                                                        </div>
                                                                        <div className='music-timing'>
                                                                            <MoreHorizIcon />
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>
                                                    </Grid>
                                                </>)
                                            })}

                                        </Grid>
                                    </CustomTabPanel>


                                </div>
                            </Grid>
                        </Grid>

                    </Container>

                    {audiosource ? <Track audiosource={audiosource} isplay={isplay} setisplay={setisplay} /> : <>{audio !== null ? <Track audiosource={audio} isplay={isplay} setisplay={setisplay} /> : <></>} </>}

                    {/* {audiosource ? <Track audiosource={audiosource}/> : <></>}   */}

                </Box>
                <Modal
                    keepMounted
                    open={openModel}
                    onClose={handleModelClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style2}>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
                                <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                    <div>
                                        <CloseIcon onClick={handleModelClose} />
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                                    <div className='edit-playlist-title'>
                                        {/* <QueueMusicIcon /> */}
                                        Add to Playlist
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div id="search-wrap2">

                                    <InputBase
                                        id='search-input'
                                        sx={{ ml: 1, flex: 1 }}
                                        value={playname ? playname : ''}
                                        onChange={(e) => setPlayname(e.target.value)}
                                        placeholder="Search your music"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='new-playlist' onClick={((e) => { navigate('/createplaylist', { state: { song_id } }) })}>
                                    <AddCircleIcon />
                                    <div>
                                        New Playlist
                                    </div>
                                </div>
                            </Grid>
                            {play?.length > 0 ? (<>

                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >
                                    {play && play.map((row, ind) => {
                                        return (
                                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} onClick={() => { handleAdd(row?._id) }}>
                                                <div className='new-playlist created'>
                                                    <div className='d-flex'>
                                                        {row?.image ? <img src={row?.image} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}
                                                        {/* <img src={row?.image} alt='img' /> */}
                                                    </div>
                                                    <div>
                                                        {row?.playlist}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </>) : (<>
                                <div className='newno'>
                                    <img src={nodata} />
                                </div>

                            </>)}



                        </Grid>

                    </Box>
                </Modal>

            </Box>
        </div >
    </>);
}

export default PremiumTrack;
import React, { useEffect, useRef } from "react";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Web3 from 'web3';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import { Avatar } from "@mui/material";
import ava from "../../img/dster-webp/ava1.webp";
import ava1 from "../../img/dster-webp/ava1.webp";
import Axios from "../../Axios";
import axios from "axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import s2 from "../../img/dster-webp/backword.webp"
import s3 from "../../img/dster-webp/pass-play.webp"
import s4 from "../../img/dster-webp/farward.webp"
import s6 from "../../img/dster-webp/phause.webp"
import Slider from '@mui/material/Slider';
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import SwipeableViews from 'react-swipeable-views';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HeaderNew from "../HeaderNew/HeaderNew";
import Erc721Abi from '../../Web3//Abi/erc721.json'
import consts from "../../Constant";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const drawerWidth = 280;

const closeModal = () => {
    $("#collectionModal").hide();
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const mood = [
    'Peaceful',
    'Romantic',
    'Sentimental',
    'Tender',
    'Easygoing',
    'Yearning',
    'Sophisticated',
    'Sensual',
    'Cool',
    'Gritty',
    'Meancholy',
    'Serious',
    'Brooding',
    'Fiery',
    'Defiant',
    'Aggressive',
    'Rowdy',
    'Exicted',
    'Energizing',
    'Empowering',
    'Stirring',
    'Upbeat',
    'Other'


]
const northamerica = [
    'Jazz',
    'Blues',
    'Country',
    'Hip-Hop',
    'Rock n Roll',
    'R&B (Rhythm and Blues)',
    'Gospel',
    'Bluegrass',
    'Folk',
    'Electronic',
    'Indie',
    'Reggae ',
    'Metal',
    'Reggae ',
    'Soul',

];

const latinamerica = [
    'Salsa',
    'Reggaeton',
    'Bossa Nova',
    'Tango',
    'Mariachi',

];

const Days = [
    'Days',
    'Weeks',
    'Months',
    'Years',
];

const Currency = [
    'MATIC',
];


const europe = [
    'Classical',
    'Techno',
    'Bossa Nova',
    'EDM (Electronic Dance Music)',
    'Flamenco',
    'Balkan Folk',
]

const africa = [
    'Afrobeat',
    'Highlife',
    'Kwaito',
    'Mbalax',
    'Taarab',
]

const asia = [
    'Bollywood Music',
    'K-Pop',
    'J-Pop',
    'Qawwali',
    'Peking Opera',
]

const middleeast = [
    'Arabic Pop',
    'Dabke',
    'Turkish Folk Music',
    'Raï',
    'Fado',
]

const oceania = [
    'Aboriginal Folk Music',
    'Pasifika',
    'Contemporary Australian Rock',
    'Maori Music',
    'Hawaiian Music',
]

function getStyles(name, personName, theme) {
    // console.log(personName)
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


function MintNftNew() {

    const [value, setValue] = React.useState(0);
    const [price, setprice] = React.useState(false);
    const [qty, setqty] = React.useState(1);
    const [albumimage, setAlbumimage] = useState(null);
    const [albumimageerr, setAlbumimageerr] = useState(null);
    const [count, setCount] = React.useState();
    const [unitPrice, setunitprice] = React.useState();
    const location = useLocation()
    const [qtyerr, setqtyerr] = React.useState(null);
    const [amounts, setAmounts] = useState()
    const [erc20Add, setErc20Add] = useState()
    const [royalAddress, setRoyalAddress] = useState()
    const [imageFile, setImageFile] = useState()


    const addReceipt = async () => {
        const web3 = new Web3(new Web3.providers.HttpProvider("https://goerli.infura.io/v3/f72796e58ab7430a8d93311393b5124d"));

        web3.eth.accounts.wallet.add("0x3c26a6d4d6005cff25fcf84d08aab900cb243b4238e7e7b42ac98954c3bd5dc0");

        const SIGNER_ADDRESS = web3.eth.accounts.privateKeyToAccount("0x3c26a6d4d6005cff25fcf84d08aab900cb243b4238e7e7b42ac98954c3bd5dc0");
        var message = web3.utils.soliditySha3(
            { type: 'address', value: "0xCEd6866729f374bCF7121735d8D8668E1D64a559" },
            { type: 'address', value: "0xeb8FC0B2c54e20748ba4411FeD0f470aA4263435" },
            { type: 'string', value: 'data' },
            { type: 'uint256', value: 1 });

        var data = web3.eth.accounts.sign(message, '0x3c26a6d4d6005cff25fcf84d08aab900cb243b4238e7e7b42ac98954c3bd5dc0')
        //console.log(data, web3.utils.hexToNumber(data.v));

        const recoveredSigner = web3.eth.accounts.recover(message, data.signature);
        //console.log(recoveredSigner,recoveredSigner == SIGNER_ADDRESS.address);
        const count = await web3.eth.getTransactionCount(SIGNER_ADDRESS.address)

        const countNumber = Number(count)
        //setCount(countNumber)
        // console.log(typeof (countNumber), "count")
        const ownerSign = {
            r: data.r,
            v: web3.utils.hexToNumber(data.v),
            s: data.s,
            nonce: countNumber
        }
        return ownerSign
    }


    const getSignatureParameters = (signature) => {
        const web3 = new Web3(window.ethereum)
        const r = signature.slice(0, 66);
        const s = `0x${signature.slice(66, 130)}`;
        let v = `0x${signature.slice(130, 132)}`;
        v = web3.utils.toDecimal(v);

        if (![27, 28].includes(v)) v += 27;

        return {
            r,
            s,
            v
        };
    };

    const signin = async () => {
        try {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum)

                const account = await window.ethereum.request({
                    method: "eth_accounts",
                });
                var SellerMessage = web3.utils.soliditySha3(
                    { type: 'address', value: "0x17579149b3126B55f7568363Eeb6b7551eE078C7" }, // NFT Address
                    { type: 'uint256', value: String(350) }, //NFT ID
                    { type: 'address', value: "0x8d008B313C1d6C7fE2982F62d32Da7507cF43551" }, // ERC20 Address
                    { type: 'uint256', value: String(Number(1) * (10 ** 18)) }, // Amount
                    { type: 'uint256', value: String(356) }// nounce of signer)

                )
                console.log(SellerMessage, "seller message")
                var signs = await web3.eth.personal.sign(SellerMessage, account[0])
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleUploadTrack = (event, newValue) => {
        setprice(false)
        setqtyerr(null)
        setqty()
        setValue(newValue);
        setpreviousPlaylist(false)
        setPutonsale(false)
        setPutonsaleerr(null)
        setRequiredField(false)
        setAudioType1()
        setAudioType()
        setTimeauction(false)
        setTimeauctionerr(null)
        setPreviousAlbum(false)
        setUnlock(false)
        setUnlockerr(null)
        setExistid()
        setNamerr()
        setName('')
        setnfttype()
        setClaimDate();
        setClaimLimit(0);
        setClaimDateerr(null);
        setClaimLimiterr(null);
        setpreview()
        setAudio()
        setpreviewerr()
        setAudioerr()
        setCollectionData([]);
        setPreviewImage("");
        setsong();
        setmusictype("track");
        setAlbum();
        setPlay();
        setCategory();
        setISRC("");
        setNFTLink("");
        setDescription()
        setImageerr(null)
        setAlbumimageerr(null)
        setsongerr(null);
        setCategoryerr(null);
        setroyalty()
        setSkiperr(null)
        setMode('');
        setsaleerr(null);
        settoken();
        settokenerr(null);
        setsale();
        setcurr();
        setamount();
        settimecurr();
        //        setunlock(false);
        setcollection();
        setmining();
        setUdescription()
        setUdescriptionerr(null)
        setcollectionerr(null);
        setminingerr(null);
        setContractAddress("")
        setContractAddressErr("")




        if (newValue === 0) {
            setmusictype("track")
        } else if (newValue === 1) {
            setmusictype("album")
        } else if (newValue === 2) {
            setmusictype("playlist")
        }
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const navigate = useNavigate()
    const classes = useStyles();

    const [classNamebox, setclassNamebox] = useState(1);
    const [NFTOwnerShip, setNFTOwnership] = useState(0);
    const [OpenEveryone, setOpenEveryone] = useState(0);
    const [ClaimDate, setClaimDate] = useState();
    const [ClaimLimit, setClaimLimit] = useState(0);
    const [ClaimDateerr, setClaimDateerr] = useState(null);
    const [ClaimLimiterr, setClaimLimiterr] = useState(null);
    const [preview, setpreview] = useState()
    const [audio, setAudio] = useState()
    const [previewerr, setpreviewerr] = useState()
    const [audioerr, setAudioerr] = useState()
    const [collectionData, setCollectionData] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [song, setsong] = useState();
    const [music_type, setmusictype] = useState("track");
    const [album_name, setAlbum] = useState();
    const [playlist, setPlay] = useState();
    const [category, setCategory] = useState();
    const [ISRC, setISRC] = useState("");
    const [NFTLink, setNFTLink] = useState("");
    const [description, setDescription] = useState()
    const [imageerr, setImageerr] = useState(null)
    const [songerr, setsongerr] = useState(null);
    const [categoryerr, setCategoryerr] = useState(null);
    const [mode, setMode] = useState('');
    const [token, settoken] = useState();
    const [tokenerr, settokenerr] = useState();
    const [sale, setsale] = useState();
    const [curr, setcurr] = useState();
    const [amount, setamount] = useState();
    const [timecurr, settimecurr] = useState();
    // const [unlock, setunlock] = useState(false);
    const [collection, setcollection] = useState();
    const [mining, setmining] = useState();
    const [udescription, setUdescription] = useState()
    const [udescriptionerr, setUdescriptionerr] = useState(null)
    const [collectionerr, setcollectionerr] = useState(null);
    const [miningerr, setminingerr] = useState(null);
    const [contractAddress, setContractAddress] = useState("")
    const [contractAddressErr, setContractAddressErr] = useState("")

    const [loading, setLoading] = useState(false)
    const [existid, setExistid] = useState();
    const [saleerr, setsaleerr] = useState();
    const [currerr, setcurrerr] = useState();
    const [amounterr, setamounterr] = useState();
    const [timecurrerr, settimecurrerr] = useState();
    const [modeerr, setModeerr] = useState(null);
    const [other, setOther] = useState(null);
    const [othererr, setothererr] = useState(null);
    const [ISRCerr, setISRCerr] = useState(null);
    const [NFTLinkerr, setNFTLinkerr] = useState(null);
    const [descriptionerr, setDescriptionerr] = useState(null)
    const [requirefield, setRequiredField] = useState(false)
    const [putonsale, setPutonsale] = useState(false)
    const [timeauction, setTimeauction] = useState(false)
    const [nfttype, setnfttype] = useState()
    const [putonsaleerr, setPutonsaleerr] = useState(null)
    const [timeauctionerr, setTimeauctionerr] = useState(false)
    const [unlock, setUnlock] = useState(false)
    const [unlockerr, setUnlockerr] = useState(null)
    const [skip, setSkip] = useState(true)
    const [royalty, setroyalty] = useState()
    const [skiperr, setSkiperr] = useState(null)
    const [delivery, setDelivery] = useState(false)
    const [albumlist, setAlbumList] = useState([])
    const [playslist, setPlaysList] = useState([])
    const handleRequirefield = () => {
        setRequiredField(!requirefield)
    }
    const handleUnlock = () => {
        setUnlock(!unlock)
    }
    const handleSkip = () => {
        setSkip(!skip)
    }
    const handleDelivery = () => {
        setDelivery(!delivery)
    }

    const handleTimeauction = () => {
        setTimeauction(!timeauction)
    }
    const handlePutonsale = () => {
        setPutonsale(!putonsale)
    }

    const [age, setAge] = React.useState('');

    const handleChange2 = (event) => {
        setAge(event.target.value);
    };

    var create_data
    const [SelectedCategory, setSelectedCategory] = useState("");


    const OpentoEveryOnes = () => {
        setOpento(!opento)
    };

    useEffect(() => {
        if (location?.state) {
            setValue(location?.state?.value)
        }
        else {
            setValue(0)
        }
        getMyList()
    }, [])

    const getMyList = async () => {
        try {

            await Axios.post("/users/get_my_list", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {

                        setAlbumList(res?.data?.result?.musicAlbum)
                        setPlaysList(res?.data?.result?.musicPlay)

                    }
                    else {
                        setAlbumList([])
                        setPlaysList([])

                    }
                }).catch((err) => {
                    // console.log(err, "err")


                })

        } catch (error) {
            // console.log(error, "error");
        }

    }
    const createMusics = async () => {
        try {
            addReceipt()

            var signs
            const web3 = new Web3(window.ethereum)
            await window.ethereum.enable();


            await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const webAccount = await web3.eth.getAccounts()
            signs = await web3.eth.personal.sign("Hello world", webAccount[0], "")
            console.log(signs)

            // const ownerSign = await addReceipt()
            // const sign = {
            //     r: getSignatureParameters(signs).r,
            //     v: getSignatureParameters(signs).v,
            //     s: getSignatureParameters(signs).s,
            //     nonce: Number(await web3.eth.getTransactionCount(webAccount[0]))
            // }


            //  const contractInstance = new web3.eth.Contract(
            //      ContractABI,
            //      contractAddress
            //    );


            // var transactionHash = {}
            // var others = {}


            // const contract_data = {
            //     seller: accounts,
            //     buyer: "0x52307495A627c14DF5590556b0F4cFd994334b14",
            //     erc20Address: "0x0000000000000000000000000000000000001010",
            //     nftAddress: "0x0a8e43aBdF89d6028978Ce37514dF378F5EaabE7",
            //     nftType: 0,
            //     unitPrice: 1 * web3.utils.toWei('0.00000000000001', 'ether'),
            //     skipRoyalty: skip,
            //     amount: 2 * web3.utils.toWei('0.00000000000001', 'ether'),
            //     tokenId: 1,
            //     tokenURI: `ipfs://bafybeif6wari4msbzy5n4wwgmkzxyl7smki4wdcbpvzxylaaq2qpyeceye/1`,
            //     supply: 1,
            //     royaltyFee: royalty ? royalty : 0,
            //     qty: qty,
            //     sign: sign,
            //     ownerSign: ownerSign
            // }
            //  const estimateGasfee = await contractInstance.methods.mintAndBuyAsset(contract_data,sign,ownerSign).estimateGas({from: accounts})
            //  const gasprice = await web3.eth.getGasPrice()
            //  const Gasamount = Number(estimateGasfee)*Number(gasprice)
            //  console.log(estimateGasfee,"estimateGasfee")
            //  const transactionGetID = await contractInstance.methods
            //  .mintAndBuyAsset(contract_data,sign,ownerSign)
            //  .send({
            //      from: accounts,
            //     //  chainId: "0x89",
            //     //  gasPrice:gasprice,
            //       gas: Gasamount,
            //    });
            //  console.log(transactionGetID)

        } catch (error) {
            console.log(error, "error");
        }
    }

    function convertBigIntToString(obj) {
        if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
            return obj;
        }

        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'bigint') {
                    obj[key] = obj[key].toString();
                }
                else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    obj[key] = convertBigIntToString(obj[key]);
                }
            }
        }

        return obj;
    }


    const createMusic = async () => {
        try {
            // addReceipt()

            var signs
            const web3 = new Web3(window.ethereum)



            //                     await window.ethereum.enable();
            //                 

            //                 await window.ethereum.request({
            //                   method: "eth_requestAccounts",
            //               });
            //         const webAccount = await web3.eth.getAccounts()
            //         console.log(web3.utils.toWei('0.0000000000001','ether'),"contracts");
            //                      signs = await web3.eth.personal.sign("Hello world",webAccount[0], "")


            //                     const ownerSign = await addReceipt()
            // const sign = {
            //     r:getSignatureParameters(signs).r,
            //     v:getSignatureParameters(signs).v,
            //     s:getSignatureParameters(signs).s,
            //     nonce:Number(await web3.eth.getTransactionCount(webAccount[0]))
            // }


            // const contractInstance = new web3.eth.Contract(
            //     ContractABI,
            //     contractAddress
            //   );


            var transactionHash = {}
            var others = {}

            if (!song) {
                setsongerr("Please Enter song Title")
            }
            else if (!image) {
                setImageerr("Please Select Image File")
            }
            else if (!preview) {
                setpreviewerr("Please Select preview Audio File")
            }
            else if (!audio) {
                setAudioerr("Please Select Audio File")
            }
            else if (!ISRC) {
                setISRCerr("Please Enter IRSC")
            }
            else if (!NFTLink) {
                setNFTLinkerr("Please Enter NFT Link")
            }
            else if (!category) {
                setCategoryerr("Please Enter Category")
            }
            else if (!mode) {
                setModeerr("Please Enter Song Mood")
            }
            else if (mode === "Other" && !other) {
                setothererr("Please Enter Song Mood")
            }
            else if (!description) {
                setDescriptionerr("Please Enter Description")
            }
            else if (music_type === "album" && previousAlbum === false && !album_name) {

                setNamerr(" Album Details is Required")


            }
            else if (music_type === "playlist" && previousPlaylist === false && !playlist) {

                setNamerr(" Playlist Details is Required")


            }
            else if (!ClaimDate) {
                setClaimDateerr("Please Enter Claim Date")

            }
            else if (!ClaimLimit) {
                setClaimLimiterr("Please Enter Claim Limit")
            }
            else if (ClaimLimit <= 0) {
                setClaimLimiterr("Please Enter Claim Limit more than 0")
            }
            else if (!contractAddress) {
                setContractAddressErr("Please Enter Contract Address")
            }
            else if (putonsale === false) {
                setPutonsaleerr("These Are Required Fields")
            }
            else if (putonsale === true && !sale) {
                setPutonsaleerr("Sale Amount is Required")

            }
            else if (putonsale === true && (sale <= 0)) {
                setPutonsaleerr("Enter Sale Amount more than 0")

            }
            else if (putonsale === true && !curr) {
                setPutonsaleerr("Currency Type is Required")

            }
            else if (skip === false && !royalty) {

                setSkiperr("Royalty Fee is Required")

            }
            else if (skip === false && royalty < 0) {

                setSkiperr("Enter Royalty Fee More than 0")

            }
            // else if (timeauction === false) {
            //     setPutonsaleerr("These Are Required Fields")
            // }

            else if (timeauction === true && !amount) {
                setTimeauctionerr("Please Enter Bid Time")
            }
            else if (timeauction === true && !timecurr) {
                setTimeauctionerr("Please Select Time")
            }
            else if (unlock === true && !udescription) {

                setUnlockerr("Unlock Description is Required")
                //setUdescriptionerr("Unlock Description is Required")

            }
            else if (!collection) {
                setcollectionerr("Please Choose Collection Type")
            }
            else if (!mining) {
                setminingerr("Please Choose Mining Type")
            }

            else {
                setLoading(true)
                create_data = {
                    song_name: song,
                    ISRC: ISRC,
                    nft_link: NFTLink,
                    categorey: category,
                    description: description,
                    image: image,
                    nft_type: 'per',
                    pre_audio: preview,
                    audio: audio,
                    music_type: music_type,
                    album_name: album_name,
                    albumimage: albumimage ? albumimage : image,
                    playlist: playlist,
                    nftclaim: ClaimLimit,
                    opentoeveryone: opento,
                    song_moods: mode === "Other" ? other : mode,
                    existid: existid,
                    sale_amount: sale,
                    sale_curr: curr,
                    time_amount: amount,
                    time_curr: timecurr,
                    purchace_unlock: unlock,
                    unlock_description: udescription,
                    delivery: delivery,
                    collection_type: collection,
                    mining_type: mining,
                    others: {},
                    transactionHash: {},
                    contractAddress: contractAddress
                }

                const createData = {
                    name: song,
                    address: contractAddress,
                    description: description,
                    put_on_sale: putonsale == true ? 1 : 0,
                    instant_sale_price: sale,
                    unlock_on_purchase: unlock == true ? 1 : 0,
                    collection_type: nfttype,
                    position: 1,
                    category: category,
                    transaction_hash: null,
                    no_of_copies: ClaimLimit,
                    image_hash: image,
                    metadata_hash: null,
                    creator_id: 1,
                    nft_contract_id: contractAddress,
                    owner_id: 1,
                    config: null,
                    data: null,
                    royalty: royalty ? royalty : 0,
                    is_active: 1,
                    // token: token,
                    state: 2,
                    sign_instant_sale_price: null,
                    unlock_description: udescription,
                    owned_tokens: 1,
                    erc20_token_id: 5,
                    instant_sale_enabled: 0,
                    min_bid_price: null,
                    min_bid_erc20_token_id: 5,
                    timed_auction_enabled: 0,
                    minimum_bid: sale,
                    bid_time: null,
                    bid_time_opt: null,
                    start_time: null,
                    end_time: null,
                    network_id: 3,
                    delivery_on_purchase: delivery == true ? 1 : 0,
                    delivery_details: null,
                    published: 1,
                    imported: 0,
                    delivery_email: null,
                }

                //                 const contract_data = {
                //                     seller:accounts,
                //                         buyer:"0x52307495A627c14DF5590556b0F4cFd994334b14",
                //                     erc20Address:"0x0000000000000000000000000000000000001010",
                //                         nftAddress:"0x0a8e43aBdF89d6028978Ce37514dF378F5EaabE7",
                //                     nftType:nfttype,
                //                         unitPrice:1*web3.utils.toWei('0.00000000000001','ether'),
                //                     skipRoyalty:skip,
                //                         amount:2*web3.utils.toWei('0.00000000000001','ether'),
                //                     tokenId:1,
                //                         tokenURI:`ipfs://bafybeif6wari4msbzy5n4wwgmkzxyl7smki4wdcbpvzxylaaq2qpyeceye/1`,
                //                     supply:1,
                //                         royaltyFee:royalty ? royalty : 0,
                //                     qty:qty,
                //                     //     sign:sign,
                //                     // ownerSign:ownerSign 
                //                 }
                // console.log(contract_data)
                // const estimateGasfee = await contractInstance.methods.mintAndBuyAsset(contract_data,sign,ownerSign).estimateGas({from: accounts})
                // const gasprice = await web3.eth.getGasPrice()
                // const Gasamount = Number(estimateGasfee)*Number(gasprice)
                // console.log(estimateGasfee,"estimateGasfee")
                // const transactionGetID = await contractInstance.methods
                // .mintAndBuyAsset(contract_data,sign,ownerSign)
                // .send({
                //     from: accounts,
                //     chainId: "0x89",
                //     gasPrice:gasprice,
                //      gas: Gasamount,
                //   });
                var nftmintContract = consts.nftmintContract

                var transfer_proxy = consts?.transfer_proxy

                const formData1 = new FormData();
                formData1.append("file", imageFile);

                const resFile = await axios({
                    method: "post",
                    url: consts?.Pinata_baseURL,
                    data: formData1,
                    headers: {
                        'pinata_api_key': `${consts?.Pinata_ApiKey}`,
                        'pinata_secret_api_key': `${consts?.Pinata_Secret}`,
                        "Content-Type": "multipart/form-data"
                    },
                });
                const ImgHash = `${consts?.Pinata_url}${resFile.data.IpfsHash}`;
                // console.log("🚀  handleImageChange  ImgHash:", ImgHash)
                create_data.ipfsImage = ImgHash
                createData.image_hash = resFile.data.IpfsHash
                const resObj = await axios({
                    method: "post",
                    url: consts?.Pinata_baseURL_json,
                    data: {
                        name: song,
                        description: description,
                        image: ImgHash
                    },
                    headers: {
                        'pinata_api_key': `${consts?.Pinata_ApiKey}`,
                        'pinata_secret_api_key': `${consts?.Pinata_Secret}`,
                        "Content-Type": "application/json"
                    },
                });
                const OBjHash = `${consts?.Pinata_url}${resObj.data.IpfsHash}`;
                create_data.ipfsURI = OBjHash
                createData.metadata_hash = resObj.data.IpfsHash
                // console.log("🚀  createMusic  OBjHash:", OBjHash)

                //mint ContractIntegration
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts()
                // console.log(accounts, "accounts")
                var randomNumber = Math.floor(1000 + Math.random() * 9000);
                // console.log(randomNumber, "randomNumber")

                // var nounce_signer = await web3.eth.getTransactionCount(consts?.nft_contract_owner_address); // signer Address
                // console.log(nounce_signer, "nounce_signer")
                var message = web3.utils.soliditySha3(
                    { type: 'address', value: nftmintContract }, // contract Address
                    { type: 'address', value: accounts[0] }, // user Address
                    { type: 'string', value: OBjHash }, // tokenURI
                    { type: 'uint256', value: randomNumber }); // nounce of signer

                // console.log(message, "message", typeof (consts?.nft_contract_owner_private));
                var data = web3.eth.accounts.sign(message, `0x${consts.nft_contract_owner_private}`) // signer privatekey

                const ContractInstance = new web3.eth.Contract(Erc721Abi, nftmintContract)
                // console.log(ContractInstance, Erc721Abi, nftmintContract)
                //( tokenURI, [royalty], [reciever (Erc721Abiuser)], [message v,r,s,nounce])
                // console.log([data.v, data.r, data.s, randomNumber], "rejahfiusdhfiusd")
                // console.log(resObj.data.IpfsHash, [royalty], [accounts[0]], [data.v, data.r, data.s, randomNumber], "rejahfiusdhfiusd")
                const methos = await ContractInstance.methods.mint(OBjHash, [royalty], [accounts[0]], [data.v, data.r, data.s, randomNumber.toString()]).send({
                    from: accounts[0]
                })
                // console.log("🚀  methos  methos:", methos)
                create_data.Mint_deatils = convertBigIntToString(methos);
                create_data.erc20Token = erc20Add
                create_data.TokenID = methos?.events?.Transfer?.returnValues?.tokenId.toString()

                // createData.erc20_token_id = token
                createData.token = erc20Add
                createData.min_bid_erc20_token_id = methos?.events?.Transfer?.returnValues?.tokenId.toString()

                // console.log(methos?.events?.Transfer?.returnValues?.tokenId, "asjdhfiusahfiuasfd")

                // approve nftid to transfer proxy address
                var Approve = await ContractInstance.methods.approve(transfer_proxy, methos?.events?.Transfer?.returnValues?.tokenId.toString()).send({
                    from: accounts[0]
                })

                if (Approve) {
                    create_data.SenderApprove = Approve

                    // SELLER SIGN   

                    var nonce = await web3.eth.getTransactionCount(accounts[0]);
                    // console.log("🚀 ~ checkTrade ~ nonce:", nonce)

                    // console.log(nftmintContract, Number(methos?.events?.Transfer?.returnValues?.tokenId), erc20Add, Number(sale) * (10 ** 18), nonce)
                    var SellerMessage = web3.utils.soliditySha3(
                        { type: 'address', value: nftmintContract }, // NFT Address
                        { type: 'uint256', value: String(methos?.events?.Transfer?.returnValues?.tokenId) }, //NFT ID
                        { type: 'address', value: erc20Add }, // ERC20 Address
                        { type: 'uint256', value: String(Number(sale) * (10 ** 18)) }, // Amount
                        { type: 'uint256', value: String(nonce) }// nounce of signer)
                        // new Buffer(nftmintContract, 1, "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238", 5, nonce)
                    )



                    // console.log("🚀 ~ sellerMessage ~ data:", SellerMessage)
                    // var SellerSign = await web3.eth.accounts.sign(SellerMessage, 'b82e2d77efb92996a0042373d924ecd2c161550c672c823bbbc910782c883faf')
                    var SellerSign = await web3.eth.personal.sign(SellerMessage, accounts[0], '')

                    // console.log("🚀 ~ Checks ~ data:", SellerSign)
                    // console.log(accounts[0], "accounts")

                    var r = SellerSign.slice(0, 66)
                    var s = `0x${SellerSign.slice(66, 130)}`
                    var v = `0x${SellerSign.slice(130, 132)}`

                    // var r = SellerSign.r
                    // var s = SellerSign.s
                    // var v = SellerSign.v

                    // console.log(r, s, v, "resf")
                    create_data.seller_signMessage = SellerMessage
                    create_data.Seller_Sign = {
                        r: r,
                        s: s,
                        v: v,
                        nounce: Number(nonce)
                    }
                    create_data.nft_contractAdd = nftmintContract
                    createData.contractid = nftmintContract
                    createData.owner = accounts[0]
                    createData.signmessage = SellerMessage
                    createData.sellersign = {
                        r: r,
                        s: s,
                        v: v,
                        nounce: Number(nonce)
                    }
                    // console.log(create_data, createData, "resf")
                    await Axios.post("/users/uploadmusic", create_data, {
                        headers: {
                            Authorization: tokens,
                        },
                    })
                        .then(async (res) => {

                            if (res?.data?.success) {
                                await Axios.post("/collection/create_collection", createData, {
                                    headers: {
                                        Authorization: tokens,
                                    },
                                })

                                toast.success('Music NFT Uploaded Successfully', {
                                    duration: 1000, // Set the duration for which the toast is visible
                                })
                                setTimeout(() => {
                                    navigate('/feed')
                                }, 1000);

                            }
                            else {
                                setLoading(false)
                                toast.error(res?.data?.message)
                                // tab_1()
                            }
                        }).catch((err) => {
                            // console.log(err, "err")
                            setLoading(false)
                            toast.error(err?.response?.data?.message)
                            // tab_1()
                        })
                }
                else {
                    toast.error("Transaction is Declined")
                    setLoading(false)
                }
            }
        } catch (error) {
            setLoading(false)
            if (error?.message) {
                toast.error(error?.message)
            }

            console.log(error.message, "error");
        }

    }

    const tab_1 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").show();
        $(".de_tab_content_3").hide();
        setclassNamebox(1);
    };
    const tab_3 = () => {
        $(".de_tab_content_2").hide();
        $(".de_tab_content").hide();
        $(".de_tab_content_3").show();
        setclassNamebox(3);
    };
    const changeTitle = (value) => {
        // value == "" ? $("#title").html("Music") : $("#title").html(value)
        setsong(value)
        setsongerr(null)
    };
    const changeSale = (value) => {
        // value == "" ? $("#title").html("Music") : $("#title").html(value)
        setsale(value)
        setsaleerr(null)
        setPutonsaleerr(null)
    };
    const changeToken = (value) => {

        settoken(value)
        settokenerr(null)
        setPutonsaleerr(null)
    };

    const changetimeAmount = (value) => {
        // value == "" ? $("#title").html("Music") : $("#title").html(value)
        setamount(value)
        setamounterr(null)
        setTimeauctionerr(null)
        setPutonsaleerr(null)

    };


    const changeCollection = (value) => {
        if (value === "1155") {
            setprice(true)
        }
        else {
            setprice(false)
        }
        // value == "" ? $("#title").html("Music") : $("#title").html(value)
        setcollection(value)
        setcollectionerr(null)
        if (mining === "lazy" && value === "721") {
            setnfttype(3)
        }
        else if (mining === "instant" && value === "721") {
            setnfttype(1)
        }
        else if (mining === "lazy" && value === "1155") {
            setnfttype(2)
        }
        else if (mining === "instant" && value === "1155") {
            setnfttype(0)
        }
    };

    const changeMining = (value) => {
        // value == "" ? $("#title").html("Music") : $("#title").html(value)
        setmining(value)
        setminingerr(null)
        if (collection === "721" && value === "instant") {
            setnfttype(1)
        }
        else if (collection === "1155" && value === "instant") {
            setnfttype(0)
        }
        else if (collection === "721" && value === "lazy") {
            setnfttype(3)
        }
        else if (collection === "1155" && value === "lazy") {
            setnfttype(2)
        }
    };

    const changeDesc = (value) => {
        $("#DESC").html(value);
        setDescription(value)
        setDescriptionerr(null)
    };

    const changeISRC = (value) => {
        setISRC(value);
        setISRCerr(null)
    };

    const changeLink = (value) => {
        setNFTLink(value);
        setNFTLinkerr(null)
    };

    const changeCategory = (value) => {
        setSelectedCategory(value);
    };

    const changeClaimDate = (value) => {
        setClaimDate(value);
        setClaimDateerr(null)
    };

    const changeClaimLimit = (value) => {
        setClaimLimit(value);
        setClaimLimiterr(null)
    };
    const getCollectionModal = (value) => {
        value == "new_collection"
            ? $("#collectionModal").show()
            : $("#collectionModal").hide();
    };

    const makePreview = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const checkLimit = async (event) => {
        const file = event.target.files[0];
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);
        var durationss
        // const duration = audio;
        // console.log(duration,"duration");
        // if (duration > 30) {
        //     event.target.value = null;
        //     toast.error("Please upload a preview File less than 30 secs");
        //     return false;
        // }else{
        //     return true;
        // }
        const dus = audio.addEventListener("loadedmetadata", () => {
            const duration = audio.duration;

            setDuration(duration)
            if (duration > 30) {
                event.target.value = null;
                toast.error("Please upload a preview File less than 30 secs");
                return false;
            } else {
                return true;
            }
        });
        return dus
    };




    const [opento, setOpento] = React.useState(false);
    const [opentoerr, setOpentoerr] = React.useState(null);
    const [duration, setDuration] = useState(0)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [previousAlbum, setPreviousAlbum] = useState(false)
    const [previousPlaylist, setpreviousPlaylist] = useState(false)
    const handlePreviousAlbum = () => {
        setExistid()
        setName('')
        setPreviousAlbum(!previousAlbum)
    }

    const handlePreviousPlaylist = () => {
        setExistid()
        setName('')
        setpreviousPlaylist(!previousPlaylist)
    }
    const theme = useTheme();
    const [personName, setPersonName] = React.useState('');
    const [name, setName] = React.useState('');
    const [nameerr, setNamerr] = React.useState('');
    const handleChange = (e) => {

        setCategory(e.target.value)
        setCategoryerr(null)
    };
    const handleChange1 = (e) => {

        setMode(e.target.value)
        setModeerr(null)
    };

    const handleCurr = (e) => {

        // console.log('value', e.target.value);
        setcurr(e.target.value)
        setcurrerr(null)
        setPutonsaleerr(null)
    };
    const handleTime = (e) => {

        settimecurr(e.target.value)
        settimecurrerr(null)
        setPutonsaleerr(null)
    };

    const handleChangesAlbum = (e) => {
        setmusictype("album")
        setName(e.target.value.album_name)
        setExistid(e.target.value._id)
        // setModeerr(null)
    };
    const handleChangesPlay = (e) => {
        setmusictype("playlist")
        setName(e.target.value.playlist)
        setExistid(e.target.value._id)
        // setModeerr(null)
    };
    // get image

    const [profileData, setProfileData] = useState({});
    const [updateName, setUpdateName] = useState();
    const tokens = localStorage.getItem("iphephile");
    const accounts = localStorage.getItem("accounts");
    const [image, setImage] = useState(null);
    const [filetype, setFileType] = useState(true)
    const [audiotype, setAudioType] = useState('')

    const [filetype1, setFileType1] = useState('')
    const [audiotype1, setAudioType1] = useState('')

    const handleAudioClick = (event) => {
        hiddenAudioInput.current.click();
        setAudioType(true)
    };

    const handleAudioClick1 = (event) => {
        hiddenAudioInput1.current.click();
        setAudioType1(true)
    };

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleClick1 = (event) => {
        hiddenFileInput1.current.click();
    };

    const hiddenFileInput = useRef(null);
    const hiddenFileInput1 = useRef(null);
    const hiddenAudioInput = useRef(null)
    const hiddenAudioInput1 = useRef(null)
    const HandleNameUpdate = (e) => {
        setUpdateName(e.target.value);
        // setnameerr("");
    };
    const handleAudio = async (event) => {
        const files = event.target.files[0];
        setAudioType(files?.name)
        setDuration(0)
        setCurrentTime(0)
        try {
            //const vdata = await checkLimit(event)
            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "mp3" || extFile == "MP3") {
                let formData = await new FormData();
                formData.append("image", files);
                const audio = new Audio();
                audio.src = URL.createObjectURL(files);

                audio.addEventListener("loadedmetadata", async () => {
                    const duration = audio.duration;

                    if (duration > 30) {
                        event.target.value = null;
                        //toast.error("Please upload a preview File less than 30 secs");
                        const { data } = await Axios.post("/updatemp3", formData);
                        if (data.result) {

                            setpreview(data.result);
                            setpreviewerr(null)
                        }
                        else {
                            setpreview();
                            setpreviewerr("Audio file did not Uploaded")
                        }
                    } else {
                        const { data } = await Axios.post("/updateDp", formData);
                        if (data.result) {

                            setpreview(data.result);
                            setpreviewerr(null)
                        }
                        else {
                            setpreview();
                            setpreviewerr("Audio file did not Uploaded")
                        }
                    }
                });
            }
            else {
                setpreview();
                setpreviewerr("Only mp3 files are allowed!")
            }




        } catch (error) {
            // console.log(error, "err");
        }
    }
    const handleAudio1 = async (event) => {
        const files = event.target.files[0];
        console.log("Audio")
        setAudioType1(files?.name)
        setIsPlaying1(false)
        // handleFarward1()
        handlePause1()
        setAudio()
        audioRef1.current.value = 0
        setDuration1(0)
        setCurrentTime1(0)

        try {
            let formData = await new FormData();
            formData.append("image", files);

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "mp3" || extFile == "MP3") {
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {

                    setAudio(data.result);
                    setAudioerr(null)
                }
                else {
                    setAudio();
                    setAudioerr("Audio file did not upload")
                }
            }
            else {
                setAudio();
                setAudioerr("Only mp3 files are allowed!")
            }


        } catch (error) {
            // console.log(error, "err");
        }
    }

    const handleImageChange = async (event) => {
        const files = event.target.files[0];



        try {
            let formData = await new FormData();

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                formData.append("image", files);
                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {
                    // setLogo(data.result)
                    setImageFile(files)
                    setFileType(files?.name, true)
                    setImage(data.result);
                    setImageerr(null)
                    const updateData = {
                        name: profileData.name,
                        // phone: profileData?.result?.phone,
                        profilePicture: data.result,
                    };

                }
            } else {
                setImage(null)
                setImageerr("Only jpg, jpeg, png and gif files are allowed!");
            }
        } catch (error) {
            // console.log(error, "err");
        }
    };
    const handleAlbumImageChange = async (event) => {
        const files = event.target.files[0];

        try {
            let formData = await new FormData();

            var fileName = files?.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                formData.append("image", files);

                const { data } = await Axios.post("/updateDp", formData);
                if (data.result) {

                    setAlbumimage(data.result);
                    setAlbumimageerr(null)
                    const updateData = {
                        name: profileData.name,
                        // phone: profileData?.result?.phone,
                        profilePicture: data.result,
                    };

                }
            }

        } catch (error) {
            // console.log(error, "err");
        }
    };




    const getProfile = async () => {
        try {
            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setProfileData(res?.data?.result);
                        setUpdateName(res?.data?.result?.name);
                    } else {
                        toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        } catch (error) {
            // console.log(error);
        }
    };

    //Audio preview

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [currentTime1, setCurrentTime1] = useState(0);
    const [duration1, setDuration1] = useState(0)
    var du = 0
    const audioRef = useRef(null);
    const audioRef1 = useRef(null);

    // Function to seek to a specific time in audio.


    const handlePlay = () => {
        // handlePause()
        audioRef.current.play();
        setIsPlaying(true);
    };

    const handlePlay1 = () => {
        // handlePause1()
        audioRef1.current.play();
        setIsPlaying1(true);
    };

    const handleFarward = () => {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    }

    const handleFarward1 = () => {
        audioRef1.current.currentTime = 0;
        audioRef1.current.play();
    }

    const handlePause = () => {
        audioRef.current.pause();
        setIsPlaying(false);
    };
    const handlePause1 = () => {
        audioRef1.current.pause();
        setIsPlaying1(false);
    };


    const handlePlayPause = () => {

        if (isPlaying) {
            handlePause();
        } else {
            handlePlay();
            handlePause1()
        }
    };

    const handlePlayPause1 = () => {

        if (isPlaying1) {
            handlePause1();
        } else {
            handlePlay1();
            handlePause()
        }
    };


    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
        setDuration(audioRef.current.duration);
    };

    const handleTimeUpdate1 = () => {
        setCurrentTime1(audioRef1.current.currentTime);
        setDuration1(audioRef1.current.duration);
    };




    const handleSeek = (e) => {
        if (isNaN(e.target.value)) {
            audioRef.current.currentTime = 0;
            setCurrentTime(0);

        }
        else {
            audioRef.current.currentTime = e.target.value;
            setCurrentTime(e.target.value);
        }

    };

    const handleSeek1 = (e) => {
        if (isNaN(e.target.value)) {
            audioRef1.current.currentTime = 0;
            setCurrentTime1(0);

        }
        else {
            audioRef1.current.currentTime = e.target.value;
            setCurrentTime1(e.target.value);
        }

    };

    useEffect(() => {
        audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };


    }, []);

    useEffect(() => {
        audioRef1.current.addEventListener("timeupdate", handleTimeUpdate1);
        return () => {
            // audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        };

    }, []);




    function formatDuration(durationSeconds) {
        // console.log(durationSeconds)
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }

    function formatDuration1(durationSeconds) {
        const minutes = Math.floor(durationSeconds / 60);
        const seconds = Math.floor(durationSeconds % 60);
        const formattedSeconds = seconds.toString().padStart(2, "0");
        return `${minutes}:${formattedSeconds}`;
    }


    return (

        <div className="upload hedaer-bg">
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }} className='display-block'>
                <HeaderNew title={"Upload"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '100px' }}
                    className="upload-bg">

                    <div className="main no-bottom no-top" id="content">


                        <div className="select-method">
                            <div className="row wow fadeIn justify-content-center align-items-center">
                                <div className="col-md-9">
                                    <form id="form-create-item" className="form-border" method="post" action="https://gigaland.io/email.php">
                                        <div className="field-set">
                                            {/* <ArrowBackIcon className="back-arrow" onClick={(() => { navigate('/create', { state: { value } }) })} /> */}
                                            {/* <h5>Select method</h5> */}
                                            <div className="de_tab tab_methods">
                                                <div className="de_tab_content">
                                                    <Grid container spacing={2} sx={{ textAlign: 'center' }}>

                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Images </h5>
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                <div
                                                                                    className="profile-img"
                                                                                    onClick={handleClick}
                                                                                    style={{ cursor: "pointer" }}
                                                                                >
                                                                                    <div className="preview-img profile-banner-size bg-bug">
                                                                                        {image ? (
                                                                                            <Avatar
                                                                                                alt="Remy Sharp"
                                                                                                src={image}
                                                                                                sx={{
                                                                                                    width: 220,
                                                                                                    height: 250,
                                                                                                    border: "3px solid #fff",
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <Avatar
                                                                                                alt="Remy Sharp"
                                                                                                src={ava1}
                                                                                                sx={{
                                                                                                    width: 220,
                                                                                                    height: 250,
                                                                                                    border: "3px solid #fff",
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                        <div class="edit1">
                                                                                            <CloudUploadIcon />
                                                                                            <div>Upload Image</div>
                                                                                        </div>

                                                                                    </div>

                                                                                    <input
                                                                                        id="image-upload-input"
                                                                                        type="file"
                                                                                        accept="image/*"
                                                                                        onChange={handleImageChange}
                                                                                        ref={hiddenFileInput}
                                                                                        style={{ display: "none" }}
                                                                                    />

                                                                                </div>
                                                                            </Grid>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">File type: PNG, JPG, GIF</p>
                                                                <div style={{ color: 'var(--clr-font)', wordWrap: "break-word" }}>ChoosenFile:{filetype}</div>
                                                            </div>
                                                            {imageerr !== null ? (
                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{imageerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Preview Audio </h5>

                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size bg-bug">
                                                                                    {image ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={image}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>


                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype}</p>
                                                                {/* <div className="preview-div">FileType: {audiotype}</div> */}

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>

                                                                        {preview ? <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration}
                                                                            onChange={handleSeek}

                                                                        /> : <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={0}
                                                                            min={0}
                                                                            step={1}
                                                                            max={0}


                                                                        />}
                                                                        <audio ref={audioRef} src={preview} />
                                                                        <div className='audio-timmer'>
                                                                            {preview ? <div>
                                                                                {formatDuration(currentTime)}

                                                                            </div> : <></>}
                                                                            {preview ? <div>
                                                                                {/* {formatDuration(duration - currentTime)} */}
                                                                                {isNaN(duration) ? '0:00' : formatDuration(duration)}

                                                                            </div> : <></>}
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype && preview ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying ? (<> {currentTime === duration ? <img src={s3} /> : <img src={s6} />}</>) : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>
                                                                                {/* {currentTime === duration ? <></> : <></>} */}

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                    </Stack>

                                                                </div>


                                                                <div className="audio-div" style={{ "display": "none" }}>
                                                                    <input type="file" id="preview" onChange={handleAudio}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput}
                                                                        className="btn-main fle_choose_btn"
                                                                    // placeholder="Browse"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {previewerr !== null ? (
                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{previewerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>


                                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                                                            <div className="d-create-file minnft">
                                                                <h5>Audio </h5>
                                                                {/* <FontAwesomeIcon icon={faVolumeUp} /> */}
                                                                <div className="profile-img-outer minnft">
                                                                    <div className="image-upload-container">
                                                                        <div className="box-decoration">
                                                                            <div
                                                                                className="profile-img"
                                                                                onClick={handleAudioClick1}
                                                                                style={{ cursor: "pointer" }}
                                                                            >

                                                                                <div className="preview-img profile-banner-size bg-bug">
                                                                                    {image ? (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={image}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <Avatar
                                                                                            alt="Remy Sharp"
                                                                                            src={ava}
                                                                                            sx={{
                                                                                                width: 220,
                                                                                                height: 250,
                                                                                                border: "3px solid #fff",
                                                                                            }}
                                                                                        />
                                                                                    )}


                                                                                    <div class="edit1">
                                                                                        <CloudUploadIcon />
                                                                                        <div>Upload Audio</div>
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p id="file_name-preview">Filetype: {audiotype1}</p>
                                                                {/* <div className="preview-div" >FileType: {audiotype1}</div> */}

                                                                <div className='track-time'>

                                                                    <Box sx={{ width: '80%', margin: 'auto' }}>
                                                                        {audio ? <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={currentTime1}
                                                                            min={0}
                                                                            step={1}
                                                                            max={duration1}
                                                                            onChange={handleSeek1}

                                                                        /> : <Slider
                                                                            aria-label="time-indicator"
                                                                            size="small"
                                                                            value={0}
                                                                            min={0}
                                                                            step={1}
                                                                            max={0}

                                                                        />}

                                                                        <audio ref={audioRef1} src={audio} />

                                                                        <div className='audio-timmer'>
                                                                            {audio ? <div>
                                                                                {formatDuration(currentTime1)}

                                                                            </div> : <></>}
                                                                            {audio ? <div>
                                                                                {isNaN(duration1) ? '0:00' : formatDuration(duration1)}

                                                                            </div> : <></>}
                                                                        </div>

                                                                    </Box>

                                                                </div>
                                                                <div>
                                                                    <Stack direction="row" spacing={4} className='music-stack'>
                                                                        <div>
                                                                            {/* <img src={s1} /> */}
                                                                        </div>
                                                                        <div>
                                                                            <img src={s2}
                                                                                onClick={handleFarward1}
                                                                            />
                                                                        </div>
                                                                        <div >
                                                                            {audiotype1 && audio ? (<>
                                                                                <Button
                                                                                    onClick={handlePlayPause1}
                                                                                >
                                                                                    {isPlaying1 ? <> {currentTime1 === duration1 ? <img src={s3} /> : <img src={s6} />}</> : (<><img src={s3} /> </>)}
                                                                                </Button>
                                                                            </>) : (<>

                                                                                <Button
                                                                                // onClick={handlePlayPause}
                                                                                >
                                                                                    {isPlaying1 ? (<><img src={s6} /></>) : (<><img src={s3} /> </>)}
                                                                                </Button></>)}

                                                                        </div>
                                                                        <div>
                                                                            <img src={s4} />
                                                                        </div>
                                                                        <div>
                                                                            {/* <img src={s5} /> */}
                                                                        </div>

                                                                    </Stack>

                                                                </div>



                                                                <div className="audio-div" style={{ "display": "none" }}>
                                                                    <input type="file" id="preview" onChange={handleAudio1}
                                                                        accept="audio/*"
                                                                        ref={hiddenAudioInput1}
                                                                        className="btn-main fle_choose_btn"
                                                                    // placeholder="Browse"
                                                                    />
                                                                </div>



                                                            </div>
                                                            {audioerr !== null ? (
                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{audioerr}</div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Grid>

                                                    </Grid>


                                                    <div className="spacer-single"></div>

                                                    {/* <input type="button" onClick={() => tab_2()} i
                                                        className="btn btn-upload"
                                                        value="Next"
                                                    /> */}
                                                    {/* <Button onClick={() => tab_2()} i className="btn btn-upload" style={{ "marginTop": "30px" }}>Next</Button> */}
                                                    <div className="spacer-single"></div>
                                                </div>

                                                {filetype ? (<>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: '15px' }}   >
                                                            <div className="spacer-single">
                                                                <Tabs
                                                                    value={value}
                                                                    onChange={handleUploadTrack}
                                                                    aria-label="icon position tabs example"
                                                                    className="mint-nft-tab"
                                                                    variant="fullWidth"
                                                                // aria-label="full width tabs example"
                                                                >

                                                                    <Tab icon={<AudiotrackIcon />} iconPosition="start" label="Track" {...a11yProps(0)} />
                                                                    <Tab icon={<LibraryMusicIcon />} iconPosition="start" label="Album" {...a11yProps(1)} />
                                                                    <Tab icon={<QueueMusicIcon />} iconPosition="start" label="Playlist" {...a11yProps(2)} />
                                                                </Tabs>

                                                                <SwipeableViews
                                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                                    index={value}
                                                                    onChangeIndex={handleChangeIndex}
                                                                >

                                                                    <TabPanel value={value} index={0} className="upload-mint-music-tab-whole-div" >
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {modeerr !== null ? (
                                                                                                <div className="errordiv">{modeerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>

                                                                                {mode === "Other" ? <>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="description-part">
                                                                                            <h5>Other </h5>
                                                                                            <textarea
                                                                                                name="postContent"
                                                                                                onChange={(e) => { setOther(e.target.value); setothererr(null) }}
                                                                                                placeholder="Others"

                                                                                            />
                                                                                            {othererr !== null ? (
                                                                                                <div className="errordiv">{othererr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                </> : <></>}
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5>Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input name="item_price"
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Contract Address
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setContractAddress(e.target.value); setContractAddressErr("") }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Contract Address"
                                                                                            />
                                                                                        </div>
                                                                                        {contractAddressErr != "" ? <div style={{ color: "red", textAlign: "center" }}>{contractAddressErr}</div> : <></>}
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Put On Sale
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handlePutonsale} />

                                                                                            {putonsale ? <div className="put-on-sale-description">You'll recieve bids on this item</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    {putonsale ? (<>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>


                                                                                            <div className="de_tab_content">

                                                                                                <input type="text" name="item_price" onChange={(e) => setErc20Add(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Erc20 Address"
                                                                                                />
                                                                                            </div>

                                                                                            {/* <div className="de_tab_content">

    <input type="Number" name="item_price" onChange={(e) => setAmounts(e.target.value)}
        style={{ borderRadius: "25px" }}
        id="Title"
        className="form-control"
        placeholder="Enter Amount"
    />
</div> */}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input
                                                                                                    onKeyPress={(event) => {
                                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                                            event.preventDefault();
                                                                                                            setPutonsaleerr("Enter Valid Number")
                                                                                                        }
                                                                                                    }}
                                                                                                    name="item_price" onChange={(e) => changeSale(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Minimum Bid"
                                                                                                />
                                                                                                <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <FormControl fullWidth>
                                                                                                {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                <Select
                                                                                                    // multiple
                                                                                                    displayEmpty

                                                                                                    value={curr}
                                                                                                    onChange={handleCurr}
                                                                                                    input={<OutlinedInput />}
                                                                                                    renderValue={(selected) => {
                                                                                                        if (selected && selected.length === 0) {
                                                                                                            return <MenuItem disabled>*Pick a Currency Type</MenuItem>;
                                                                                                        }

                                                                                                        return selected;
                                                                                                    }}

                                                                                                    MenuProps={MenuProps}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >

                                                                                                    {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                    {Currency && Currency.map((name) => (
                                                                                                        <MenuItem
                                                                                                            key={name}
                                                                                                            value={name}
                                                                                                            style={getStyles(name, personName, theme)}
                                                                                                        >
                                                                                                            {name}
                                                                                                        </MenuItem>
                                                                                                    ))}

                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input name="item_price" onChange={(e) => changeToken(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Token"
                                                                                                />
                                                                                            </div>
                                                                                        </Grid> */}
                                                                                        {putonsaleerr !== null ? (
                                                                                            <div className="errordiv">{putonsaleerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                            <h5>
                                                                                                TimeAution
                                                                                                <span style={{ color: "red" }}> * </span>
                                                                                                <Switch {...label} onClick={handleTimeauction} />

                                                                                                {timeauction ? <div className="put-on-sale-description">Bids below this amount won't allowed</div> : null}
                                                                                            </h5>
                                                                                        </Grid>
                                                                                        {timeauction ? (<>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <div className="de_tab_content">

                                                                                                    <input
                                                                                                        onKeyPress={(event) => {
                                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                                event.preventDefault();
                                                                                                                setTimeauctionerr("Enter Valid Number")
                                                                                                            }
                                                                                                        }}
                                                                                                        name="item_price" onChange={(e) => changetimeAmount(e.target.value)}
                                                                                                        style={{ borderRadius: "25px" }}
                                                                                                        id="Title"
                                                                                                        className="form-control"
                                                                                                        placeholder="Enter Bid Time"
                                                                                                    />
                                                                                                    {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <FormControl fullWidth>
                                                                                                    {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                    <Select
                                                                                                        // multiple
                                                                                                        displayEmpty

                                                                                                        value={timecurr}
                                                                                                        onChange={handleTime}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>*Pick a Time</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >

                                                                                                        {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                        {Days && Days.map((name) => (
                                                                                                            <MenuItem
                                                                                                                key={name}
                                                                                                                value={name}
                                                                                                                style={getStyles(name, personName, theme)}
                                                                                                            >
                                                                                                                {name}
                                                                                                            </MenuItem>
                                                                                                        ))}

                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </>) : null}

                                                                                    </>) : null}
                                                                                    {timeauctionerr !== null ? (
                                                                                        <div className="errordiv">{timeauctionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Unlocked once purchased
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handleUnlock} />

                                                                                            {unlock ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                </Grid>


                                                                                {unlock ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setUdescription(e.target.value); setUdescriptionerr(null); setUnlockerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Unlock Description"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {udescriptionerr !== null ? (
                                                                                            <div className="errordiv">{udescriptionerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                {unlockerr !== null ? (
                                                                                    <div className="errordiv">{unlockerr}</div>
                                                                                ) : (
                                                                                    <> </>
                                                                                )}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Skip Royalty
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} checked={skip} onClick={handleSkip} />

                                                                                        {!skip ? <div className="put-on-sale-description">Royalty Amount</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                {!skip ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setSkiperr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" onChange={(e) => { setroyalty(e.target.value); setSkiperr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Royalty Fee"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {skiperr !== null ? (
                                                                                            <div className="errordiv">{skiperr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setRoyalAddress(e.target.value) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Royalty Receivers"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {skiperr !== null ? (
                                                                                            <div className="errordiv">{skiperr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}

                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Direct Nft Delivery
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} onClick={handleDelivery} />

                                                                                        {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Collection
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >
                                                                                            <FormControlLabel value="721" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-721" />
                                                                                            <FormControlLabel value="1155" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-1155" />


                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                    {collectionerr !== null ? (
                                                                                        <div className="errordiv">{collectionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </Grid>
                                                                                {price ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setqty(e.target.value); setqtyerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Quantity"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {qtyerr !== null ? (
                                                                                            <div className="errordiv">{qtyerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Minning Type
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >
                                                                                            <FormControlLabel value="instant" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Instant Minting" />
                                                                                            <FormControlLabel value="lazy" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Lazy Minting" />


                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                    {miningerr !== null ? (
                                                                                        <div className="errordiv">{miningerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}

                                                                                </Grid>

                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">

                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ marginTop: "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn mint">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>


                                                                                <div className="spacer-single"></div>


                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={1} className="upload-mint-music-tab-whole-div">
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                                                                    <div className="d-create-file minnft-innertab">
                                                                                        <div className="profile-img-outer1 minnft">
                                                                                            <div className="image-upload-container">
                                                                                                <div className="box-decoration">
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                        <div
                                                                                                            className="profile-img"
                                                                                                            onClick={handleClick1}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >


                                                                                                            <div className="preview-img profile-banner-size bg-bug">
                                                                                                                {image ? (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={albumimage ? albumimage : image}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={ava1}
                                                                                                                        sx={{
                                                                                                                            width: 250,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                                <div class="edit1">
                                                                                                                    <CloudUploadIcon />
                                                                                                                    <div>Upload Cover Image</div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <input
                                                                                                                id="image-upload-input"
                                                                                                                type="file"
                                                                                                                accept="image/*"
                                                                                                                onChange={(e) => { handleAlbumImageChange(e) }}
                                                                                                                ref={hiddenFileInput1}
                                                                                                                style={{ display: "none" }}
                                                                                                            />

                                                                                                        </div>
                                                                                                        {albumimageerr !== null ? (
                                                                                                            <div className="errordiv" style={{ marginTop: "10px" }}>{albumimageerr}</div>
                                                                                                        ) : (
                                                                                                            <></>
                                                                                                        )}
                                                                                                    </Grid>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={9} >
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                {mode === "Other" ? <>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="description-part">
                                                                                            <h5>Other </h5>
                                                                                            <textarea
                                                                                                name="postContent"
                                                                                                onChange={(e) => { setOther(e.target.value); setothererr(null) }}
                                                                                                placeholder="Others"

                                                                                            />
                                                                                            {othererr !== null ? (
                                                                                                <div className="errordiv">{othererr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                </> : <></>}
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div  >
                                                                                        <h5 className="h5-custom2">
                                                                                            Add to Previous Album
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} checked={previousAlbum} onClick={handlePreviousAlbum} />
                                                                                            {/* <FormControlLabel required control={<Switch {...label}  onClick={handlePreviousAlbum}  />} label="Required" /> */}
                                                                                        </h5>
                                                                                        {previousAlbum ? (<>

                                                                                            {albumlist.length > 0 ? <>
                                                                                                <FormControl fullWidth>
                                                                                                    <Select
                                                                                                        // multiple
                                                                                                        displayEmpty

                                                                                                        value={name}
                                                                                                        onChange={handleChangesAlbum}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>Previous Album</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >

                                                                                                        {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                        {albumlist.map((row, index) => (
                                                                                                            <MenuItem
                                                                                                                key={row?._id}
                                                                                                                value={row}

                                                                                                                style={getStyles(row?._id, personName, theme)}
                                                                                                            >
                                                                                                                {row?.album_name}
                                                                                                            </MenuItem>
                                                                                                        ))}

                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </> : <>No Previous ALbum List</>}


                                                                                        </>) : null
                                                                                        }
                                                                                    </div>
                                                                                </Grid>
                                                                                {!previousAlbum ? (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Album Name
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => { setmusictype("album"); setAlbum(e.target.value); setNamerr(null) }}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Enter Your Album Name"
                                                                                        />
                                                                                        {nameerr !== null ? (
                                                                                            <div className="errordiv" style={{ marginTop: "10px" }}>{nameerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>) : null}

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5 >Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>


                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input name="item_price"
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                id="Title" onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Contract Address
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setContractAddress(e.target.value); setContractAddressErr("") }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Contract Address"
                                                                                            />
                                                                                        </div>
                                                                                        {contractAddressErr != "" ? <div style={{ color: "red", textAlign: "center" }}>{contractAddressErr}</div> : <></>}
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Put On Sale
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handlePutonsale} />

                                                                                            {putonsale ? <div className="put-on-sale-description">You'll recieve bids on this item</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    {putonsale ? (<>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>


                                                                                            <div className="de_tab_content">

                                                                                                <input type="text" name="item_price" onChange={(e) => setErc20Add(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Erc20 Address"
                                                                                                />
                                                                                            </div>

                                                                                            {/* <div className="de_tab_content">

    <input type="Number" name="item_price" onChange={(e) => setAmounts(e.target.value)}
        style={{ borderRadius: "25px" }}
        id="Title"
        className="form-control"
        placeholder="Enter Amount"
    />
</div> */}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input
                                                                                                    onKeyPress={(event) => {
                                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                                            event.preventDefault();
                                                                                                            setPutonsaleerr("Enter Valid Number")
                                                                                                        }
                                                                                                    }}
                                                                                                    name="item_price" onChange={(e) => changeSale(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Minimum Bid"
                                                                                                />
                                                                                                <div className="put-on-sale-div">Set a period of time for which buyers can place bids</div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <FormControl fullWidth>
                                                                                                {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                <Select
                                                                                                    // multiple
                                                                                                    displayEmpty

                                                                                                    value={curr}
                                                                                                    onChange={handleCurr}
                                                                                                    input={<OutlinedInput />}
                                                                                                    renderValue={(selected) => {
                                                                                                        if (selected && selected.length === 0) {
                                                                                                            return <MenuItem disabled>*Pick a Currency Type</MenuItem>;
                                                                                                        }

                                                                                                        return selected;
                                                                                                    }}

                                                                                                    MenuProps={MenuProps}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >

                                                                                                    {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                    {Currency && Currency.map((name) => (
                                                                                                        <MenuItem
                                                                                                            key={name}
                                                                                                            value={name}
                                                                                                            style={getStyles(name, personName, theme)}
                                                                                                        >
                                                                                                            {name}
                                                                                                        </MenuItem>
                                                                                                    ))}

                                                                                                </Select>
                                                                                                {modeerr !== null ? (
                                                                                                    <div className="errordiv">{modeerr}</div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input name="item_price" onChange={(e) => changeToken(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Token"
                                                                                                />
                                                                                            </div>
                                                                                        </Grid> */}
                                                                                        {putonsaleerr !== null ? (
                                                                                            <div className="errordiv">{putonsaleerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                            <h5>
                                                                                                TimeAution
                                                                                                <span style={{ color: "red" }}> * </span>
                                                                                                <Switch {...label} onClick={handleTimeauction} />

                                                                                                {timeauction ? <div className="put-on-sale-description">Bids below this amount won't allowed</div> : null}
                                                                                            </h5>
                                                                                        </Grid>
                                                                                        {timeauction ? (<>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <div className="de_tab_content">

                                                                                                    <input
                                                                                                        onKeyPress={(event) => {
                                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                                event.preventDefault();
                                                                                                                setTimeauctionerr("Enter Valid Number")
                                                                                                            }
                                                                                                        }}
                                                                                                        name="item_price" onChange={(e) => changetimeAmount(e.target.value)}
                                                                                                        style={{ borderRadius: "25px" }}
                                                                                                        id="Title"
                                                                                                        className="form-control"
                                                                                                        placeholder="Enter Bid Time"
                                                                                                    />
                                                                                                    {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <FormControl fullWidth>
                                                                                                    {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                    <Select
                                                                                                        // multiple
                                                                                                        displayEmpty

                                                                                                        value={timecurr}
                                                                                                        onChange={handleTime}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>*Pick a Time</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >

                                                                                                        {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                        {Days && Days.map((name) => (
                                                                                                            <MenuItem
                                                                                                                key={name}
                                                                                                                value={name}
                                                                                                                style={getStyles(name, personName, theme)}
                                                                                                            >
                                                                                                                {name}
                                                                                                            </MenuItem>
                                                                                                        ))}

                                                                                                    </Select>
                                                                                                    {modeerr !== null ? (
                                                                                                        <div className="errordiv">{modeerr}</div>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </>) : null}

                                                                                    </>) : null}
                                                                                    {timeauctionerr !== null ? (
                                                                                        <div className="errordiv">{timeauctionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Unlocked once purchased
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handleUnlock} />

                                                                                            {unlock ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                </Grid>


                                                                                {unlock ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setUdescription(e.target.value); setUdescriptionerr(null); setUnlockerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Unlock Description"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {udescriptionerr !== null ? (
                                                                                            <div className="errordiv">{udescriptionerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                {unlockerr !== null ? (
                                                                                    <div className="errordiv">{unlockerr}</div>
                                                                                ) : (
                                                                                    <> </>
                                                                                )}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Skip Royalty
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} checked={skip} onClick={handleSkip} />

                                                                                        {!skip ? <div className="put-on-sale-description">Skip Amount</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                {!skip ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setSkiperr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" onChange={(e) => { setroyalty(e.target.value); setSkiperr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Royalty Fee"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {skiperr !== null ? (
                                                                                            <div className="errordiv">{skiperr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                    <div className="de_tab_content">

                                                                                        <input type="text" name="item_price" onChange={(e) => { setroyalty(e.target.value); setSkiperr(null) }}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"

                                                                                            className="form-control"
                                                                                            placeholder="Enter Royalty Receivers"
                                                                                        />
                                                                                        {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                    </div>
                                                                                    {skiperr !== null ? (
                                                                                        <div className="errordiv">{skiperr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </>) : null}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Direct Nft Delivery
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} onClick={handleDelivery} />

                                                                                        {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Collection
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >
                                                                                            <FormControlLabel value="721" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-721" />
                                                                                            <FormControlLabel value="1155" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-1155" />


                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                    {collectionerr !== null ? (
                                                                                        <div className="errordiv">{collectionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </Grid>
                                                                                {price ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setqty(e.target.value); setqtyerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Quantity"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {qtyerr !== null ? (
                                                                                            <div className="errordiv">{qtyerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Minning Type
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >
                                                                                            <FormControlLabel value="instant" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Instant Minting" />
                                                                                            <FormControlLabel value="lazy" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Lazy Minting" />


                                                                                        </RadioGroup>

                                                                                    </FormControl>

                                                                                    {miningerr !== null ? (
                                                                                        <div className="errordiv">{miningerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </Grid>




                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">
                                                                                        {/* <input type="checkbox" id="switch-unlock7" checked={OpenEveryone == 1 ?? false} onClick={(e) =>
                                                                OpentoEveryOne(e.target.value)}
                                                                value={OpenEveryone == "0" ? "0" : "1"}
                                                                className="checkbox"
                                                            /> */}
                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ marginTop: "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn mint">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>


                                                                                <div className="spacer-single"></div>


                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                    <TabPanel value={value} index={2} className="upload-mint-music-tab-whole-div">
                                                                        <div className="de_tab_content">
                                                                            <Grid container spacing={0}  >
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3} >
                                                                                    <div className="d-create-file minnft-innertab">
                                                                                        <div className="profile-img-outer1 minnft">
                                                                                            <div className="image-upload-container">
                                                                                                <div className="box-decoration">
                                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                                        <div
                                                                                                            className="profile-img"
                                                                                                            onClick={handleClick1}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                        >


                                                                                                            <div className="preview-img profile-banner-size bg-bug">
                                                                                                                {image ? (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={albumimage ? albumimage : image}
                                                                                                                        sx={{
                                                                                                                            width: 220,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                ) : (
                                                                                                                    <Avatar
                                                                                                                        alt="Remy Sharp"
                                                                                                                        src={ava1}
                                                                                                                        sx={{
                                                                                                                            width: 250,
                                                                                                                            height: 250,
                                                                                                                            border: "3px solid #fff",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                )}
                                                                                                                <div class="edit1">
                                                                                                                    <CloudUploadIcon />
                                                                                                                    <div>Upload Cover Image</div>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <input
                                                                                                                id="image-upload-input"
                                                                                                                type="file"
                                                                                                                onChange={(e) => { handleAlbumImageChange(e) }}
                                                                                                                ref={hiddenFileInput1}
                                                                                                                style={{ display: "none" }}
                                                                                                            />

                                                                                                        </div>
                                                                                                    </Grid>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={9} >
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">Song Name</h5>
                                                                                        <input type="text" name="item_price" onChange={(e) => changeTitle(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Title here"
                                                                                        />
                                                                                        {songerr !== null ? (
                                                                                            <div className="errordiv">{songerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                    <div id="tab_opt_1" className="album-tab">
                                                                                        <h5 className="h5-custom2">
                                                                                            International Standard Recording Code(ISRC)
                                                                                        </h5>
                                                                                        <input type="text" name="isrc" onChange={(e) => changeISRC(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your ISRC here"
                                                                                        />
                                                                                        {ISRCerr !== null ? (
                                                                                            <div className="errordiv">{ISRCerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                   
                                                                                </Grid> */}

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1">
                                                                                        <h5 className="h5-custom2">
                                                                                            Enter the NFT Collection link
                                                                                        </h5>
                                                                                        <input type="text" name="platform_link" onChange={(e) => changeLink(e.target.value)}
                                                                                            style={{ borderRadius: "25px" }}
                                                                                            id="Title"
                                                                                            className="form-control"
                                                                                            placeholder="Your Platform Link here"
                                                                                        />
                                                                                        {NFTLinkerr !== null ? (
                                                                                            <div className="errordiv">{NFTLinkerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Song Category</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={category}
                                                                                                onChange={handleChange}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Genre</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem>
                                                                                                {northamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Latin America</strong></MenuItem>
                                                                                                {latinamerica.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Europe</strong></MenuItem>
                                                                                                {europe.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled><strong>Africa</strong></MenuItem>
                                                                                                {africa.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Asia</strong></MenuItem>
                                                                                                {asia.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Middle East</strong></MenuItem>
                                                                                                {middleeast.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                                <MenuItem className="select-genre" disabled ><strong>Oceania</strong></MenuItem>
                                                                                                {oceania.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {categoryerr !== null ? (
                                                                                                <div className="errordiv">{categoryerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="select-input">
                                                                                        <FormControl fullWidth>
                                                                                            <h5 className="h5-custom2">Pick a Mood</h5>
                                                                                            <Select
                                                                                                // multiple
                                                                                                displayEmpty

                                                                                                value={mode}
                                                                                                onChange={handleChange1}
                                                                                                input={<OutlinedInput />}
                                                                                                renderValue={(selected) => {
                                                                                                    if (selected && selected.length === 0) {
                                                                                                        return <MenuItem disabled>*Pick a Mood</MenuItem>;
                                                                                                    }

                                                                                                    return selected;
                                                                                                }}

                                                                                                MenuProps={MenuProps}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >

                                                                                                {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                {mood.map((name) => (
                                                                                                    <MenuItem
                                                                                                        key={name}
                                                                                                        value={name}
                                                                                                        style={getStyles(name, personName, theme)}
                                                                                                    >
                                                                                                        {name}
                                                                                                    </MenuItem>
                                                                                                ))}

                                                                                            </Select>
                                                                                            {modeerr !== null ? (
                                                                                                <div className="errordiv">{modeerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </Grid>
                                                                                {mode === "Other" ? <>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="description-part">
                                                                                            <h5>Other </h5>
                                                                                            <textarea
                                                                                                name="postContent"
                                                                                                onChange={(e) => { setOther(e.target.value); setothererr(null) }}
                                                                                                placeholder="Others"

                                                                                            />
                                                                                            {othererr !== null ? (
                                                                                                <div className="errordiv">{othererr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                </> : <></>}
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div  >
                                                                                        <h5>
                                                                                            Add to Previous PlayList
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} checked={previousPlaylist} onClick={handlePreviousPlaylist} />

                                                                                        </h5>
                                                                                        {previousPlaylist ? (
                                                                                            <>
                                                                                                {playslist.length > 0 ? <>

                                                                                                    <FormControl fullWidth>
                                                                                                        <Select

                                                                                                            displayEmpty

                                                                                                            value={name}
                                                                                                            onChange={handleChangesPlay}
                                                                                                            input={<OutlinedInput />}
                                                                                                            renderValue={(selected) => {
                                                                                                                if (selected && selected.length === 0) {
                                                                                                                    return <MenuItem disabled>Previous Album</MenuItem>;
                                                                                                                }

                                                                                                                return selected;
                                                                                                            }}

                                                                                                            MenuProps={MenuProps}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >


                                                                                                            {playslist && playslist.map((row, index) => (
                                                                                                                <MenuItem
                                                                                                                    key={row?._id}
                                                                                                                    value={row}

                                                                                                                    style={getStyles(row?._id, personName, theme)}
                                                                                                                >
                                                                                                                    {row?.playlist}
                                                                                                                </MenuItem>
                                                                                                            ))}

                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                </> : <> No Previous Play List</>}
                                                                                            </>

                                                                                        ) : <div id="tab_opt_1">
                                                                                            <h5 className="h5-custom2">
                                                                                                Play List Name
                                                                                            </h5>
                                                                                            <input type="text" name="platform_link" onChange={(e) => { setmusictype("playlist"); setPlay(e.target.value); setNamerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Your Playlist Name Here"
                                                                                            />
                                                                                            {nameerr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{nameerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}

                                                                                        </div>}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                            <Grid container spacing={0} sx={{ marginTop: '20px' }}>
                                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                    <div id="tab_opt_1" className="description-part">
                                                                                        <h5>Description </h5>
                                                                                        <textarea
                                                                                            name="postContent"
                                                                                            onChange={(e) => changeDesc(e.target.value)}
                                                                                            placeholder="Explain what your NFT is about ( Max 500 characters )"
                                                                                            rows={4}
                                                                                            cols={40}
                                                                                        />
                                                                                        {descriptionerr !== null ? (
                                                                                            <div className="errordiv">{descriptionerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </div>
                                                                        <div className="de_tab_content_2" >
                                                                            <h5>
                                                                                Required Fields
                                                                                <span style={{ color: "red" }}> * </span>
                                                                                <Switch {...label} onClick={handleRequirefield} />

                                                                            </h5>

                                                                            {requirefield ? (<>
                                                                                <p>
                                                                                    set the Requirements users need to meet to mint your
                                                                                    Dreamster
                                                                                </p>

                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Set date and time for claiming the song
                                                                                            </label>
                                                                                            <input type="date" name="item_price" id="Title" className="form-control date-input" onChange={(e) =>
                                                                                                changeClaimDate(e.target.value)
                                                                                            }
                                                                                            />
                                                                                            {ClaimDateerr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimDateerr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={6} xl={6}>
                                                                                        <div id="tab_opt_1" className="set-date">
                                                                                            <label>
                                                                                                Total NFT Claim
                                                                                            </label>
                                                                                            <input name="item_price" id="Title"
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setClaimLimiterr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                onChange={(e) => changeClaimLimit(e.target.value)} className="form-control date-input"
                                                                                                placeholder="Total NFT Claim" />
                                                                                            {ClaimLimiterr !== null ? (
                                                                                                <div className="errordiv" style={{ marginTop: "10px" }}>{ClaimLimiterr}</div>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Contract Address
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setContractAddress(e.target.value); setContractAddressErr("") }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Contract Address"
                                                                                            />
                                                                                        </div>
                                                                                        {contractAddressErr != "" ? <div style={{ color: "red", textAlign: "center" }}>{contractAddressErr}</div> : <></>}
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Put On Sale
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handlePutonsale} />

                                                                                            {putonsale ? <div className="put-on-sale-description">You'll recieve bids on this item</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                    {putonsale ? (<>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>


                                                                                            <div className="de_tab_content">

                                                                                                <input type="text" name="item_price" onChange={(e) => setErc20Add(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Erc20 Address"
                                                                                                />
                                                                                            </div>

                                                                                            {/* <div className="de_tab_content">

    <input type="Number" name="item_price" onChange={(e) => setAmounts(e.target.value)}
        style={{ borderRadius: "25px" }}
        id="Title"
        className="form-control"
        placeholder="Enter Amount"
    />
</div> */}
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input
                                                                                                    onKeyPress={(event) => {
                                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                                            event.preventDefault();
                                                                                                            setPutonsaleerr("Enter Valid Number")
                                                                                                        }
                                                                                                    }}
                                                                                                    name="item_price" onChange={(e) => changeSale(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Minimum Bid"
                                                                                                />
                                                                                                {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <FormControl fullWidth>
                                                                                                {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                <Select
                                                                                                    // multiple
                                                                                                    displayEmpty

                                                                                                    value={curr}
                                                                                                    onChange={handleCurr}
                                                                                                    input={<OutlinedInput />}
                                                                                                    renderValue={(selected) => {
                                                                                                        if (selected && selected.length === 0) {
                                                                                                            return <MenuItem disabled>*Pick a Currency Type</MenuItem>;
                                                                                                        }

                                                                                                        return selected;
                                                                                                    }}

                                                                                                    MenuProps={MenuProps}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >

                                                                                                    {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                    {Currency && Currency.map((name) => (
                                                                                                        <MenuItem
                                                                                                            key={name}
                                                                                                            value={name}
                                                                                                            style={getStyles(name, personName, theme)}
                                                                                                        >
                                                                                                            {name}
                                                                                                        </MenuItem>
                                                                                                    ))}

                                                                                                </Select>
                                                                                                {modeerr !== null ? (
                                                                                                    <div className="errordiv">{modeerr}</div>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                            <div className="de_tab_content">

                                                                                                <input name="item_price" onChange={(e) => changeToken(e.target.value)}
                                                                                                    style={{ borderRadius: "25px" }}
                                                                                                    id="Title"
                                                                                                    className="form-control"
                                                                                                    placeholder="Enter Token"
                                                                                                />
                                                                                            </div>
                                                                                        </Grid> */}
                                                                                        {putonsaleerr !== null ? (
                                                                                            <div className="errordiv">{putonsaleerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                            <h5>
                                                                                                TimeAution
                                                                                                <span style={{ color: "red" }}> * </span>
                                                                                                <Switch {...label} onClick={handleTimeauction} />

                                                                                                {timeauction ? <div className="put-on-sale-description">Bids below this amount won't allowed</div> : null}
                                                                                            </h5>
                                                                                        </Grid>
                                                                                        {timeauction ? (<>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <div className="de_tab_content">

                                                                                                    <input
                                                                                                        onKeyPress={(event) => {
                                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                                event.preventDefault();
                                                                                                                setTimeauctionerr("Enter Valid Number")
                                                                                                            }
                                                                                                        }}
                                                                                                        name="item_price" onChange={(e) => changetimeAmount(e.target.value)}
                                                                                                        style={{ borderRadius: "25px" }}
                                                                                                        id="Title"
                                                                                                        className="form-control"
                                                                                                        placeholder="Enter Bid Time"
                                                                                                    />
                                                                                                    {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                                                                <FormControl fullWidth>
                                                                                                    {/* <h5 className="h5-custom2">Pick a Mood</h5> */}
                                                                                                    <Select
                                                                                                        // multiple
                                                                                                        displayEmpty

                                                                                                        value={timecurr}
                                                                                                        onChange={handleTime}
                                                                                                        input={<OutlinedInput />}
                                                                                                        renderValue={(selected) => {
                                                                                                            if (selected && selected.length === 0) {
                                                                                                                return <MenuItem disabled>*Pick a Time</MenuItem>;
                                                                                                            }

                                                                                                            return selected;
                                                                                                        }}

                                                                                                        MenuProps={MenuProps}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >

                                                                                                        {/* <MenuItem className="select-genre" disabled><strong>North America</strong></MenuItem> */}
                                                                                                        {Days && Days.map((name) => (
                                                                                                            <MenuItem
                                                                                                                key={name}
                                                                                                                value={name}
                                                                                                                style={getStyles(name, personName, theme)}
                                                                                                            >
                                                                                                                {name}
                                                                                                            </MenuItem>
                                                                                                        ))}

                                                                                                    </Select>
                                                                                                    {modeerr !== null ? (
                                                                                                        <div className="errordiv">{modeerr}</div>
                                                                                                    ) : (
                                                                                                        <></>
                                                                                                    )}
                                                                                                </FormControl>
                                                                                            </Grid>
                                                                                        </>) : null}

                                                                                    </>) : null}
                                                                                    {timeauctionerr !== null ? (
                                                                                        <div className="errordiv">{timeauctionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                        <h5>
                                                                                            Unlocked once purchased
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            <Switch {...label} onClick={handleUnlock} />

                                                                                            {unlock ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                        </h5>
                                                                                    </Grid>
                                                                                </Grid>


                                                                                {unlock ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setUdescription(e.target.value); setUdescriptionerr(null); setUnlockerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Unlock Description"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {udescriptionerr !== null ? (
                                                                                            <div className="errordiv">{udescriptionerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}

                                                                                {unlockerr !== null ? (
                                                                                    <div className="errordiv">{unlockerr}</div>
                                                                                ) : (
                                                                                    <> </>
                                                                                )}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Skip Royalty
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} onClick={handleSkip} />

                                                                                        {!skip ? <div className="put-on-sale-description">Skip Amount</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                {!skip ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input
                                                                                                onKeyPress={(event) => {
                                                                                                    if (!/[0-9]/.test(event.key)) {
                                                                                                        event.preventDefault();
                                                                                                        setSkiperr("Enter Valid Number")
                                                                                                    }
                                                                                                }}
                                                                                                name="item_price" onChange={(e) => { setroyalty(e.target.value); setSkiperr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Royalty Fee"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {skiperr !== null ? (
                                                                                            <div className="errordiv">{skiperr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setroyalty(e.target.value); setSkiperr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Royalty Receivers"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {skiperr !== null ? (
                                                                                            <div className="errordiv">{skiperr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <h5>
                                                                                        Direct Nft Delivery
                                                                                        <span style={{ color: "red" }}> * </span>
                                                                                        <Switch {...label} onClick={handleDelivery} />

                                                                                        {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div> : null}
                                                                                    </h5>
                                                                                </Grid>
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Collection
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >
                                                                                            <FormControlLabel value="721" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-721" />
                                                                                            <FormControlLabel value="1155" control={<Radio onClick={(e) => changeCollection(e.target.value)} />} label="ERC-1155" />


                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                    {collectionerr !== null ? (
                                                                                        <div className="errordiv">{collectionerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </Grid>
                                                                                {price ? (<>
                                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                                        <div className="de_tab_content">

                                                                                            <input type="text" name="item_price" onChange={(e) => { setqty(e.target.value); setqtyerr(null) }}
                                                                                                style={{ borderRadius: "25px" }}
                                                                                                id="Title"

                                                                                                className="form-control"
                                                                                                placeholder="Enter Quantity"
                                                                                            />
                                                                                            {/* <div className="put-on-sale-div">Set a peroid of time for which buyers can place bids</div> */}
                                                                                        </div>
                                                                                        {qtyerr !== null ? (
                                                                                            <div className="errordiv">{qtyerr}</div>
                                                                                        ) : (
                                                                                            <> </>
                                                                                        )}
                                                                                    </Grid>
                                                                                </>) : null}
                                                                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                                                                    <FormControl>
                                                                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                                        <h5>
                                                                                            Choose Minning Type
                                                                                            <span style={{ color: "red" }}> * </span>
                                                                                            {/* <Switch {...label}  onClick={handleDelivery}/> */}

                                                                                            {/* {delivery ? <div className="put-on-sale-description">Content will be Unlocked after successful transaction</div>  : null } */}
                                                                                        </h5>
                                                                                        <RadioGroup
                                                                                            row
                                                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                            name="row-radio-buttons-group"
                                                                                            className="radio-div"
                                                                                        >

                                                                                            <FormControlLabel value="instant" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Instant Minting" />
                                                                                            <FormControlLabel value="lazy" control={<Radio onClick={(e) => changeMining(e.target.value)} />} label="Lazy Minting" />


                                                                                        </RadioGroup>
                                                                                    </FormControl>
                                                                                    {miningerr !== null ? (
                                                                                        <div className="errordiv">{miningerr}</div>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </Grid>



                                                                                <div className="switch-with-title" style={{ borderRadius: "30px" }}>

                                                                                    <h5> {/* <FontAwesomeIcon icon={faGlobe} /> &nbsp;Open to */}Open to Everyone
                                                                                    </h5>
                                                                                    <div className="de-switch 1">
                                                                                        {/* <input type="checkbox" id="switch-unlock7" checked={OpenEveryone == 1 ?? false} onClick={(e) =>
                                                                OpentoEveryOne(e.target.value)}
                                                                value={OpenEveryone == "0" ? "0" : "1"}
                                                                className="checkbox"
                                                            /> */}
                                                                                        <Switch {...label} checked={opento} onClick={() => OpentoEveryOnes()} />
                                                                                        {opentoerr !== null ? (
                                                                                            <div className="errordiv" style={{ marginTop: "10px" }}>{opentoerr}</div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="submit-btn mint">
                                                                                    {loading == true ? <Button className="btn btn-upload">Processing ...</Button> :
                                                                                        <Button i className="btn btn-upload" onClick={() => createMusic()}>Submit</Button>}
                                                                                </div>

                                                                                <div className="spacer-single"></div>


                                                                            </>) : null}





                                                                        </div>
                                                                    </TabPanel>
                                                                </SwipeableViews>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </>) : null}




                                            </div>


                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>

                    </div>
                </Box>
            </Box>
        </div>

    );
}

export default MintNftNew;

import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import ava1 from "../../img/dster-webp/ava1.webp";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import SendTrack from '../ShareLink/SendTrack';
import Tracks from "../Music-traxk/Tracks";
import Tooltip from '@mui/material/Tooltip';
import VerifiedIcon from '@mui/icons-material/Verified';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Axios from "../../Axios";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import Album from '../../img/dster-webp/music-logo.webp';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Companylogo from '../../img/dster-webp/company-logo.webp';
import Modal from '@mui/material/Modal';
import RepeatIcon from '@mui/icons-material/Repeat';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import Track from "../Music-traxk/Track";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import s6 from "../../img/dster-webp/pass-play.webp"
import Popover from '@mui/material/Popover';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HeaderNew from '../HeaderNew/HeaderNew';


const drawerWidth = 280;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'var(--clr-bcg)',
    height: '620px',
    // overflow: 'scroll',
    // border: '2px solid #000',
    // boxShadow: 'none',
    p: 4,
    '@media all and (max-width: 1199.98px)': {
        width: '55%'
    },
    '@media all and (max-width: 768.98px)': {
        width: '85%'
    },
    '@media all and (max-width: 575.98px)': {
        width: '95%'
    }
};
const label = { inputProps: { 'aria-label': 'Switch demo' } };

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Feed = () => {
    const [value, setValue] = React.useState(0);
    const [music, setMusic] = React.useState([]);
    const [name, setName] = React.useState();
    const [playname, setPlayname] = React.useState();
    const [song_id, setsongid] = React.useState()
    const [play, setPlay] = React.useState([]);
    const [audiosource, setAudiosource] = React.useState();
    const [count, setCount] = React.useState()
    const tokens = localStorage.getItem("iphephile");
    const navigate = useNavigate()
    const [audioDurations, setAudioDurations] = useState([]);
    const [openModel, setOpenModel] = React.useState(false);
    const handleOpen = () => setOpenModel(true);
    const handleModelClose = () => setOpenModel(false);
    const [rows, setRows] = useState({})
    const [newlist, setNewList] = useState(false)
    const handlenewPlayList = () => setNewList(!newlist)
    const [openModel2, setOpenModel2] = React.useState(false);
    const handleOpen2 = () => setOpenModel2(true);
    const handleModelClose2 = () => setOpenModel2(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);


    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);

    };
    const audio = localStorage.getItem("audiosource")
    const audios = localStorage.getItem("audiosources")


    const handleClickEl2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClickEl3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleCloseEl2 = () => {
        setAnchorEl2(null);
    };
    const handleCloseEl3 = () => {
        setAnchorEl3(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const navigate = useNavigate()

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'simple-popover2' : undefined;
    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'simple-popover3' : undefined;

    const getAudioDuration = async (audioSrc) => {
        try {
            const audio = new Audio(audioSrc);
            await audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                const durationInSeconds = audio.duration;
                const durationInMinutes = (durationInSeconds / 60).toFixed(2);
                setAudioDurations((prev) => ({
                    ...prev,
                    [audioSrc]: durationInMinutes,
                }));
            });
        } catch (error) {
            console.error(error);
            setAudioDurations((prev) => ({
                ...prev,
                [audioSrc]: 0, // Default duration or handle error accordingly
            }));
        }
    };

    useEffect(() => {
        if (music.length > 0) {
            music.forEach((row) => {
                getAudioDuration(row?.audio);
            });

        }
    }, [music]);

    const tabContent = ["All", "original", "repost"]

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleAdd = async (data) => {

        try {

            const create_data = {
                playlistId: data,
                songId: song_id
            }


            await Axios.post("/users/edit_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()
                        handleModelClose()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }


    }
    useEffect(() => {
        getMusic()
        getplayLists()
    }, [])

    useEffect(() => {
        getMusic()
    }, [name])

    useEffect(() => {
        getMusic()
    }, [value])

    const getMusic = async () => {
        try {


            const datas = await Axios.post("/users/get_user_music", {
                status: tabContent[value],
                name: name
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        setMusic(res?.data?.result)
                        setCount(res?.data?.count)

                    }
                    else {
                        //toast.error(err?.response?.data?.message)
                        setMusic([])
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const setAudio = (data) => {
        localStorage.setItem("audiosource", data.audio)
        localStorage.setItem("songName", data.song_name)
        localStorage.setItem("Image", data.image)
        localStorage.setItem("current", 0)
        localStorage.setItem("isPause", false)
        setAudiosource(data.audio)

    }

    const createMusic = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_history", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //   toast.success(res?.data?.message)
                        getMusic()
                        // 
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editSong = async (data) => {
        try {

            const create_data = {
                songId: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_song_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // 
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editAlbums = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_album_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // 
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const editPlaylists = async (data) => {
        try {

            const create_data = {
                album_id: data.id,
                status: data.status
            }


            await Axios.post("/users/edit_playlist_details", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()
                        // 
                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addLibrary = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addFavorite = async (data) => {
        try {

            const create_data = {
                song_id: data
            }


            await Axios.post("/users/add_to_favourite", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addalbumFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_fav_album", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        //toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const addplayFavorite = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_fav_playlist", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        // toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }
    const [send, setSend] = useState()
    const sendTracks = (data) => {
        handleOpen2()
        setSend(data)
    }
    const addalbumLibrary = async (data) => {
        try {

            const create_data = {
                album_id: data
            }


            await Axios.post("/users/add_album_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    const addplayLibrary = async (data) => {
        try {

            const create_data = {
                play_id: data
            }


            await Axios.post("/users/add_playlist_to_library", create_data, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {

                    if (res?.data?.success) {
                        toast.success(res?.data?.message)
                        getMusic()

                    }
                    else {
                        toast.error(res?.data?.message)

                    }
                }).catch((err) => {
                    // console.log(err, "err")
                    toast.error(err?.response?.data?.message)

                })

        } catch (error) {
            console.log(error, "error");
        }

    }

    useEffect(() => {
        getplayLists()
    }, [playname])

    const getplayLists = async () => {
        try {


            const datas = await Axios.post("/users/get_my_playlist", {
                playname: playname
            }, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {

                        setPlay(res?.data?.result)


                    }
                    else {
                        setPlay([])
                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }
    const addPlay = (data) => {

        // setsongid(data)
        handleOpen()
        handleCloseEl2()
        handleClose()
    }



    return (
        <div className='feed'>
            <ToastContainer

                autoClose={3000}
                position="top-right"

            />
            <Box sx={{ display: 'flex' }}>
                <HeaderNew name={name} setName={setName} />


                <Box component="main"
                    className="feed-bg"
                    sx={{ flexGrow: 1, py: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: '50px' }}>

                    <div className='feed-head'>

                        <div className='feeds'>
                            <div className="back-svg-div">
                                <ArrowBackIcon className="back-svg" onClick={(() => { navigate(-1) })} />
                            </div>
                            <h2> Your Feed</h2>
                        </div>



                        <div className='feed-tab'>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="All Posts" className='active-feed' {...a11yProps(0)} />
                                <Tab label="Original Posts" className='active-feed' {...a11yProps(1)} />
                                <Tab label="Reposts" className='active-feed' {...a11yProps(2)} />
                            </Tabs>


                        </div>
                    </div>
                    <Container maxWidth="md">
                        <Grid container spacing={0}>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className='tab-cnt' style={(audiosource || audios) ? { paddingBottom: "150px" } : {}}>
                                    <CustomTabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            {music.length > 0 ? <>{music && music.map((row, index) => {
                                                return <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>
                                                        {row.type !== "" ? <div className='feed-music-main-div' >
                                                            <div className='feed-music-box' >
                                                                <div className='music-detail'>
                                                                    <div className='album-img'>
                                                                        {row?.type === "Track" ? <img src={row?.image} alt={Album} /> : <></>}

                                                                        {row?.type === "Album" ? <>
                                                                            {row?.AlbumDetails?.image ? <img src={row?.AlbumDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}

                                                                        {row?.type === "Playlist" ? <>
                                                                            {row?.PlaylistDetails?.image ? <img src={row?.PlaylistDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}
                                                                    </div>

                                                                    <div className='album-detail'>
                                                                        <div className='music-time'>
                                                                            <div className='time-music'>
                                                                                <div className='music-name'>

                                                                                    {row?.type} {row?.type === "Track" && row?.nft_type === "per" ? <><VerifiedIcon className='premium' /></> : <></>}



                                                                                    {row?.type === "Album" ? <div onClick={((e) => { navigate(`/albumpage/${row?.AlbumDetails?._id}`, { state: { row } }) })} >{row?.AlbumDetails?.album_name}</div> : <></>}
                                                                                    {row?.type === "Track" ? <div onClick={((e) => { navigate(`/trackpage/${row._id}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> : <></>}
                                                                                    {row?.type === "Playlist" ? <div onClick={((e) => { navigate(`/showplaylist/${row.PlaylistDetails?._id}`, { state: { row } }) })} >{row?.PlaylistDetails?.playlist}</div> : <></>}


                                                                                    {/* <div onClick={((e) => { navigate(`${row?.SongDetails.length > 0 ? '/albumpage' : '/trackpage'}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> */}
                                                                                </div>
                                                                                <div className='link-badge'>
                                                                                    <Link to='' onClick={((e) => { navigate(`/artistpage/${row.createUser?._id}`) })}>{row?.createUser?.name}</Link>


                                                                                    {/* {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.SongDetails[0]?.pre_audio) }} />
                                                                                            : <img src={Mbadge} alt={Mbadge} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ?
                                                                                        <>
                                                                                            {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>
                                                                                        : <></>}
                                                                                    {row?.type === "Playlist" ?

                                                                                        <>
                                                                                            {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.playlistSongDetails[0]?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>

                                                                                        : <></>} */}


                                                                                    {/* <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }}/> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <>{audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>}
                                                                                </> : <></>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='loop-times'>
                                                                            <div className='loop-part'>
                                                                                <div className='company-logo'>
                                                                                    {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined && row?.SongDetails[0]?.nft_type === "nor" || row?.SongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.SongDetails[0]); createMusic(row?.SongDetails[0]?._id); setAudiosource(row?.SongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ? <>
                                                                                        {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { setAudio(row); createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Playlist" ? <>
                                                                                        {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined && row?.playlistSongDetails[0]?.nft_type === "nor" || row?.playlistSongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.playlistSongDetails[0]); createMusic(row?.playlistSongDetails[0]?._id); setAudiosource(row?.playlistSongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}




                                                                                    {/* <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} /> */}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {/* <p> <RepeatIcon /></p> */}
                                                                                    {row?.type === "Track" ? <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <RepeatIcon className='repeat' />{row?.AlbumDetails?.reposts?.length ? row?.AlbumDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <RepeatIcon className='repeat' />{row?.PlaylistDetails?.reposts?.length ? row?.PlaylistDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {row?.type === "Track" ? <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <FavoriteIcon className='unfav' />{row?.AlbumDetails?.fav_count ? row?.AlbumDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <FavoriteIcon className='unfav' />{row?.PlaylistDetails?.fav_count ? row?.PlaylistDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {/* <p> <FavoriteIcon />{row?.fav_count ? row?.fav_count : 0} Favorite</p> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <p>{row?.players?.length ? row?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Album" ? <p>{row?.AlbumDetails?.players?.length ? row?.AlbumDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Playlist" ? <p>{row?.PlaylistDetails?.players?.length ? row?.PlaylistDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {/* <p>{row?.plays ? row?.plays : 0} Plays</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='like-dot'>
                                                                            <div className='share-btn'>
                                                                                {row?.type === "Track" ? <>{row?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>} </> : <></>}
                                                                                {row?.type === "Album" ? <>{row?.AlbumDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}
                                                                                {row?.type === "Playlist" ? <>{row?.PlaylistDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}



                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip>}

                                                                                </> : <></>}

                                                                                {/* {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    <div onClick={() => { sendTracks(row?._id) }}>
                                                                                        <ShortcutIcon />
                                                                                    </div>
                                                                                    <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${send}`} />
                                                                                    {/* <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`}/> */}
                                                                                </> : <></>}

                                                                                {/* {row?.type === "Album" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?.AlbumDetails?._id}`}/>
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`}/>
                                                                                </> : <></>} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addLibrary(row?._id) }} /></Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFound === false && row?.AlbumDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addalbumLibrary(row?.AlbumDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFound === false && row?.PlaylistDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addplayLibrary(row?.PlaylistDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}



                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.isMine === true && row.type === "Track" ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>}
                                                                                {row?.type === "Track" ? <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} /> : <></>}

                                                                                <Popover
                                                                                    id={id}
                                                                                    open={open}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                    className='playlist-popover'
                                                                                >
                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlist</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                </Popover>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {row?.type === "Album" ? <>
                                                                {row?.SongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Album
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.SongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Album" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl2(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                <Popover
                                                                                                                    id={id2}
                                                                                                                    open={open2}
                                                                                                                    anchorEl={anchorEl2}
                                                                                                                    onClose={handleCloseEl2}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(obj._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}
                                                            {row?.type === "Playlist" ? <>

                                                                {row?.playlistSongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Playlist
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.playlistSongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Playlist" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl3(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                {/* <MoreHorizIcon style={{"cursor":"pointer"}} onClick={handleClickEl3} className='feed-album-list-info' /> */}
                                                                                                                <Popover
                                                                                                                    id={id3}
                                                                                                                    open={open3}
                                                                                                                    anchorEl={anchorEl3}
                                                                                                                    onClose={handleCloseEl3}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}


                                                        </div> : <></>}

                                                    </Grid>
                                                </>
                                            })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='feed-music-box' id='no-data'>
                                                    <div className='music-detail'>


                                                        <div className='album-detail'>

                                                            <div className='no-music'>
                                                                <div className='time-music'>
                                                                    <div className='music-name'>
                                                                        <h4>No Data Found</h4>
                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>}
                                        </Grid>
                                    </CustomTabPanel >

                                    <CustomTabPanel value={value} index={1}>
                                        <Grid container spacing={2}>
                                            {music.length > 0 ? <>{music && music.map((row, index) => {
                                                return <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>
                                                        {row.type !== "" ? <div className='feed-music-main-div' >
                                                            <div className='feed-music-box' >
                                                                <div className='music-detail'>
                                                                    <div className='album-img'>
                                                                        {row?.type === "Track" ? <img src={row?.image} alt={Album} /> : <></>}

                                                                        {row?.type === "Album" ? <>
                                                                            {row?.AlbumDetails?.image ? <img src={row?.AlbumDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}

                                                                        {row?.type === "Playlist" ? <>
                                                                            {row?.PlaylistDetails?.image ? <img src={row?.PlaylistDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}
                                                                    </div>

                                                                    <div className='album-detail'>
                                                                        <div className='music-time'>
                                                                            <div className='time-music'>
                                                                                <div className='music-name'>

                                                                                    {row?.type} {row?.type === "Track" && row?.nft_type === "per" ? <><VerifiedIcon className='premium' /></> : <></>}



                                                                                    {row?.type === "Album" ? <div onClick={((e) => { navigate(`/albumpage/${row?.AlbumDetails?._id}`, { state: { row } }) })} >{row?.AlbumDetails?.album_name}</div> : <></>}
                                                                                    {row?.type === "Track" ? <div onClick={((e) => { navigate(`/trackpage/${row._id}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> : <></>}
                                                                                    {row?.type === "Playlist" ? <div onClick={((e) => { navigate(`/showplaylist/${row.PlaylistDetails?._id}`, { state: { row } }) })} >{row?.PlaylistDetails?.playlist}</div> : <></>}


                                                                                    {/* <div onClick={((e) => { navigate(`${row?.SongDetails.length > 0 ? '/albumpage' : '/trackpage'}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> */}
                                                                                </div>
                                                                                <div className='link-badge'>
                                                                                    <Link to='' onClick={((e) => { navigate(`/artistpage/${row.createUser?._id}`) })}>{row?.createUser?.name}</Link>


                                                                                    {/* {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.SongDetails[0]?.pre_audio) }} />
                                                                                            : <img src={Mbadge} alt={Mbadge} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ?
                                                                                        <>
                                                                                            {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>
                                                                                        : <></>}
                                                                                    {row?.type === "Playlist" ?

                                                                                        <>
                                                                                            {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.playlistSongDetails[0]?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>

                                                                                        : <></>} */}


                                                                                    {/* <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }}/> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <>{audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>}
                                                                                </> : <></>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='loop-times'>
                                                                            <div className='loop-part'>
                                                                                <div className='company-logo'>
                                                                                    {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined && row?.SongDetails[0]?.nft_type === "nor" || row?.SongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.SongDetails[0]); createMusic(row?.SongDetails[0]?._id); setAudiosource(row?.SongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ? <>
                                                                                        {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { setAudio(row); createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Playlist" ? <>
                                                                                        {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined && row?.playlistSongDetails[0]?.nft_type === "nor" || row?.playlistSongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.playlistSongDetails[0]); createMusic(row?.playlistSongDetails[0]?._id); setAudiosource(row?.playlistSongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}




                                                                                    {/* <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} /> */}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {/* <p> <RepeatIcon /></p> */}
                                                                                    {row?.type === "Track" ? <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <RepeatIcon className='repeat' />{row?.AlbumDetails?.reposts?.length ? row?.AlbumDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <RepeatIcon className='repeat' />{row?.PlaylistDetails?.reposts?.length ? row?.PlaylistDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {row?.type === "Track" ? <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <FavoriteIcon className='unfav' />{row?.AlbumDetails?.fav_count ? row?.AlbumDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <FavoriteIcon className='unfav' />{row?.PlaylistDetails?.fav_count ? row?.PlaylistDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {/* <p> <FavoriteIcon />{row?.fav_count ? row?.fav_count : 0} Favorite</p> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <p>{row?.players?.length ? row?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Album" ? <p>{row?.AlbumDetails?.players?.length ? row?.AlbumDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Playlist" ? <p>{row?.PlaylistDetails?.players?.length ? row?.PlaylistDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {/* <p>{row?.plays ? row?.plays : 0} Plays</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='like-dot'>
                                                                            <div className='share-btn'>
                                                                                {row?.type === "Track" ? <>{row?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>} </> : <></>}
                                                                                {row?.type === "Album" ? <>{row?.AlbumDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}
                                                                                {row?.type === "Playlist" ? <>{row?.PlaylistDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}



                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip>}

                                                                                </> : <></>}

                                                                                {/* {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`} />
                                                                                </> : <></>}

                                                                                {/* {row?.type === "Album" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?.AlbumDetails?._id}`}/>
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`}/>
                                                                                </> : <></>} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addLibrary(row?._id) }} /></Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFound === false && row?.AlbumDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addalbumLibrary(row?.AlbumDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFound === false && row?.PlaylistDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addplayLibrary(row?.PlaylistDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}



                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.isMine === true && row.type === "Track" ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>}
                                                                                {row?.type === "Track" ? <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} /> : <></>}

                                                                                <Popover
                                                                                    id={id}
                                                                                    open={open}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                    className='playlist-popover'
                                                                                >
                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlists</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                </Popover>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {row?.type === "Album" ? <>
                                                                {row?.SongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Album
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.SongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Album" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl2(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                <Popover
                                                                                                                    id={id2}
                                                                                                                    open={open2}
                                                                                                                    anchorEl={anchorEl2}
                                                                                                                    onClose={handleCloseEl2}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(obj._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}
                                                            {row?.type === "Playlist" ? <>

                                                                {row?.playlistSongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Playlist
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.playlistSongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Playlist" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl3(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                {/* <MoreHorizIcon style={{"cursor":"pointer"}} onClick={handleClickEl3} className='feed-album-list-info' /> */}
                                                                                                                <Popover
                                                                                                                    id={id3}
                                                                                                                    open={open3}
                                                                                                                    anchorEl={anchorEl3}
                                                                                                                    onClose={handleCloseEl3}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}


                                                        </div> : <></>}

                                                    </Grid>
                                                </>
                                            })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='feed-music-box'>
                                                    <div className='music-detail'>


                                                        <div className='album-detail'>

                                                            <div className='no-music'>
                                                                <div className='time-music'>
                                                                    <div className='music-name'>
                                                                        <h4>No Data Found</h4>
                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>}
                                        </Grid>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={2}>
                                        <Grid container spacing={2}>
                                            {music.length > 0 ? <>{music && music.map((row, index) => {
                                                return <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id}>
                                                        {row.type !== "" ? <div className='feed-music-main-div' >
                                                            <div className='feed-music-box' >
                                                                <div className='music-detail'>
                                                                    <div className='album-img'>
                                                                        {row?.type === "Track" ? <img src={row?.image} alt={Album} /> : <></>}

                                                                        {row?.type === "Album" ? <>
                                                                            {row?.AlbumDetails?.image ? <img src={row?.AlbumDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}

                                                                        {row?.type === "Playlist" ? <>
                                                                            {row?.PlaylistDetails?.image ? <img src={row?.PlaylistDetails?.image} alt={Album} /> : <><img src={ava1} alt={Album} /></>}
                                                                        </> : <></>}
                                                                    </div>

                                                                    <div className='album-detail'>
                                                                        <div className='music-time'>
                                                                            <div className='time-music'>
                                                                                <div className='music-name'>

                                                                                    {row?.type} {row?.type === "Track" && row?.nft_type === "per" ? <><VerifiedIcon className='premium' /></> : <></>}



                                                                                    {row?.type === "Album" ? <div onClick={((e) => { navigate(`/albumpage/${row?.AlbumDetails?._id}`, { state: { row } }) })} >{row?.AlbumDetails?.album_name}</div> : <></>}
                                                                                    {row?.type === "Track" ? <div onClick={((e) => { navigate(`/trackpage/${row._id}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> : <></>}
                                                                                    {row?.type === "Playlist" ? <div onClick={((e) => { navigate(`/showplaylist/${row.PlaylistDetails?._id}`, { state: { row } }) })} >{row?.PlaylistDetails?.playlist}</div> : <></>}


                                                                                    {/* <div onClick={((e) => { navigate(`${row?.SongDetails.length > 0 ? '/albumpage' : '/trackpage'}`, { state: { row } }) })} >{row?.song_name}-{row?.ISRC}</div> */}
                                                                                </div>
                                                                                <div className='link-badge'>
                                                                                    <Link to='' onClick={((e) => { navigate(`/artistpage/${row.createUser?._id}`) })}>{row?.createUser?.name}</Link>


                                                                                    {/* {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.SongDetails[0]?.pre_audio) }} />
                                                                                            : <img src={Mbadge} alt={Mbadge} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ?
                                                                                        <>
                                                                                            {audioDurations[row?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>
                                                                                        : <></>}
                                                                                    {row?.type === "Playlist" ?

                                                                                        <>
                                                                                            {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined ? <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.playlistSongDetails[0]?.pre_audio) }} />
                                                                                                : <img src={Mbadge} alt={Mbadge} />}
                                                                                        </>

                                                                                        : <></>} */}


                                                                                    {/* <img src={Mbadge} alt={Mbadge} onClick={() => { setAudiosource(row?.pre_audio) }}/> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <>{audioDurations[row?.audio] !== undefined ? <span>{audioDurations[row?.audio]}</span> : <span>-</span>}
                                                                                </> : <></>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='loop-times'>
                                                                            <div className='loop-part'>
                                                                                <div className='company-logo'>
                                                                                    {row?.type === "Album" ? <>
                                                                                        {audioDurations[row?.SongDetails[0]?.audio] !== undefined && row?.SongDetails[0]?.nft_type === "nor" || row?.SongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.SongDetails[0]); createMusic(row?.SongDetails[0]?._id); setAudiosource(row?.SongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Track" ? <>
                                                                                        {audioDurations[row?.audio] !== undefined && row?.nft_type === "nor" || row?.isMine === true ? <img onClick={() => { setAudio(row); createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}
                                                                                    {row?.type === "Playlist" ? <>
                                                                                        {audioDurations[row?.playlistSongDetails[0]?.audio] !== undefined && row?.playlistSongDetails[0]?.nft_type === "nor" || row?.playlistSongDetails[0]?.isMine === true ? <img onClick={() => { setAudio(row?.playlistSongDetails[0]); createMusic(row?.playlistSongDetails[0]?._id); setAudiosource(row?.playlistSongDetails[0]?.audio) }} src={Companylogo} alt={Companylogo} />
                                                                                            : <img src={Companylogo} alt={Companylogo} />}
                                                                                    </> : <></>}




                                                                                    {/* <img onClick={() => { createMusic(row?._id); setAudiosource(row?.audio) }} src={Companylogo} alt={Companylogo} /> */}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {/* <p> <RepeatIcon /></p> */}
                                                                                    {row?.type === "Track" ? <p> <RepeatIcon className='repeat' />{row?.reposts?.length ? row?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <RepeatIcon className='repeat' />{row?.AlbumDetails?.reposts?.length ? row?.AlbumDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <RepeatIcon className='repeat' />{row?.PlaylistDetails?.reposts?.length ? row?.PlaylistDetails?.reposts?.length : 0} reposts</p> : <></>}
                                                                                </div>
                                                                                <div className='repost-loop'>
                                                                                    {row?.type === "Track" ? <p> <FavoriteIcon className='unfav' />{row?.fav_count ? row?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Album" ? <p> <FavoriteIcon className='unfav' />{row?.AlbumDetails?.fav_count ? row?.AlbumDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {row?.type === "Playlist" ? <p> <FavoriteIcon className='unfav' />{row?.PlaylistDetails?.fav_count ? row?.PlaylistDetails?.fav_count : 0} Favorite</p> : <></>}
                                                                                    {/* <p> <FavoriteIcon />{row?.fav_count ? row?.fav_count : 0} Favorite</p> */}
                                                                                </div>
                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.type === "Track" ? <p>{row?.players?.length ? row?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Album" ? <p>{row?.AlbumDetails?.players?.length ? row?.AlbumDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {row?.type === "Playlist" ? <p>{row?.PlaylistDetails?.players?.length ? row?.PlaylistDetails?.players?.length : 0} Plays</p> : <></>}
                                                                                {/* <p>{row?.plays ? row?.plays : 0} Plays</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='like-dot'>
                                                                            <div className='share-btn'>
                                                                                {row?.type === "Track" ? <>{row?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editSong({ id: row?._id, status: "repost" }) }} />
                                                                                    </Tooltip>} </> : <></>}
                                                                                {row?.type === "Album" ? <>{row?.AlbumDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editAlbums({ id: row?.AlbumDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}
                                                                                {row?.type === "Playlist" ? <>{row?.PlaylistDetails?.isRepost === true ?
                                                                                    <Tooltip title="Unrepost" placement="top">
                                                                                        <RepeatIcon className='repeat' onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip> :
                                                                                    <Tooltip title="Repost" placement="top">
                                                                                        <RepeatIcon onClick={() => { editPlaylists({ id: row?.PlaylistDetails?._id, status: "repost" }) }} />
                                                                                    </Tooltip>}</> : <></>}



                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addalbumFavorite(row?.AlbumDetails?._id) }} />
                                                                                        </Tooltip>}
                                                                                </> : <></>}
                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFav === true ?
                                                                                        <Tooltip title="Unfavourite" placement="top">
                                                                                            <FavoriteIcon className="unfav" onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Favourite" placement="top">
                                                                                            <FavoriteIcon onClick={() => { addplayFavorite(row?.PlaylistDetails?._id) }} />
                                                                                        </Tooltip>}

                                                                                </> : <></>}

                                                                                {/* {row?.userFav === true ? <FavoriteIcon className="unfav" onClick={() => { addFavorite(row?._id) }} /> : <FavoriteIcon onClick={() => { addFavorite(row?._id) }} />} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`} />
                                                                                </> : <></>}

                                                                                {/* {row?.type === "Album" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?.AlbumDetails?._id}`}/>
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                <SendTrack handleModelClose2={handleModelClose2} handleOpen2={handleOpen2} openModel2={openModel2} id={`/trackpage/${row?._id}`}/>
                                                                                </> : <></>} */}

                                                                                {row?.type === "Track" ? <>
                                                                                    {row?.nftclaim !== 0 && row?.userFound === false && row?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addLibrary(row?._id) }} /></Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Album" ? <>
                                                                                    {row?.AlbumDetails?.userFound === false && row?.AlbumDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addalbumLibrary(row?.AlbumDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}

                                                                                {row?.type === "Playlist" ? <>
                                                                                    {row?.PlaylistDetails?.userFound === false && row?.PlaylistDetails?.isMine === false ? <Tooltip title="Add to Library" placement="top"><DownloadIcon onClick={() => { addplayLibrary(row?.PlaylistDetails?._id) }} /> </Tooltip> : <></>}
                                                                                </> : <></>}



                                                                            </div>
                                                                            <div className='music-timing'>
                                                                                {row?.isMine === true && row.type === "Track" ? <Switch {...label} checked={row?.opentoeveryone} onClick={() => { editSong({ id: row?._id, status: "open" }) }} /> : <></>}
                                                                                {row?.type === "Track" ? <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClick(e); setsongid(row?._id); setRows(row) }} /> : <></>}

                                                                                <Popover
                                                                                    id={id}
                                                                                    open={open}
                                                                                    anchorEl={anchorEl}
                                                                                    onClose={handleClose}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'left',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'right',
                                                                                    }}
                                                                                    className='playlist-popover'
                                                                                >
                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlists</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`, { state: { rows } }) })}>Visit Track Page</div>
                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                </Popover>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>

                                                            </div>

                                                            {row?.type === "Album" ? <>
                                                                {row?.SongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Album
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.SongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Album" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl2(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                <Popover
                                                                                                                    id={id2}
                                                                                                                    open={open2}
                                                                                                                    anchorEl={anchorEl2}
                                                                                                                    onClose={handleCloseEl2}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(obj._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}
                                                            {row?.type === "Playlist" ? <>

                                                                {row?.playlistSongDetails?.length > 0 ? <div >
                                                                    <Accordion className='accord-feed-page'>
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header-feed-album-list"
                                                                        >
                                                                            View All in Playlist
                                                                        </AccordionSummary>
                                                                        <div className='table-div-feed'>
                                                                            <TableContainer >
                                                                                <Table aria-label="simple table">
                                                                                    <TableBody>



                                                                                        {
                                                                                            row?.playlistSongDetails.map((obj, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TableRow>
                                                                                                            <TableCell >
                                                                                                                <div className='inside-div'>

                                                                                                                    <div className='more-tracks-inner-div'>
                                                                                                                        <div className='music-play-main-div'>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <img src={s6} className='music-play' onClick={() => { createMusic(obj?._id); setAudiosource(obj?.audio) }} />
                                                                                                                                : <img src={s6} className='music-play' />}

                                                                                                                            <span>#{index + 1}</span>{obj?.song_name}-{obj?.ISRC}
                                                                                                                        </div>
                                                                                                                        <div>

                                                                                                                            {audioDurations[obj?.audio] !== undefined ? <span>{audioDurations[obj?.audio]}</span> : <span>-</span>}

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                            </TableCell>
                                                                                                            <TableCell>
                                                                                                                {row?.type === "Playlist" ?
                                                                                                                    <MoreHorizIcon style={{ "cursor": "pointer" }} onClick={(e) => { handleClickEl3(e); setsongid(obj?._id); setRows(obj) }} className='feed-album-list-info' />

                                                                                                                    : <></>}
                                                                                                                {/* <MoreHorizIcon style={{"cursor":"pointer"}} onClick={handleClickEl3} className='feed-album-list-info' /> */}
                                                                                                                <Popover
                                                                                                                    id={id3}
                                                                                                                    open={open3}
                                                                                                                    anchorEl={anchorEl3}
                                                                                                                    onClose={handleCloseEl3}
                                                                                                                    anchorOrigin={{
                                                                                                                        vertical: 'bottom',
                                                                                                                        horizontal: 'left',
                                                                                                                    }}
                                                                                                                    transformOrigin={{
                                                                                                                        vertical: 'top',
                                                                                                                        horizontal: 'right',
                                                                                                                    }}
                                                                                                                    className='playlist-popover'
                                                                                                                >
                                                                                                                    <div className='playlist-more-div' onClick={() => addPlay(row._id)}>Add to Playlist</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/trackpage/${rows._id}`) })}>Visit Track Page</div>
                                                                                                                    <div className='playlist-more-div' onClick={((e) => { navigate(`/artistpage/${rows.createUser?._id}`) })} >Visit Artist Page</div>
                                                                                                                </Popover>

                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    </>

                                                                                                )
                                                                                            })

                                                                                        }



                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </div>

                                                                    </Accordion>
                                                                </div> : <></>}
                                                            </> : <></>}


                                                        </div> : <></>}

                                                    </Grid>
                                                </>
                                            })}</> : <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='feed-music-box' id='no-data'>
                                                    <div className='music-detail'>


                                                        <div className='album-detail'>

                                                            <div className='no-music'>
                                                                <div className='time-music'>
                                                                    <div className='music-name'>
                                                                        <h4>No Data Found</h4>
                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>}
                                        </Grid>
                                    </CustomTabPanel>
                                </div>
                            </Grid>
                        </Grid>
                        <div>
                            {/* <Track /> */}
                            {/* {audiosource ? <Track audiosource={audiosource} /> : <></>} */}
                            {audios !== null && audios.length > 0 ? <Tracks audiosources={audios} /> : <></>}

                            {audiosource ? <Track audiosource={audiosource} /> : <>{audio !== null ? <Track audiosource={audio} /> : <></>} </>}


                        </div>
                    </Container>
                </Box>
            </Box>
            {/* <Modal
                open={openModel}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={0}>
                        <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                            <div>
                                <CloseIcon onClick={handleModelClose} />
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                            <div className='edit-playlist-title'>
                                <QueueMusicIcon />
                                Add to Playlist
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='imgae-div-edit-grid'>
                            <div className="profile-img-outer2 minnft" style={{ "width": "100%" }}>
                                <div className="image-upload-container">
                                    <div className="box-decoration">


                                        <Card className='create-playlist-card card-setting' onClick={((e) => { navigate('/createplaylist') })}>

                                            <CardContent className='album-lib-typo' >
                                                <Typography gutterBottom className='library-album-card-title2' component="div">
                                                    <AddIcon />
                                                    <div>
                                                        Create New Playlist
                                                    </div>
                                                </Typography>


                                            </CardContent>

                                        </Card>
                                        {play.length > 0 ? <>{play && play.map((row, index) => {
                                            return <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} >
                                                    <Card className='create-playlist-card card-setting' onClick={() => { handleAdd(row?._id) }}>

                                                        <CardContent className='album-lib-typo' >
                                                            <Typography gutterBottom className='library-album-card-title2' component="div">

                                                                <div>
                                                                    {row?._id} {row?.playlist}
                                                                </div>
                                                            </Typography>


                                                        </CardContent>

                                                    </Card>
                                                </Grid>
                                            </>
                                        })}</> :
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <div className='feed-music-box'>
                                                    <div className='music-detail'>


                                                        <div className='album-detail'>

                                                            <div className='no-music'>
                                                                <div className='time-music'>
                                                                    <div className='music-name'>
                                                                        <h4>No Data Found</h4>
                                                                    </div>

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        }


                                    </div>
                                </div>
                            </div>
                        </Grid>


                    </Grid>
                </Box>
            </Modal> */}
            <Modal
                keepMounted
                open={openModel}
                onClose={handleModelClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style2}>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', color: 'var(--clr-font)' }} >

                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ borderBottom: '1px solid var(--clr-search-border)', display: 'flex' }}>
                            <Grid xs={1} sm={1} md={1} lg={1} xl={1} >
                                <div>
                                    <CloseIcon onClick={handleModelClose} />
                                </div>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={11} xl={11}>
                                <div className='edit-playlist-title'>
                                    {/* <QueueMusicIcon /> */}
                                    Add to Playlist
                                </div>

                            </Grid>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div id="search-wrap2">

                                <InputBase
                                    id='search-input'
                                    sx={{ ml: 1, flex: 1 }}
                                    value={playname ? playname : ''}
                                    onChange={(e) => setPlayname(e.target.value)}
                                    placeholder="Search your music"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                    <SearchIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='new-playlist' onClick={((e) => { navigate('/createplaylist', { state: { song_id } }) })}>
                                <AddCircleIcon />
                                <div>
                                    New Playlist
                                </div>
                            </div>
                        </Grid>
                        {play.length > 0 ? (<>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='grid-height' >
                                {play && play.map((row, ind) => {
                                    return (
                                        <Grid xs={12} sm={12} md={12} lg={12} xl={12} key={row?._id} onClick={() => { handleAdd(row?._id) }}>
                                            <div className='new-playlist created'>
                                                <div className='d-flex'>
                                                    {row?.image ? <img src={row?.image} alt='bg2' /> : <img src={Companylogo} alt='bg2' />}
                                                    {/* <img src={row?.image} alt='img' /> */}
                                                </div>
                                                <div>
                                                    {row?.playlist}
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </>) : (<>
                            <div >
                                No Data Found
                            </div>

                        </>)}



                    </Grid>

                </Box>
            </Modal>

        </div >
    )
}

export default Feed

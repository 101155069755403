import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import logo from '../../img/dster-webp/logo.webp'
import add from '../../img/dster-webp/add-svg.webp'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ava from "../../img/dster-webp/ava1.webp";
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useRef } from "react";
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import Axios from "../../Axios";
import DarkMode from "../DarkMode/DarkMode";
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import PaidIcon from '@mui/icons-material/Paid';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SettingsIcon from '@mui/icons-material/Settings';
import usdt from "../../img/dster-webp/usdt.webp"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import minilogo from '../../img/dster-webp/minilogo.webp'

const drawerWidth = 280;



;



function Header2(props) {
    const [signin, setSignin] = React.useState(false)
    const [welcome, setWelcome] = React.useState(false)

    const navigate = useNavigate();
    //  let socket = io.connect(consts.SocketUrl);
    const handleSignin = () => {
        setSignin(true)
    }
    const isMount = useRef(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);

    const open2 = Boolean(anchorE2);
    const id2 = open2 ? 'simple-popover' : undefined;


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClickMore = (event) => {
        setAnchorEl(event?.currentTarget);
        //    console.log(anchorEl2)
    };


    const handleClickMessage = (event) => {
        setAnchorE2(event?.currentTarget);
        //    console.log(anchorEl2)
    };

    const handleCloseMessage = () => {
        setAnchorE2(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        if (localStorage.getItem("iphephile") != null) {
            localStorage.clear();
            toast.success('Logged out Successfully', {
                duration: 500, // Set the duration for which the toast is visible
            })

            setTimeout(() => {

                navigate('/')

            }, 1000)
        }
        else {
            navigate('/')
        }

    }

    const [uploadtrack, setUploadtrack] = React.useState(null)


    const handleUploadTrack = () => {

        setSignin(true)
        setWelcome(true)
        setUploadtrack(true)
        setTimeout(() => {
            setWelcome(false)
            setUploadtrack(false)

        }, "1500")

    }

    React.useEffect(() => {
        if (uploadtrack === false) {
            navigate('/register')
        }
    }, [uploadtrack])



    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);


    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    const handleinfo = (e) => {
        console.log(e.target.files);
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleClick = (event) => {
        // clickFileInput.current.click();
        navigate('/profile')
    };

    const clickFileInput = useRef(null);


    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };



    const [selectedImage, setSelectedImage] = React.useState(null);
    const [profileData, setProfileData] = useState({});
    const [notify, setNotify] = useState([]);
    const tokens = localStorage.getItem("iphephile");


    useEffect(() => {
        if (!isMount.current && tokens) {

            getProfile();
            getNotification()
            isMount.current = true
        }
        else {
            console.log("Run")
        }
    }, []);


    const getNotification = async () => {
        try {

            console.log("gettinsg")

            const datas = await Axios.post("/notification/get_my_notification", '', {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    console.log(res?.data?.result[0], "resi")
                    if (res?.data?.success) {

                        setNotify(res?.data?.result)


                    }
                    else {

                        setNotify([])

                    }
                }).catch((err) => {
                    // console.log(err,"err")

                })

        } catch (error) {
            // console.log(error,"error");
        }

    }

    const getProfile = async () => {
        try {
            await Axios.get(`/profile/getprofile`, {
                headers: {
                    Authorization: tokens,
                },
            })
                .then((res) => {
                    if (res?.data?.success) {
                        setProfileData(res?.data?.result);
                        // setUpdateName(res?.data?.result?.name);
                    } else {
                        // toast.error("Can't Fetch Detail");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };



    const drawer = (
        <div >
            {/*   <ToastContainer
          
        autoClose={3000}
          position="top-right"
           
        /> */}
            <div className="sidebar-header-div">
                <Link to="/">
                    <div className="header-logo">
                        <img src={logo} alt='logo' />
                    </div>
                </Link>
                <div className="more-svg">
                    <MoreHorizIcon onClick={handleClickMore} />

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        className='playlist-popover'
                    >
                        <div className='playlist-more-div' onClick={(() => { navigate('/mymessage') })} ><InsertCommentIcon />Messages</div>
                        <div className='playlist-more-div' ><PaidIcon />Pay & Earn<span className="more-usdt-img"><img src={usdt} alt='usdt' />$ 0</span></div>
                        <div className='playlist-more-div' ><ShowChartIcon />Artist Dashboard</div>
                        <div className='playlist-more-div' ><EmojiEventsIcon />Rewards</div>
                        <div className='playlist-more-div' ><SettingsIcon />Settings</div>
                    </Popover>
                </div>
                <div className="notification-svg">
                    <NotificationsIcon onClick={(() => { navigate('/notify') })} />
                    <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorE2}
                        onClose={handleCloseMessage}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        className='message-popover'
                    >
                        <div className="msg-pop-inner-main-div">
                            <div className="noftigy-title-fixed">
                                <NotificationsIcon /> Notification
                            </div>
                            <div className="msg-content-mian-div">
                                <div className="msg-content-div">
                                    <div className="new-release">
                                        <AutoAwesomeIcon />  New Release
                                    </div>

                                    <hr />
                                    <div className="msg-avat-content-main-div">
                                        <Avatar alt="Remy Sharp" src={profileData.profilePicture ? profileData.profilePicture : ava} />
                                        <div className="artist-angry-baby">
                                            AngryBaby<span><img src={minilogo} width={15} height={15} />Posted a new track</span>
                                            <div>
                                                LOVERS TOMORROW
                                            </div>
                                        </div>
                                    </div>
                                    <div className="msg-artist-post-time">
                                        2 days Ago
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Popover>
                </div>
            </div>



            <Divider />
            <List sx={{ textAlign: 'center' }}>

                <div className="avatar-cont">
                    <div onClick={handleClick} style={{ cursor: "pointer" }}>
                        <Avatar alt="Remy Sharp" src={profileData.profilePicture ? profileData.profilePicture : ava} sx={{ width: 56, height: 56 }} >
                        </Avatar>
                        <div class="edit">
                            {/* <EditIcon /> */}
                        </div>
                        <input
                            id="image-upload-input"
                            type="file"
                            onChange={handleinfo}

                            ref={clickFileInput}
                            style={{ display: "none" }}
                        />
                    </div>
                    <span className="hav-acc">
                        <div>
                            {profileData?.name ? profileData?.name : <>
                                {profileData?.account?.slice(0, 6)}...{profileData?.account?.slice(-4, profileData?.account?.length)}
                            </>}

                            <Link>
                                <div className="go-violet2">
                                    @{profileData?.name ? <>{profileData?.name?.toLowerCase()}</> : <>
                                        {profileData?.account?.slice(0, 6)}...{profileData?.account?.slice(-4, profileData?.account?.length)}
                                    </>}
                                </div>
                            </Link>
                        </div>

                        <span className="dark-mod-btn" >
                            <DarkMode />
                        </span>
                    </span>

                </div>
            </List>
            <div>

            </div>
            <Divider />
            <List>
                <div className="discover">
                    <div>
                        <NavLink to='/dashboard' className={({ isActive }) => (isActive ? 'active' : '')} >  <h4>DISCOVER</h4></NavLink>
                    </div>
                    <NavLink to='/feed' className={({ isActive }) => (isActive ? 'active' : '')} >Feed</NavLink>

                    {/* <NavLink to='/trending' className={({ isActive }) => (isActive ? 'active' : '')} >Trending</NavLink> */}

                    <NavLink to='/explore' className={({ isActive }) => (isActive ? 'active' : '')} >Explore</NavLink>

                    <div>
                        <NavLink to='/faq' className={({ isActive }) => (isActive ? 'active' : '')} ><h4>Your Music</h4></NavLink>
                    </div>

                    <NavLink to='/library' className={({ isActive }) => (isActive ? 'active' : '')} >Library</NavLink>

                    <NavLink to='/history' className={({ isActive }) => (isActive ? 'active' : '')} >History</NavLink>

                    {/* <div>
                        <h4>Playlists <span className="new-span"> + New </span> </h4>
                    </div>
                    <div>
                        Create your first playlist!
                    </div> */}
                </div>
            </List>



            <div className="sidebar-btn">
                {signin ? (<>
                    {/* <Box sx={{ width: '70%' }}>
                        <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
                    </Box> */}
                    {uploadtrack ? (<>
                        <div className="loading-div">
                            <div className="loading-text">Please wait</div>
                            <svg class="pl" viewBox="0 0 128 128" width="100px" height="40px">
                                <defs>
                                    <linearGradient id="pl-grad" x1="0" y1="0" x2="1" y2="1">
                                        <stop offset="0%" stop-color="#000" />
                                        <stop offset="100%" stop-color="#fff" />
                                    </linearGradient>
                                    <mask id="pl-mask">
                                        <rect x="0" y="0" width="128" height="128" fill="url(#pl-grad)" />
                                    </mask>
                                </defs>
                                <g stroke-linecap="round" stroke-width="8" stroke-dasharray="32 32">
                                    <g stroke="hsl(193,90%,50%)">
                                        <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                                        <line class="pl__line2" x1="19" y1="48" x2="19" y2="80" />
                                        <line class="pl__line3" x1="34" y1="48" x2="34" y2="80" />
                                        <line class="pl__line4" x1="49" y1="48" x2="49" y2="80" />
                                        <line class="pl__line5" x1="64" y1="48" x2="64" y2="80" />
                                        <g transform="rotate(180,79,64)">
                                            <line class="pl__line6" x1="79" y1="48" x2="79" y2="80" />
                                        </g>
                                        <g transform="rotate(180,94,64)">
                                            <line class="pl__line7" x1="94" y1="48" x2="94" y2="80" />
                                        </g>
                                        <g transform="rotate(180,109,64)">
                                            <line class="pl__line8" x1="109" y1="48" x2="109" y2="80" />
                                        </g>
                                        <g transform="rotate(180,124,64)">
                                            <line class="pl__line9" x1="124" y1="48" x2="124" y2="80" />
                                        </g>
                                    </g>
                                    <g stroke="hsl(283,90%,50%)" mask="url(#pl-mask)">
                                        <line class="pl__line1" x1="4" y1="48" x2="4" y2="80" />
                                        <line class="pl__line2" x1="19" y1="48" x2="19" y2="80" />
                                        <line class="pl__line3" x1="34" y1="48" x2="34" y2="80" />
                                        <line class="pl__line4" x1="49" y1="48" x2="49" y2="80" />
                                        <line class="pl__line5" x1="64" y1="48" x2="64" y2="80" />
                                        <g transform="rotate(180,79,64)">
                                            <line class="pl__line6" x1="79" y1="48" x2="79" y2="80" />
                                        </g>
                                        <g transform="rotate(180,94,64)">
                                            <line class="pl__line7" x1="94" y1="48" x2="94" y2="80" />
                                        </g>
                                        <g transform="rotate(180,109,64)">
                                            <line class="pl__line8" x1="109" y1="48" x2="109" y2="80" />
                                        </g>
                                        <g transform="rotate(180,124,64)">
                                            <line class="pl__line9" x1="124" y1="48" x2="124" y2="80" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </>)
                        :
                        (<>
                            <Button className="upload-track"><CloudUploadIcon />UploadFile</Button>
                        </>)
                    }


                </>) :
                    (<>


                        {/* <Link onClick={handleUploadTrack}><img src={add} />Sign-up</Link> */}
                        <Link to='/' onClick={() => { logout() }}><img src={add} />Logout</Link>

                        {/* <Button  ><img src={add}  onClick={alert('kuygbiuf')} />Logout</Button> */}
                    </>)
                }

            </div>
        </div>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;



    return (<>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />


            <Box
                component="nav"
                sx={{

                    '@media (min-width:991.68px)': {
                        width: drawerWidth,
                    },

                    flexShrink: { sm: 0 }
                }}
                aria-label="mailbox folders"
                className="sidebar"
            >
                <AppBar
                    position="fixed"
                    sx={{
                        '@media (min-width:991.68px)': {
                            width: (`calc(100% - ${drawerWidth}px)`),
                        },

                        ml: { md: `${drawerWidth}px` },
                    }}
                    className='bg-appbar2'
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}

                            sx={{
                                mr: 2,

                                '@media (min-width:991.68px)': {
                                    display: 'none',
                                },



                            }}
                        >
                            <MenuIcon className="svg-icon" />
                        </IconButton >

                        <Grid container>
                            <Grid xs={1} sm={1} md={1} lg={1} xl={1}>
                                {/* <div className="back-svg-div">
                                    <ArrowBackIcon className="back-svg" onClick={(() => { navigate(-1) })} />
                                </div> */}

                            </Grid>
                            <Grid xs={11} sm={11} md={11} lg={4} xl={3}>
                                <div id="search-wrap" style={{ "display": "flex" }}>
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" id='search-input'>
                                        <SearchIcon />
                                    </IconButton>
                                    <InputBase
                                        id='search-input'
                                        sx={{ ml: 1, flex: 1 }}
                                        value={props?.name ? props?.name : ''}
                                        onChange={(e) => props?.setName(e.target.value)}
                                        placeholder="Search your music"
                                        inputProps={{ 'aria-label': 'search google maps' }}
                                        className="search-inputs"
                                    />
                                </div>

                            </Grid>
                        </Grid>


                    </Toolbar>
                </AppBar>

                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}

                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '@media (max-width:991.68px)': {
                            display: 'block',
                        },

                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    className="sidebar"
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    className="responsive-drawer"
                    sx={{
                        '@media (min-width:991.68px)': {
                            display: 'block',
                        },

                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },

                    }}
                    open
                >
                    {drawer}
                </Drawer>

            </Box>


        </Box>
    </>);
}

export default Header2;
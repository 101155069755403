import { useState } from 'react';
import Calendar from 'react-calendar';
import './CalendarNew.css'
import 'react-calendar/dist/Calendar.css';
import { useEffect } from 'react';
import dt from '../../img/dster-webp/dt1.webp';
import Backdrop from '@mui/material/Backdrop';






const CalendarNew = (props) => {

  const [value, setValue] = useState([new Date()])
  const [eventDetail, seteventDetail] = useState([])


  useEffect(() => {
    if (props?.todays?.length > 0) {
      setValue(props?.todays)
    }

  }, [props])

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };




  const onChanges = async (v) => {
    seteventDetail(props?.events[new Date(v).toLocaleDateString()])
    handleOpen()

  }

  const getData = (Events) => {
    {
      Events.map((row, obj) => {
        return (
          <p>
            row?._id
          </p>)
      })
    }
  }
  const tileContent = ({ view, date }) => {
    const Events = props?.events[new Date(date).toLocaleDateString()]
    if (Events?.length > 0 && Events?.length === 1) {
      return <p>{Events?.length} Event</p>

    } else if (Events?.length > 0 && Events?.length > 1) {
      return <p> {Events?.length} Events</p>

    }

  };

  const tileClassName = ({ view, date }) => {


    if (props?.events[new Date(date).toLocaleDateString()] && props?.events[new Date(date).toLocaleDateString()][0]?.track_Name !== undefined) {
      return "days";
    }
    else {
      return "days1";
    }

  };

  return (
    <div className='calender-main'>
      <div>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter: 'blur(5px)' }}
          open={open}
          onClick={handleClose}
        >
          {eventDetail && eventDetail?.length > 0 && eventDetail?.map((data, index) => {
            return (
              <div className='calander-evt-pop-div'>
                <div className='calender-backdrop-main'>
                  <div className="today-inner-cont cal" key={index}>
                    <div className="inner-cont2">

                      <div className="inner-cont3 cal">
                        <img src={dt} alt='dt' />
                        <div className="calander-song-genre cal">
                          {data?.track_Name}
                          <div className="country cal">
                            {data?.description}
                          </div>
                          <div className="calender-timing">
                            <div className="calender-date-n-time cal">
                              {data?.EventDate?.split(' ')[0]}
                            </div>
                            <div className="calender-date-n-time cal">
                              {data?.EventDate?.split(' ')[1]}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div onClick={() => { testLive(data?._id); window.localStorage.setItem('Playing', "false") }}>
                    <Button className="join">
                        Join
                    </Button>
                    <div>
                        <Button className="remainder">
                            Remainder
                        </Button>
                    </div>
                </div> */}
                    </div>
                  </div>

                </div>
              </div>

            )
          })}
        </Backdrop>
      </div>




      <Calendar
        onClickDay={(e) => { onChanges(e) }}
        tileContent={tileContent}
        tileClassName={tileClassName}
        value={value} defaultActiveStartDate={new Date()} goToRangeStartOnSelect={false} showNeighboringMonth={false} />

    </div>
  )
}

export default CalendarNew

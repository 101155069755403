import Grid from "@mui/material/Grid";
// import "../Music-player/Player.css";
import "./Fanzone.css"
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import Box from "@mui/material/Box";
import Footer from "../Footer/Footer";
import subimg from "../../img/dster-webp/fanzone-subimg.webp";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  cardbg: {
    borderRadius: "12px !important",
    background: "rgba(255, 255, 255, 0.10)",
    boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
    backdropFilter: "blur(37.5px)",
    padding: "0 !important",

  },

  pwrback: {
    borderRadius: "12px !important",
    border: "1px solid rgba(255, 255, 255, 0.20)",
    background: "#000000 !important",
  },

  learn: {
    borderRadius: "12px !important",
    background: 'transparent !important',
    boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
  },

  containWidth: {
    maxWidth: '1440px',
    margin: 'auto',
    background: 'none !important',
    padding: '0 !important',
    boxShadow: 'none !important',

    "@media (max-width: 1199.98px)": {
      padding: '20px !important',
    },
  }

});


function Fanzone() {
  const classes = useStyles();
  return (
    <>
      <div className="fanzone" sx={{ bgcolor: '#000' }}>
        <div className="playerBanner"></div>

        <Grid container spacing={0} className="music-player-grid">
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.containWidth}>
              <Header />
            </Item>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="music-player-banner-grid"
          >
            <Item className={classes.containWidth}>
              <div className="fanzone-body">
                <h1 className="player-platform">
                  WE Are Coming, Stay Updated!
                </h1>
                <div className="player-description">
                  Liberating music through the passion of its biggest fans.
                  Connect with the artists you love in a whole new way.
                </div>

                <div className="subimg">
                  <img src={subimg} />
                </div>
                <div className="brief-instruct">
                  <ul>
                    <li>Support the creators you love – your money goes directly to them</li>
                    <li>Access exclusive content from your favorite creators</li>
                    <li>Connect and interact with the creators who inspire you</li>
                  </ul>
                </div>

                <div className="start-now-offer">
                  <Button>Join the Backstage pass</Button>
                </div>

              </div>
            </Item>
          </Grid>



          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Item className={classes.containWidth}>
                <div className="experience-process">
                  <div className="process">
                    <div className="process-main">Empowerment</div>
                    <h6>Give voice to the artists you love - support your favorite artists through a monthly membership and unlock unique experiences.</h6>
                  </div>

                  <div className="process">
                    <div className="process-main">Access</div>
                    <h6>Connect with the artists you love in a whole new way - go backstage and see their creative process, get insight into their lives and access special tracks, remixes, performances and benefits.</h6>
                  </div>

                  <div className="process">
                    <div className="process-main">Simplicity</div>
                    <h6>A totally fluid experience - access the Backstage pass and the Player on one platform, listening and
                      connecting with your favorite artists in one seamless environment.</h6>
                  </div>

                  <div className="process">
                    <div className="process-main">Fairness</div>
                    <h6>Pay the artist, tip the server – 85% goes directly to the artist, Dreamster take just 15%, less than a restaurant server.</h6>
                  </div>
                </div>

                <div className="discover-fanzone">
                  <div className="con">Discover the Backstage pass</div>
                  <div>
                    <svg width="227" height="81" viewBox="0 0 227 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="40.4996" cy="40.4999" r="27.5" transform="rotate(42.1646 40.4996 40.4999)" stroke="url(#paint0_linear_115_14713)" stroke-width="2" />
                      <path d="M226.707 41.7071C227.098 41.3166 227.098 40.6834 226.707 40.2929L220.343 33.9289C219.953 33.5384 219.319 33.5384 218.929 33.9289C218.538 34.3195 218.538 34.9526 218.929 35.3432L224.586 41L218.929 46.6569C218.538 47.0474 218.538 47.6806 218.929 48.0711C219.319 48.4616 219.953 48.4616 220.343 48.0711L226.707 41.7071ZM40 42L226 42L226 40L40 40L40 42Z" fill="#FF0000" />
                      <defs>
                        <linearGradient id="paint0_linear_115_14713" x1="67.9818" y1="63.2108" x2="10.056" y2="58.7535" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#175AFF" />
                          <stop offset="1" stop-color="#FC5AFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="start-now-offer">
                    <Button>Join the Backstage pass</Button>
                  </div>
                </div>
              </Item>
            </Grid>
          </Box>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.containWidth}>
              <Footer />
            </Item>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Fanzone;

import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import c7 from '../../img/dster-webp/bs3.webp'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Tracks from "../Music-traxk/Tracks";
import Track from '../NewMusicTrack/NewMusicTrack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import i3 from '../../img/dster-webp/i3.webp'
import { useNavigate } from 'react-router-dom'
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import './ExploreArtist.css'
import Axios from '../../Axios';
import HeaderNew from '../HeaderNew/HeaderNew';
import nodata from '../../img/dster-webp/newnodata.gif'
const useStyles = makeStyles({
    cardbg: {
        borderRadius: "12px !important",
        background: "rgba(255, 255, 255, 0.10)",
        boxShadow: "0px 10px 15px -3px rgba(0, 3, 45, 0.25) !important  ",
        backdropFilter: "blur(37.5px)",
        padding: "0 !important",

    },

    pwrback: {
        borderRadius: "12px !important",
        border: "1px solid rgba(255, 255, 255, 0.20)",
        background: "#000000 !important",
    },

    learn: {
        borderRadius: "12px !important",
        background: 'transparent !important',
        boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important',
    },

    containWidth: {
        maxWidth: '1440px',
        margin: 'auto',
        background: 'none !important',
        padding: '0 !important',
        boxShadow: 'none !important',

        "@media (max-width: 1199.98px)": {
            padding: '20px !important',
        },
    }

});
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "#1A2027" : "rgba(255, 255, 255, 0.10)",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

function ExploreArtist() {
    const classes = useStyles();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const songlist = useSelector(state => state.songs);
    const play_mood = useSelector(state => state.play_mood);
    const [audiosource, setAudiosource] = React.useState();
    const [tracks, setTracks] = useState(0)
    const [isplay, setisplay] = useState(0)

    const [audiosources, setAudiosources] = React.useState([]);
    const [audiosources1, setAudiosources1] = React.useState(songlist);

    const audio = localStorage.getItem("audiosource")

    const [Artists, setArtists] = useState([])

    const getArtist = async () => {
        try {
            const { data } = await Axios.post('/followers/get_all_users', { type: "artist" }, {
                headers: { Authorization: window.localStorage.getItem('iphephile') }
            })
            if (data?.success === true) {
                setArtists(data?.result)
            } else {
                setArtists([])
            }
        } catch (error) {
            console.log("🚀 ~ getArtist ~ error:", error)
        }
    }


    useEffect(() => {
        getArtist()
    }, [])


    const drawerWidth = 280;
    return (
        <div>
            <Box sx={{ display: 'flex' }} className='display-block'>
                <HeaderNew title="Artist List" />

                <Box component="main"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        marginTop: "100px",
                    }}
                    className="explore-artist">
                    <Grid spacing={0} className='whole-cnt'>


                        <Grid container spacing={0} className="expart">

                            {Artists?.length > 0 ? (<>
                                {Artists?.map((row, obj) => {
                                    return (
                                        <>

                                            <Grid xs={12} sm={12} md={12} lg={6} xl={3} sx={{ padding: '10px' }} key={obj}>
                                                <Card sx={{ maxWidth: 300, margin: 'auto' }} className="card-backstage expart">
                                                    <div

                                                        className="cirle-img"

                                                        title="green iguana"
                                                        onClick={((e) => { navigate(`/artistpage/${row?._id}`) })}
                                                    >
                                                        <img src={row?.profilePicture != "" ? row?.profilePicture : c7} alt='img' />
                                                    </div>
                                                    <CardContent >
                                                        <div className="love-txt1">
                                                            {row?.name != '' ? row?.name : `${row?.account?.slice(0, 10)}...`}{row?.exclusive == 'true' ? <img src={i3} /> : <></>}
                                                        </div>
                                                        <div className="love-txt2">
                                                            {row?.TotalFollowers} Followers
                                                        </div>

                                                        {/* <div className="explore-more-btn2 artists" onClick={((e) => { navigate(`/message/${row?._id}`) })}>
                                                            <Button>direct MESSAGE</Button>
                                                        </div> */}



                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </>) : (<>
                                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <div className='newno top'>
                                        <img src={nodata} />
                                    </div>
                                </Grid>


                            </>)}


                        </Grid>
                        {localStorage.getItem("playsong") === "true" ? <>{audiosources1.length > 0 ? <Tracks audiosources={audiosources1} tracks={tracks} /> : <></>} </> : <></>}
                        {(localStorage.getItem("playsong") === "false" && audiosource) === true ? <Track audiosource={audiosource} isplay={isplay} setisplay={setisplay} /> : <>{(localStorage.getItem("playsong") === "false" && audio !== null) === true ? <Track audiosource={audio} isplay={isplay} setisplay={setisplay} /> : <></>} </>}
                    </Grid>
                </Box>
            </Box>
        </div >
    )
}

export default ExploreArtist
